import React, { FC, ChangeEvent, ReactNode, Children } from "react";
import cx from "classnames";
import classes from "../Menu/styles.module.scss";

export interface MenuDividerProps {
  className?: string;
}

const MenuDivider: FC<MenuDividerProps> = ({ className }) => {
  return <hr className={className} />;
};

export default MenuDivider;

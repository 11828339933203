/* Global import */
import { observable, action, makeAutoObservable } from "mobx";

type NotificationState = "info" | "success" | "warning" | "error";

export default class ErrorStore {
  rootStore: import("./rootStore").default;

  @observable display: boolean;
  @observable title?: string;
  @observable message?: string;
  @observable state?: NotificationState;

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.display = false;
  }

  @action setNotification(
    title: string,
    message: string,
    state: NotificationState
  ) {
    this.title = title;
    this.message = message;
    this.state = state;
    this.display = true;
  }

  @action hideNotification() {
    this.display = false;
  }

  @action resetNotification() {
    this.title = "";
    this.message = "";
    this.hideNotification();
  }
}

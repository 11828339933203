/* Styles import */
import styles from "./license.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Checkbox,
  SearchInput,
  Table,
  TextInput,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  Pagination,
  IconButton,
  Radio,
  Flag,
  //DialogActions,
  //DialogContent,
  //Dialog,
  Button,
} from "../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import i18n from "i18next";
import { Hidden, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../components/autoComplete";
import { FormLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Create";
import FolderOpenIcon from "@mui/icons-material/Folder";
import ViewIcon from "@mui/icons-material/Loupe";
import moment from "moment";
import HeaderReport from "../../components/headerReport";
/* Custom import */
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import {
  generateFlowId,
  redirectFlow,
  flowStatus,
} from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow";
import { flowStatusList, flowType } from "../../utils/constant";
import { UnsubscribeTwoTone } from "@mui/icons-material";
import { unset } from "lodash";
import searchFlow from "../searchFlow";
import { useNavigate } from "react-router-dom";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

interface State {
  flowType?: FlowType;
  creationDateBegin?: string;
  dualUse?: boolean;
  usEar?: boolean;
  status?: FlowStatus;
  creationDateEnd?: string;
  ml?: boolean;
  usItar?: boolean;
  search?: string;
  departureCountry: number;
  endUserCountry: number;
  customer?: string;
  flows: FlowDto[];
  page: number;
  totalResults: number;
  filter: Filter;
  desId: number;
  open: boolean;
  open1: boolean;
}
type Keys = keyof State;

const SearchFlowArchive: React.FC<Props> = ({ rootStore }) => {
  const [state, setState] = useState<State>({
    flowType: undefined,
    creationDateBegin: undefined,
    dualUse: false,
    usEar: false,
    status: undefined,
    creationDateEnd: undefined,
    ml: false,
    usItar: false,
    search: "",
    departureCountry: 0,
    endUserCountry: 0,
    customer: "",
    flows: [],
    page: 1,
    totalResults: 0,
    filter: "ALL",
    desId: 0,
    open: false,
    open1: false,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Code à exécuter lorsque le composant est monté
    rootStore.countriesStore.getCountries();
    search({
      pageNumber: state.page - 1, // Assurez-vous que props.page est correctement transmis
    });
    rootStore.countriesStore.getCountriesDeparture();
  }, []);

  const navigate = useNavigate();

  // todo : bizarre ici
  const search = async (params: SearchFlowParams) => {
    setLoading(true);
    const searchResult = await rootStore.flowStore.searchFlow(params);
    let flows = [];
    if (searchResult) {
      searchResult.results.map((flow, index) => {
        if ("ARCHIVED" !== searchResult.results[index].status)
          delete searchResult.results[index];
        if (
          params.departureCountry !== undefined &&
          params.departureCountry !== 0 &&
          params.departureCountry !== flow.departureCountryId
        )
          delete searchResult.results[index];

        if (
          params.endUserCountry !== undefined &&
          params.endUserCountry !== 0 &&
          params.endUserCountry !== flow.destinationCountryId
        )
          delete searchResult.results[index];
        if (
          params.customer !== undefined &&
          params.customer !== "" &&
          params.customer !== flow.customer
        )
          delete searchResult.results[index];
      });
    }
    setLoading(false);
    setState((prevState) => ({
      ...prevState,
      flows: searchResult ? searchResult.results : [],
      totalResults: searchResult ? searchResult.totalResults : 0,
      page: 1,
    }));
  };

  const handleDepartureCountry = async (departureCountry: string) => {
    const intDepartureCountry = parseInt(departureCountry);
    setState({ ...state, departureCountry: intDepartureCountry });
  };

  const handleChange = (key: Keys, value: string | boolean | number) => {
    const updatedField = { [key]: value };
    if (key === "filter" && value === "ALL") {
      updatedField.ml = false;
      updatedField.usItar = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...updatedField,
    }));
    // todo : très bizarre ici
    search({
      creationDateBegin: state.creationDateBegin,
      creationDateEnd: state.creationDateEnd,
      flowType: state.flowType,
      departureCountry: state.departureCountry,
      endUserCountry: state.endUserCountry,
      customer: state.customer,
      ml: state.ml,
      pageNumber: state.page - 1,
      search: state.search,
      status: state.status,
      usItar: state.usItar,
    });
  };

  const submitDelete = async (userId: number) => {
    const searchResult = await rootStore.flowStore.deleteFlow(userId);
    window.location.reload();
  };

  const renderForm = () => {
    return (
      <form className={styles.form}>
        <div className={styles.container}>
          <GridRow alignItems="end">
            <GridCol md={3}>
              <div className={styles.input}>
                <AutoComplete
                  id="departure-country"
                  label={i18n.t("pages.new-flow.general.departure-country")}
                  options={rootStore.countriesStore.countriesDepartureToOptions}
                  errorText={i18n.t("errors.departure-country-not-defined")}
                  value={state.departureCountry || ""}
                  onChange={(e) => {
                    handleDepartureCountry(e);
                    handleChange("departureCountry", e);
                  }}
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <div className={styles.input}>
                <FormLabel>
                  {i18n.t("pages.new-flow.general.flow-type")}
                </FormLabel>

                <select
                  id="flow-type"
                  value={state.flowType || ""}
                  onChange={(e) => handleChange("flowType", e.target.value)}
                >
                  <option value="">
                    {i18n.t("pages.new-flow.select-value")}
                  </option>
                  {flowType.map((type, index) => {
                    return (
                      <option key={index} value={type.value || ""}>
                        {type.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </GridCol>

            <GridCol md={3}>
              <div className={styles.input}>
                <AutoComplete
                  id="end-user-country"
                  label={i18n.t("pages.exportability.country-of-destination")}
                  errorText={i18n.t("errors")}
                  onChange={(e) => {
                    setState({ ...state, endUserCountry: e });
                    handleChange("endUserCountry", e);
                  }}
                  options={rootStore.countriesStore.countriesToOptions}
                  value={state.endUserCountry || ""}
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>
                {i18n.t("pages.new-flow.general.end-user-country")}
              </FormLabel>
              <div className={styles.input}>
                <TextInput
                  value={state.customer || ""}
                  onChange={(e) => {
                    setState({ ...state, customer: e.target.value as string });
                    handleChange("customer", e.target.value as string);
                  }}
                />
              </div>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.search-flow.creation-date")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-begin"
                  type="date"
                  value={state.creationDateBegin || ""}
                  onChange={(e) =>
                    handleChange("creationDateBegin", e.target.value)
                  }
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.new-flow.general.to")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-end"
                  type="date"
                  value={state.creationDateEnd || ""}
                  onChange={(e) =>
                    handleChange("creationDateEnd", e.target.value)
                  }
                />
              </div>
            </GridCol>
          </GridRow>
        </div>
      </form>
    );
  };

  const renderListFlow = () => {
    const handleOpen = () => setState({ ...state, open: true });
    const handleClose = () => setState({ ...state, open: false });
    const handleOpen1 = () => setState({ ...state, open1: true });
    const handleClose1 = () => setState({ ...state, open1: false });
    return (
      <div className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                <TableHeaderCell> Tenant</TableHeaderCell>
              )}
              <TableHeaderCell>
                {i18n.t("pages.search-flow.id")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.creation-dateArchive")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.departure-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.end-user-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.customers-company")}
              </TableHeaderCell>

              <TableHeaderCell>
                {i18n.t("pages.search-flow.model")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.folder")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.du-ml")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.regulation")}
              </TableHeaderCell>
              <TableHeaderCell>Action </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state.flows.map((flow, index) => {
              let label = flowStatus["CLASSIFICATION"];

              if (flowStatus[flow.status]) {
                label = flowStatus[flow.status];
              }

              return (
                <TableRow key={index}>
                  {rootStore.userStore.user?.role == "SUPER_ADMINISTRATOR" && (
                    <td> {flow.supplier}</td>
                  )}
                  <td>{generateFlowId(flow.flowType, flow.id)}</td>
                  <td>{moment(flow.updatedAt).format("MMM D YYYY")}</td>
                  <td>{flow.departureCountryName}</td>
                  <td>{flow.destinationCountryName}</td>
                  <td>{flow.customer}</td>

                  <td>{flow.flowAircraft ? flow.flowAircraft.model : ""}</td>
                  <td>
                    <img
                      src={require(`../../../statics/images/folder1.png`)}
                      alt={"Folder"}
                    />
                  </td>
                  <td>
                    {flow.hasDuEccn && <Flag>DU</Flag>}

                    {flow.hasMlEccn && <Flag>ML</Flag>}
                  </td>
                  <td>
                    {flow.hasEarEccn && <Flag>EAR</Flag>}

                    {flow.hasItarEccn && <Flag>ITAR</Flag>}
                  </td>
                  <td>
                    <IconButton
                      onClick={() => navigate(`/flow/${flow.id}/viewArchived`)}
                    >
                      <Tooltip title="Visualiser">
                        <ViewIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={() =>
                        navigate(`/flow/${flow.id}/classificationArchived`)
                      }
                    >
                      <Tooltip title="Editer">
                        <EditIcon />
                      </Tooltip>
                    </IconButton>

                    {flow.status !== "ARCHIVED" && (
                      <IconButton
                        onClick={() => {
                          setState({ ...state, desId: flow.id });
                          handleOpen1();
                        }}
                        //  style={{display: this.injected.rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) ? 'block' : 'none' }}

                        //   disabled={ !this.injected.rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
                      >
                        <Tooltip title="Supprimer">
                          <DeleteIcon style={{ color: "#0085ad" }} />
                        </Tooltip>
                      </IconButton>
                    )}
                    <Dialog
                      open={state.open1}
                      title="Suppression Flux"
                      onClose={handleClose1}
                    >
                      <DialogContent>
                        Le Flux va être supprimé ainsi que ses données.
                        <br />
                        Etes-vous vraiment sûr ?
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose1}>Annuler</Button>
                        <Button
                          primary
                          onClick={() => {
                            submitDelete(state.desId);
                            handleClose1();
                          }}
                        >
                          Supprimer
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          page={state.page}
          count={state.totalResults}
          rowsPerPage={1000}
          onPageChange={(_: any, page: React.SetStateAction<number>) =>
            handleChange("page", page as number)
          }
        />
      </div>
    );
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="FLOW" />

      <HeaderReport pages="FLOWS" rootStore={rootStore} />

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.search-flow.titleArchive")}</Title>

        {renderForm()}

        {state.flows.length > 0 ? (
          renderListFlow()
        ) : (
          <p>{i18n.t("pages.search-flow.no-result")}</p>
        )}

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(SearchFlowArchive));

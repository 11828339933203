import React, { Component, Fragment, useEffect, useState } from "react";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import Api from "../../utils/api";
import RootStore from "../../stores/rootStore";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Button,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { SelectCountry } from "../../customComponents/selectCountry";
import axios from "axios";
import {
  AccountCircle,
  Spellcheck,
  Filter1,
  FormatListNumbered,
} from "@mui/icons-material";
import { regulation } from "../../utils/constant";
import {
  convertCountryCodeToId,
  convertCountryIdToCode,
  convertEntityIdToName,
  formatNumber,
  generateSelectOptionMui,
  getCurrencySymbol,
  getRegutationByCountryIdAndType,
} from "../../utils/generator";
import { flexbox } from "@mui/system/Box";
import { direction } from "html2canvas/dist/types/css/property-descriptors/direction";
import { toJS } from "mobx";
import moment from "moment";
import license from "../../components/matching/license";
import AssociationCard from "../../components/matching/associated";
import { ArrowRight, Check, DoubleArrow, East } from "@mui/icons-material";
import configuration from "../../configuration";

interface Props {
  rootStore: RootStore;
  flowId: number;
  equipmentId: number;
  equipment: EquipmentFlow;
  type: string;
  flow: Flow;
}
interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}
interface BookingValues {
  old_values: {
    quantity_ordered: number;
    amount_ordered: number;
  };
  new_values: {
    quantity_ordered: number;
    amount_ordered: number;
  };
}

const equipmentsToLicense: React.FC<Props> = ({
  rootStore,
  flowId,
  equipmentId,
  type,
  flow,
  equipment,
}) => {
  const api = new Api();
  const [countriesState, setCountries] = useState<any[]>([] as any[]);
  const [countriesDestination, setCountriesDestination] = useState<any[]>(
    [] as any[]
  );
  const [selectedRegulations, setSelectedRegulations] = useState<string[]>([]);
  const [dpCountry, setDpCountry] = useState<string | number>();
  const [dtCountry, setDtCountry] = useState<string | number>();
  const [classement, setClassement] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [eccn, setEccn] = useState<string>("");
  const [licenseMatchedRegulation, setLicenseMatchedRegulation] =
    useState<string>("");
  const [endUser, setEndUser] = useState("");
  const [licenses, setLicenses] = useState<any[]>([]);
  const [allLicenses, setAllLicenses] = useState<any[]>([]);
  const [isErrorPresent, setError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [bigLoading, setBigLoading] = useState<boolean>(false);
  const [matchedLicense, setMatchedLicense] = useState([] as any);
  const [chosenEquipment, setChosenEquipment] = useState<number>(0);
  const [booked, setBooked] = useState<boolean>(false);
  const [bookingValues, setBookingValues] = useState<BookingValues | null>(
    null
  );

  useEffect(() => {
    setBigLoading(true);
    setLoading(true);
    setEndUser(flow.customer);
    setClassement(equipment.national_eccn);
    setPartNumber(equipment.part_number);
    if (equipment.nationalEccn) {
      setEccn(equipment.nationalEccn);
    } else if (equipment.us_eccn) {
      setEccn(equipment.us_eccn);
    } else {
      setEccn("");
    }
    setSelectedRegulations(
      getRegutationByCountryIdAndType(flow.departure_country_id, type)
    );
    //if tenant.entities > 0 = entite général
    //ligne active si FR ML
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SCREEN}/countries_departure`
      );
      const fetchedLicenses = await api.getLicensesToMatch(
        flow.entity_id
          ? convertEntityIdToName(
              flow.entity_id,
              rootStore.tenantStore.entities
            )
          : null,
        rootStore.tenantStore.entities.length
      );
      try {
        const linkedEquip = await api.getMatchedLicenseByEquipId(
          equipment.id,
          type
        );
        if (linkedEquip.id) {
          setMatchedLicense(linkedEquip);
        } else {
          setMatchedLicense(null);
        }
      } catch (error) {
        setMatchedLicense(null);

        // console.error(
        //   "Erreur lors de la récupération de la licence associée à l'équipement :",
        //   error
        // );
      }
      setLicenses(fetchedLicenses);
      setAllLicenses(fetchedLicenses);
      setLoading(false);
      // const pays = await api.getCountryTable();
      if (rootStore.countriesStore.countryTable.length <= 0) {
        await rootStore.countriesStore.setCountryTable();
        setCountriesDestination(toJS(rootStore.countriesStore.countryTable));
        setDpCountry(
          convertCountryIdToCode(
            flow.departure_country_id,
            toJS(rootStore.countriesStore.countryTable)
          )
        );
        setDtCountry(
          convertCountryIdToCode(
            flow.destination_country_id,
            toJS(rootStore.countriesStore.countryTable)
          )
        );
      } else {
        setCountriesDestination(toJS(rootStore.countriesStore.countryTable));
        setDpCountry(
          convertCountryIdToCode(
            flow.departure_country_id,
            toJS(rootStore.countriesStore.countryTable)
          )
        );
        setDtCountry(
          convertCountryIdToCode(
            flow.destination_country_id,
            toJS(rootStore.countriesStore.countryTable)
          )
        );
      }
      // todo ajouter ça à l'authstore
      setCountries(response.data);
      setBigLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setBigLoading(true);
    const fetchData = async () => {
      try {
        const linkedEquip = await api.getMatchedLicenseByEquipId(
          equipment.id,
          type
        );
        if (linkedEquip.id) {
          setMatchedLicense(linkedEquip);
        } else {
          setMatchedLicense(null);
        }
      } catch (error) {
        setMatchedLicense(null);
      }
      setBigLoading(false);
    };
    fetchData();
  }, [chosenEquipment]);

  useEffect(() => {
    filterLicensesByPartNumber(
      partNumber,
      classement,
      selectedRegulations,
      endUser
    );
  }, [partNumber, classement, selectedRegulations, endUser]);

  const filterLicensesByPartNumber = (
    eccn: string,
    classification: string,
    regulations: string[],
    user: string
  ) => {
    const filters: {
      (license: any): any;
      (license: any): any;
      (license: any): boolean;
      (license: any): any;
    }[] = [];
    if (eccn)
      filters.push((license: any) =>
        license.equipment.some((equip: any) => equip.pn?.includes(eccn))
      );
    if (classification)
      filters.push((license: any) =>
        license.equipment.some((equip: any) =>
          equip.eccn_code?.includes(classification)
        )
      );
    if (regulations.length > 0)
      filters.push((license: any) => regulations?.includes(license.regulation));
    if (user)
      filters.push((license: any) => license.company_name?.includes(user));

    const filteredLicenses =
      filters.length > 0
        ? allLicenses.filter((license: any) =>
            filters.every((filter) => filter(license))
          )
        : allLicenses;
    setLicenses(filteredLicenses);
  };

  const regulationOptions = generateSelectOptionMui(regulation);
  const now = moment();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "250",
        display: "list-item",
        padding: "1em",
      },
    },
  };

  const handleChange = (
    event: SelectChangeEvent<typeof selectedRegulations>
  ) => {
    const {
      target: { value },
    } = event;
    if (value.includes("")) {
      setSelectedRegulations([]);
    } else {
      setSelectedRegulations(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleChangeDpCountry = (selectedCountry: CountryType | null) => {
    setDpCountry(selectedCountry ? selectedCountry.value : undefined);
  };
  const handleChangeDtCountry = (selectedCountry: CountryType | null) => {
    setDtCountry(selectedCountry ? selectedCountry.value : undefined);
  };

  const checkErrors = (license: {
    expirationDate: any;
    expiration_date: any;
    amount_actual: number | null | undefined;
    regulation: string;
    quantity_actual: number | null | undefined;
    unit_price: number | null | undefined;
    quantity_initial: number;
    quantity_ordered: number;
  }) => {
    const expirationDate = moment(
      license.expirationDate || license.expiration_date
    );
    if (
      moment().isAfter(expirationDate) ||
      license.amount_actual === undefined ||
      license.amount_actual === null ||
      (license.regulation !== "FR_MUNITIONS_LIST" &&
        equipment.amount > license.amount_actual) ||
      license.quantity_actual === undefined ||
      license.quantity_actual === null ||
      (license.regulation !== "FR_MUNITIONS_LIST" &&
        equipment.quantity > license.quantity_actual) ||
      license.unit_price === undefined ||
      license.unit_price === null ||
      // equipmentUnitPrice === undefined ||
      // equipmentUnitPrice === null ||
      equipment.unit_price > license.unit_price ||
      (license.regulation === "FR_MUNITIONS_LIST" &&
        equipment.quantity >
          license.quantity_initial - license.quantity_ordered)
    ) {
      setError(true);
    }
  };

  const hasFieldError = (
    licenseInfos: {
      regulation: string;
      quantity_initial: number;
      quantity_ordered: number;
      quantity_actual: number;
      quantity_delivered: number;
      unit_price: number;
      amount_initial: number;
      amount_ordered: number;
      amount_delivered: number;
      amount_actual: number;
      expiration_date: string;
      equipment: [];
    },
    equip: any,
    field: string
  ) => {
    // const results = findGoodEquipment(licenseInfos.equipment);
    const expirationDate = moment(licenseInfos.expiration_date);
    const now = moment();
    // const equip = results[0];
    switch (field) {
      case "date":
        if (expirationDate.isValid()) {
          const diffInmonths = Math.abs(expirationDate.diff(now, "months"));

          return (
            <Typography
              color={moment().isAfter(expirationDate) ? "red" : "green"}
            >
              {moment().isAfter(expirationDate)
                ? i18n.t("pages.license.matching.expired")
                : i18n.t("pages.license.matching.expiredMessage", {
                    code: diffInmonths,
                  })}
            </Typography>
          );
        } else {
          return (
            <Typography color="orange">Date d'expiration invalide</Typography>
          );
        }
      case "qty":
        return (
          <Typography
            color={` ${
              (licenseInfos.regulation === "FR_MUNITIONS_LIST" &&
                equipment.quantity >
                  equip.quantity_initial -
                    equip.quantity_ordered -
                    equip.quantity_delivered) ||
              (licenseInfos.regulation !== "FR_MUNITIONS_LIST" &&
                equipment.quantity > equip.quantity_actual)
                ? "red"
                : "black"
            }`}
          >
            <b>{i18n.t("pages.license.imput.left_quantity2")} : </b>
            {licenseInfos.regulation === "FR_MUNITIONS_LIST"
              ? parseInt(equip.quantity_initial) -
                parseInt(equip.quantity_ordered) -
                parseInt(equip.quantity_delivered)
              : equip.quantity_actual
                ? equip.quantity_actual
                : ""}
          </Typography>
        );
      case "price":
        return (
          <Typography
            color={` ${
              (equip.unit_price &&
                equipment.unit_price > equip.unit_price &&
                isErrorPresent) ||
              !equip.unit_price
                ? "red"
                : "black"
            }`}
          >
            <b>{i18n.t("pages.license.matching.unit_price")} : </b>
            {equip.unit_price ? equip.unit_price : " - "}
          </Typography>
        );
      case "amount":
        return (
          <Typography
            color={` ${
              equipment.amount > equip.amount_initial - equip.amount_ordered &&
              isErrorPresent
                ? "red"
                : "black"
            }`}
          >
            <b>{i18n.t("pages.license.imput.left_amount")} : </b>
            {licenseInfos.regulation === "FR_MUNITIONS_LIST"
              ? (
                  equip.amount_initial -
                  equip.amount_ordered -
                  equip.amount_delivered
                ).toLocaleString()
              : equip.amount_actual}
          </Typography>
        );
      default:
        return null;
    }
  };

  const handleEquipClick = async (l: any, e: any) => {
    try {
      const bond = await api.saveLink(
        flow.id,
        equipment.id as number,
        l.id,
        type,
        e.id
      );
      setLicenseMatchedRegulation(l.regulation);
      setChosenEquipment(e.id);
      if (l.regulation === "FR_MUNITIONS_LIST") {
        setBooked(true);
        const resp = await api.bookLicense(true, equipment?.id as number, e.id);
        setBookingValues({
          old_values: resp.old_values,
          new_values: resp.new_values,
        });
      }
      console.log("Link saved successfully:", bond);
    } catch (error) {
      console.error("Error while saving link:", error);
    }
  };

  const handleDeleteAssociation =
    (equipment: EquipmentFlow, lEquipmentId: any) => async () => {
      setMatchedLicense(null);
      if (lEquipmentId.regulation === "FR_MUNITIONS_LIST") {
        setBooked(true);
        const resp = await api.bookLicense(
          false,
          equipment?.id as number,
          lEquipmentId?.id
        );
        setBookingValues({
          old_values: resp.old_values,
          new_values: resp.new_values,
        });
      }
    };

  return (
    <Container
      maxWidth="xl"
      sx={{
        p: 0,
        m: 0,
        mb: "10em",
        pt: 3,
        ...(bigLoading && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }),
      }}
    >
      {bigLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {i18n.t("pages.license.add.num_order")} : <b>{flow.order_num}</b>
          </Typography>
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={2}
            sx={{ p: 0, m: 0, width: "100%" }}
          >
            <Typography variant="h6" gutterBottom>
              {i18n.t("pages.license.matching.flowFilter")}
            </Typography>
            <Stack
              direction="row"
              id="filters"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0.5}
              sx={{ width: "100%", p: 0 }}
            >
              <Box
                height={"auto"}
                width={"100%"}
                display="flex"
                gap={4}
                sx={{ mt: "10px", mb: "10px" }}
              >
                <FormControl sx={{ m: 0, width: "32%" }} variant="standard">
                  <SelectCountry
                    onChangeInherited={handleChangeDpCountry}
                    selected={dpCountry}
                    size="small"
                    width="100%"
                    countries={countriesState}
                    label={i18n.t("pages.exportability.departure-country")}
                  />
                </FormControl>
                <FormControl sx={{ m: 0, width: "32%" }} variant="standard">
                  <SelectCountry
                    width="100%"
                    onChangeInherited={handleChangeDtCountry}
                    selected={dtCountry}
                    size="small"
                    countries={countriesDestination}
                    label={i18n.t("pages.exportability.country-of-destination")}
                  />
                </FormControl>
                <FormControl sx={{ m: 0, width: "32%" }} variant="standard">
                  <InputLabel htmlFor="input-with-icon-adornment">
                    {i18n.t("pages.license.add.end-user")}
                  </InputLabel>
                  <Input
                    value={endUser}
                    onChange={(e) => setEndUser(e.target.value)}
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Stack>
            <Stack
              direction="row"
              id="matchings"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0.5}
              sx={{ width: "100%", p: 0 }}
            >
              <Box
                height={"auto"}
                width={"100%"}
                display="flex"
                gap={4}
                sx={{ p: 0, mt: "10px", mb: "10px" }}
              >
                <FormControl variant="standard" sx={{ m: 0, width: "32%" }}>
                  <InputLabel id="regulation-select-chip-label">
                    {i18n.t("pages.license.add.regulation")}
                  </InputLabel>
                  <Select
                    labelId="regulation-select-chip-label"
                    id="regulation-select-chip"
                    multiple
                    value={selectedRegulations}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={i18n.t(
                              `global.regulation-minimized.${value}`
                            )}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {generateSelectOptionMui(regulation)}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 0, width: "32%" }}>
                  <InputLabel htmlFor="input-with-icon-adornment">
                    {i18n.t("pages.license.add.classification")}
                  </InputLabel>
                  <Input
                    value={classement}
                    onChange={(e) => setClassement(e.target.value)}
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 0, width: "32%" }}>
                  <InputLabel htmlFor="input-with-icon-adornment">
                    {i18n.t("pages.new-flow.product.equipment.part-number")}
                  </InputLabel>
                  <Input
                    value={partNumber}
                    onChange={(e) => setPartNumber(e.target.value)}
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <Filter1 />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Stack>
          </Stack>
          <Divider sx={{ mt: 5, mb: 3 }} />
          {/* <Typography variant="h6" sx={{ mr: "10em", float: "right", mt: "20px" }}>
        Licences trouvées :
      </Typography>{" "} */}
          {booked && (
            <Alert
              icon={<Check fontSize="inherit" />}
              severity="success"
              sx={{ margin: 2 }}
            >
              <Typography>
                {i18n.t("pages.license.imput.reservation")} :{" "}
                {/* {matchedLicense?.license_number} */}
                <Link
                  style={{ color: "#0085ad" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/license/${matchedLicense?.id}/edit`}
                >
                  {matchedLicense?.license_number}
                </Link>{" "}
              </Typography>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                sx={{ marginTop: 3 }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Box sx={{ marginRight: "50px" }}>
                    {i18n.t("pages.license.add.quantity-ordered")} :{" "}
                  </Box>
                  {formatNumber(
                    bookingValues?.old_values.quantity_ordered as number
                  )}
                  <East />
                  <Box>
                    {formatNumber(
                      bookingValues?.new_values?.quantity_ordered as number
                    )}
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Box sx={{ marginRight: "50px" }}>
                    {i18n.t("pages.license.add.amount-ordered")} :{" "}
                  </Box>
                  {formatNumber(
                    bookingValues?.old_values.amount_ordered as number
                  )}{" "}
                  {getCurrencySymbol(equipment.currency as Currency)}
                  <East />
                  <Box>
                    {formatNumber(
                      bookingValues?.new_values.amount_ordered as number
                    )}{" "}
                    {getCurrencySymbol(equipment.currency as Currency)}
                  </Box>
                </Stack>
              </Stack>
            </Alert>
          )}
          {matchedLicense && (
            <AssociationCard
              onDeleteAssociation={handleDeleteAssociation(
                equipment,
                matchedLicense
              )}
              rootStore={rootStore}
              equipment={equipment}
              license={matchedLicense}
              flow={flow}
              type={type}
            ></AssociationCard>
          )}
          {!matchedLicense && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0}
              sx={{ width: "100%", p: 0, m: 0 }}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="end"
                sx={{
                  width: "100%",
                  // display: "inline-block",
                  transform: "scale(0.9)",
                  p: 5,
                  m: 0,
                }}
              >
                <Card sx={{ marginBottom: 2, width: "100%", p: 0, m: 0 }}>
                  <CardContent>
                    {/* ici le bug de police */}
                    <Typography gutterBottom variant="h5">
                      {i18n.t("pages.license.matching.equipment_to_link")} :{" "}
                      <b>{equipment.model ? equipment.model : "-"}</b>
                    </Typography>
                    <Box>
                      <br></br>
                      <Typography color="black">
                        {i18n.t("pages.license.add.part-number")} :{" "}
                        {equipment.part_number}
                      </Typography>
                      <Typography color="black">
                        {i18n.t("pages.license.add.quantity")} :{" "}
                        {equipment.quantity}
                      </Typography>
                      <Typography color="black">
                        {i18n.t("pages.license.add.unit-price")} :{" "}
                        {equipment.unit_price}
                      </Typography>
                      <Typography color="black">
                        {i18n.t("pages.license.add.amount")} :{" "}
                        {equipment.amount}
                      </Typography>
                      <Typography color="black">
                        {i18n.t("pages.license.add.end-user")} : {flow.customer}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Stack direction="column" sx={{ width: "100%", p: 0, m: 0 }}>
                <Box
                  width={"100%"}
                  display="flex"
                  flexDirection={"column"}
                  alignItems={licenses.length === 0 ? "center" : "end"}
                  justifyContent={licenses.length === 0 ? "center" : ""}
                  gap={2}
                  sx={{
                    overflowY: "scroll",
                    height: "30em",
                    display: "inline-block",
                    transform: "scale(0.8)",
                  }}
                >
                  {!loading && licenses.length === 0 ? (
                    <Typography variant="h6">
                      {i18n.t("pages.search-flow.no-result")}
                    </Typography>
                  ) : (
                    <>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        licenses.map((license) => (
                          <>
                            {license.equipment.length > 0 &&
                              license.equipment.map(
                                (equip: any, index: number) => {
                                  // Vérifie la condition pour "FR_MUNITIONS_LIST"
                                  if (
                                    (license.regulation ===
                                      "FR_MUNITIONS_LIST" &&
                                      equip.active_line !== 1) ||
                                    license.entite !==
                                      convertEntityIdToName(
                                        flow.entity_id,
                                        rootStore.tenantStore.entities
                                      )
                                  ) {
                                    return null;
                                  }
                                  if (
                                    (!partNumber ||
                                      equip.pn?.includes(partNumber)) &&
                                    (!classement ||
                                      equip.eccn_code?.includes(classement)) &&
                                    (!endUser ||
                                      license.company_name?.includes(
                                        endUser
                                      )) &&
                                    (!selectedRegulations.length ||
                                      selectedRegulations?.includes(
                                        license.regulation
                                      )) &&
                                    (!dpCountry ||
                                      dpCountry ===
                                        license.departure_country_id) &&
                                    (!dtCountry ||
                                      convertCountryCodeToId(
                                        dtCountry as string,
                                        toJS(
                                          rootStore.countriesStore.countryTable
                                        )
                                      ) === license.destination_country_id)
                                  ) {
                                    return (
                                      <Card
                                        key={`${license.id}_${index}`}
                                        sx={{
                                          marginBottom: 2,
                                          cursor: "pointer",
                                          height: "260px",
                                        }}
                                        onClick={() =>
                                          handleEquipClick(license, equip)
                                        } /* Appel de la fonction onClick avec les informations de la licence et de l'équipement */
                                      >
                                        <CardContent>
                                          {/* ici le bug de police */}
                                          <Typography gutterBottom variant="h5">
                                            {i18n.t(
                                              "pages.license.imput.title-tab"
                                            )}{" "}
                                            :{" "}
                                            <b>
                                              {license.license_number
                                                ? license.license_number
                                                : "-"}
                                            </b>
                                          </Typography>
                                          {hasFieldError(
                                            license,
                                            equip,
                                            "date"
                                          )}
                                          <b>
                                            {i18n.t(
                                              "pages.license.add.part-number"
                                            ) + " : "}
                                          </b>
                                          {equip.pn ? equip.pn : " - "}
                                          {hasFieldError(license, equip, "qty")}
                                          {hasFieldError(
                                            license,
                                            equip,
                                            "price"
                                          )}
                                          {hasFieldError(
                                            license,
                                            equip,
                                            "amount"
                                          )}
                                          <b>
                                            {i18n.t(
                                              "pages.license.add.end-user"
                                            ) + " : "}
                                          </b>
                                          {license.company_name
                                            ? license.company_name
                                            : " - "}
                                        </CardContent>
                                      </Card>
                                    );
                                  }
                                  return null;
                                }
                              )}
                          </>
                        ))
                      )}
                    </>
                  )}
                </Box>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};

export default inject("rootStore")(observer(equipmentsToLicense));

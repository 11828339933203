import React, { FC } from "react";
import cx from "classnames";
import classes from "../Dialog/styles.module.scss";

type Props = {
  background?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const DialogActions: FC<Props> = ({
  children,
  className,
  background = false,
}) => (
  <div
    className={cx(className, classes["ds-modal__footer"], {
      [classes["ds-modal__footer--bg"]]: background,
    })}
  >
    {children}
  </div>
);

export default DialogActions;

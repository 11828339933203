import React, { FC, ReactNode, HTMLAttributes } from "react";
import cx from "classnames";

type TableHeadProps = HTMLAttributes<HTMLTableSectionElement> & {
  children?: ReactNode;
};

const TableHead: FC<TableHeadProps> = ({ children, ...rest }) => (
  <thead {...rest}>{children}</thead>
);

export default TableHead;

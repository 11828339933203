/* Styles import */
import styles from "./license.module.css";

/* Global import */
import React, { Component } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Checkbox,
  SearchInput,
  Table,
  TextInput,
  TableHead,
  TableRow,
  TableBody,
  TableHeaderCell,
  Pagination,
  IconButton,
  Radio,
  Flag,
  //DialogActions,
  //DialogContent,
  //Dialog,
  Button,
} from "../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import i18n from "i18next";
import { Hidden, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { inject, observer } from "mobx-react";
import AutoComplete from "../../components/autoComplete";
import { FormLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Create";
import FolderOpenIcon from "@mui/icons-material/Folder";
import ViewIcon from "@mui/icons-material/Loupe";
import moment from "moment";
import HeaderReport from "../../components/headerReport";
/* Custom import */
import AppBar from "../../components/appBar";
import Loader from "../../components/loader";
import {
  generateFlowId,
  redirectFlow,
  flowStatus,
} from "../../utils/generator";
import HeaderFlow from "../../components/headerFlow";
import { flowStatusList, flowType } from "../../utils/constant";
import { UnsubscribeTwoTone } from "@mui/icons-material";
import { unset } from "lodash";
import Moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Api from "../../utils/api";
import HeaderReportMenu from "../../components/headerReportMenu";
interface InjectedProps {
  rootStore: import("../../stores/rootStore").default;
}
type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface Props extends InjectedProps {}
interface State {
  flowType?: FlowType;
  creationDateBegin?: string;
  dualUse?: boolean;
  usEar?: boolean;
  status?: FlowStatus;
  creationDateEnd?: string;
  ml?: boolean;
  usItar?: boolean;
  search?: string;
  departureCountry: number;
  endUserCountry: number;
  customer?: string;
  flows: FlowDto[];
  page: number;
  totalResults: number;
  loading: boolean;
  filter: Filter;
  filtrearchive: string;
  desId: number;
  open: boolean;
  open1: boolean;
}
type Keys = keyof State;

@inject("rootStore")
@observer
export default class ReportingSBDU extends Component<Props, State> {
  departureCountry: number | undefined;
  api: Api;
  msg: MsgType;
  constructor(props: Props) {
    super(props);
    this.api = new Api();
    this.msg = {
      code_msg: "",
      Regle: "",
      Message: "",
      Lib_url1: "",
      Url1: "",
      Lib_url2: "",
      Url2: "",
      Lib_url3: "",
      Url3: "",
    };

    this.state = {
      flows: [],
      page: 1,
      totalResults: 0,
      loading: false,
      departureCountry: 0,
      endUserCountry: 0,
      customer: "",
      filter: "ALL",
      filtrearchive: "ALL",
      open: false,
      open1: false,
      desId: 0,
    };
  }

  async exportXLS() {
    //const columns = this.myRef.current.visibleColumns;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";
    var temp: FlowDto[] = [];
    this.state.flows.map((flow, index) => {
      temp.push(this.state.flows[index]);
    });
    //let excel_res=await this.api.writeexcel(temp)

    //console.log(this.state.flows)
    //  const ws = XLSX.utils.json_to_sheet(temp);
    //  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //  const data = new Blob(excel_res, {type: fileType});
    //let file:File
    // fileDownload(file, $excel_res)

    //  const a = document.createElement("a");
    // a.href = `${process.env.REACT_APP_PUBLIC}/`+excel_res;
    // a.download = "reporting_DGA.xlsx";
    // a.click();
    // FileSaver.saveAs(`${process.env.REACT_APP_PUBLIC}/`+excel_res, 'reporting_DGA' + fileExtension);
    //console.log(this.state.flows)
    const ws = XLSX.utils.json_to_sheet(temp);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "SBDU modele-de-reporting-liglo.xlsx");
  }

  async componentDidMount() {
    const cmsg = "EXP073";
    this.msg = await this.api.GcodeToMsg(cmsg);
    this.props.rootStore.countriesStore.getCountries();
    this.search({
      pageNumber: this.state.page - 1,
    });
  }
  UNSAFE_componentWillMount() {
    this.props.rootStore.countriesStore.getCountriesDeparture();
  }
  async search(params: SearchFlowParams) {
    this.setState({ loading: true });

    const searchResult =
      await this.props.rootStore.flowStore.searchFlow(params);

    let flows = [];
    if (searchResult)
      searchResult.results.map((flow, index) => {
        if (
          "ARCHIVED" !== searchResult.results[index].status &&
          this.state.filtrearchive === "ARCHIVED"
        )
          delete searchResult.results[index];
        else {
          if (
            "ARCHIVED" === searchResult.results[index].status &&
            this.state.filtrearchive === "NOARCHIVED"
          )
            delete searchResult.results[index];
          else if (
            "France" !== searchResult.results[index].departureCountryName
          )
            delete searchResult.results[index];
          else if (!searchResult.results[index].hasDuEccn)
            delete searchResult.results[index];

          if (
            params.departureCountry !== undefined &&
            params.departureCountry !== 0 &&
            params.departureCountry !== flow.departureCountryId
          )
            delete searchResult.results[index];

          if (
            params.endUserCountry !== undefined &&
            params.endUserCountry !== 0 &&
            params.endUserCountry !== flow.destinationCountryId
          )
            delete searchResult.results[index];
          if (
            params.customer !== undefined &&
            params.customer !== "" &&
            params.customer !== flow.customer
          )
            delete searchResult.results[index];
          if (searchResult.results[index] !== undefined) {
            if (
              this.state.creationDateEnd &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) > moment(this.state.creationDateEnd).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            } else if (
              this.state.creationDateBegin &&
              moment(searchResult.results[index].updatedAt).format(
                "YYYY MM DD"
              ) < moment(this.state.creationDateBegin).format("YYYY MM DD")
            ) {
              delete searchResult.results[index];
            }
          }
        }
      });

    this.setState({
      loading: false,
      flows: searchResult ? searchResult.results : [],
      totalResults: searchResult ? searchResult.totalResults : 0,
      page: 1,
    });
  }
  async handleDepartureCountry(departureCountry: string) {
    const intDepartureCountry = parseInt(departureCountry);

    this.setState({ departureCountry: intDepartureCountry });
  }
  handleChange(key: Keys, value: string | boolean | number) {
    this.setState(
      (prevState) => {
        const newState = {
          ...prevState,
          [key]: value,
        };

        if (key === "filter" && value === "ALL") {
          newState.ml = false;
          newState.usItar = false;
        }

        return newState;
      },
      () =>
        this.search({
          creationDateBegin: this.state.creationDateBegin,
          creationDateEnd: this.state.creationDateEnd,
          flowType: this.state.flowType,
          departureCountry: this.state.departureCountry,
          endUserCountry: this.state.endUserCountry,
          customer: this.state.customer,
          ml: this.state.ml,
          pageNumber: this.state.page - 1,
          search: this.state.search,
          status: this.state.status,
          usItar: this.state.usItar,
        })
    );
  }

  async submitDelete(userId: number) {
    const searchResult =
      await this.props.rootStore.flowStore.deleteFlow(userId);

    window.location.reload();
  }

  render() {
    return (
      <div>
        <AppBar rootStore={this.props.rootStore} selectedPage="NONE" />

        <HeaderReportMenu pages="REPORTING" rootStore={this.props.rootStore} />

        <div className={styles.body}>
          <Title tag="h2">Reporting SBDU </Title>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: this.msg.Message }}
          ></div>
          <p>
            <a target="_blank" rel="noopener noreferrer" href={this.msg.Url1}>
              {this.msg.Lib_url1}
            </a>
            <a
              target="_blank"
              style={{ marginLeft: "50px" }}
              rel="noopener noreferrer"
              href={this.msg.Url2}
            >
              {this.msg.Lib_url2}
            </a>
            <a
              target="_blank"
              style={{ marginLeft: "50px" }}
              rel="noopener noreferrer"
              href={this.msg.Url3}
            >
              {this.msg.Lib_url3}
            </a>
            <br />
          </p>

          {this.renderForm()}

          {this.state.flows.length > 0 ? (
            this.renderListFlow()
          ) : (
            <p>{i18n.t("pages.search-flow.no-result")}</p>
          )}

          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form className={styles.form}>
        <div className={styles.container}>
          <GridRow>
            {/* <GridCol md={3}>
              <FormLabel>{i18n.t("pages.new-flow.filtrearchive")}</FormLabel>
              <select
                id="regulation"
                value={this.state.filtrearchive || ""}
                onChange={(e) => {
                  this.setState({
                    filtrearchive: e.target.value as Regulation,
                  });
                  this.search({ pageNumber: this.state.page - 1 });
                }}
              >
                <option value="ALL">{i18n.t("pages.new-flow.lib1")}</option>
                <option value="NOARCHIVED">
                  {i18n.t("pages.new-flow.lib2")}
                </option>
                <option value="ARCHIVED">
                  {i18n.t("pages.new-flow.lib3")}
                </option>
              </select>
            </GridCol> */}

            <GridCol md={3}>
              <FormLabel>
                {i18n.t("pages.reporting.dateFrom")}{" "}
                {/* {i18n.t("pages.new-flow.general.from")} */}
              </FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-begin"
                  type="date"
                  value={this.state.creationDateBegin || ""}
                  onChange={(e) =>
                    this.handleChange("creationDateBegin", e.target.value)
                  }
                />
              </div>
            </GridCol>

            <GridCol md={3}>
              <FormLabel>{i18n.t("pages.reporting.dateEnd")}</FormLabel>
              <div className={styles.row}>
                <input
                  id="creation-date-end"
                  type="date"
                  value={this.state.creationDateEnd || ""}
                  onChange={(e) =>
                    this.handleChange("creationDateEnd", e.target.value)
                  }
                />
              </div>
            </GridCol>
            <GridCol md={3}>
              <Button
                style={{ marginTop: 20, marginLeft: 50 }}
                onClick={() => {
                  this.exportXLS();
                }}
              >
                {i18n.t("pages.new-flow.general.expxls")}
              </Button>
            </GridCol>
          </GridRow>
        </div>
      </form>
    );
  }

  renderListFlow() {
    const handleOpen = () => this.setState({ open: true });
    const handleClose = () => this.setState({ open: false });
    const handleOpen1 = () => this.setState({ open1: true });
    const handleClose1 = () => this.setState({ open1: false });
    return (
      <div className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              {this.props.rootStore.userStore.user?.role ==
                "SUPER_ADMINISTRATOR" && (
                <TableHeaderCell> Tenant</TableHeaderCell>
              )}
              <TableHeaderCell>
                {i18n.t("pages.search-flow.id1")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.creation-dateArchive")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.departure-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.end-user-country")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.customers-company")}
              </TableHeaderCell>

              <TableHeaderCell>
                {i18n.t("pages.search-flow.model")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.folder")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.du-ml")}
              </TableHeaderCell>
              <TableHeaderCell>
                {i18n.t("pages.search-flow.regulation")}
              </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.flows.map((flow, index) => {
              let label = flowStatus["CLASSIFICATION"];

              if (flowStatus[flow.status]) {
                label = flowStatus[flow.status];
              }

              return (
                <TableRow key={index}>
                  {this.props.rootStore.userStore.user?.role ==
                    "SUPER_ADMINISTRATOR" && <td> {flow.supplier}</td>}
                  <td>
                    {flow.order_num && flow.order_num !== "null"
                      ? flow.order_num
                      : ""}
                  </td>
                  <td>{moment(flow.updatedAt).format("MMM D YYYY")}</td>
                  <td>{flow.departureCountryName}</td>
                  <td>{flow.destinationCountryName}</td>
                  <td>{flow.customer}</td>

                  <td>
                    {flow.flowAircraft
                      ? flow.flowAircraft.model
                      : flow.flowEquipments.length > 0
                        ? flow.flowEquipments[0].model
                        : ""}
                  </td>
                  <td>
                    <img
                      src={require(`../../../statics/images/folder1.png`)}
                      alt={"Folder"}
                    />
                  </td>
                  <td>
                    {flow.hasDuEccn && <Flag>DU</Flag>}

                    {flow.hasMlEccn && <Flag>ML</Flag>}
                  </td>
                  <td>
                    {flow.hasEarEccn && <Flag>EAR</Flag>}

                    {flow.hasItarEccn && <Flag>ITAR</Flag>}
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          page={this.state.page}
          count={this.state.totalResults}
          rowsPerPage={1000}
          onPageChange={(_: any, page: React.SetStateAction<number>) => this.handleChange("page", page as number)}
        />
      </div>
    );
  }
}

/* Styles import */
import "./rightPanel.css";
import styles from "./rightPanel.module.css";

/* Global import */
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { IconButton } from "../../components/horizon-components-react/src/components";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  display: boolean;
  children: any;
  onClose: () => void;
}
interface State {}

export default class RightPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  clickOutPanel(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.persist();
    const target: any = e.target;
    if (
      target &&
      target.className &&
      target.className.toString().indexOf("overlay") !== -1
    ) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <CSSTransition
        in={this.props.display}
        classNames="rightPanel"
        timeout={300}
        unmountOnExit
      >
        <div className={styles.overlay} onClick={(e) => this.clickOutPanel(e)}>
          <div className={styles.panel}>
            <IconButton
              id="close-panel"
              onClick={() => this.props.onClose()}
              className={styles.close}
            >
              <CloseIcon />
            </IconButton>

            {this.props.children}
          </div>
        </div>
      </CSSTransition>
    );
  }
}

/* Custom import */
import configuration from "../configuration";

export const getCountry = (): EccnCountry => {
  let country = window.navigator.language.split("-")[0];
  //var codePays = country.split('-')[1] || '';
  //console.log("window.nav.lang : ", country)
  if (country && country.length !== 2) {
    country = country.substring(0, 1).toUpperCase();
  }
  if (country == "GB") country = "UK";
  if (country == "fr") country = "FR";
  if (country == "de") country = "DE";
  if (country == "it") country = "IT";
  if (country == "ch") country = "CH";
  if (country == "dk") country = "DK";
  if (country == "pt") country = "PT";

  //alert(country)
  if (
    ["DE", "FR", "SP", "UK", "US", "IT", "DK", "CH", "PT"].indexOf(country) ===
    -1
  ) {
    country = configuration.DEFAULT_COUNTRY;
  }
  return country as EccnCountry;
};

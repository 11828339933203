/* Styles import */
import styles from "./basicEntityChoose.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Button,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import EntityComponent from "../../../../components/entity";
import Loader from "../../../../components/loader";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";

interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const BasicEntityChoosefr: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const [loading, setLoading] = useState(false);
  const [fuzzyResult, setFuzzyResult] = useState(false);
  const [flowId, setFlowId] = useState("");
  const [basicEntity, setBasicEntity] = useState<BasicEntity>();

  const handleSubmit = async () => {
    const tmpbasicEntity = basicEntity;
    setLoading(true);
    setLoading(false);
    if (tmpbasicEntity) {
      const formattedUrl = flowUrl(
        "/screening/basic/fr/result/" + tmpbasicEntity?.name
      );
      console.log(formattedUrl);
      navigate(formattedUrl);
    }
    setLoading(false);
  };

  const getCompany = () => {
    const company = rootStore.screeningStore.company;
    return company ? company : "";
  };

  useEffect(
    () => {
      const length = fuzzyResult
        ? rootStore.screeningStore.basicEntitiesFuzzy.length
        : rootStore.screeningStore.basicEntities.length;
      let basicEntity_choix = "";

      if (length === 1) {
        console.log(rootStore.screeningStore.basicEntities);
        rootStore.screeningStore.basicEntities.map((basicEntity, index) => {
          basicEntity_choix = basicEntity.name;
          setBasicEntity(basicEntity);
        });
      }

      console.log("bchoix : ", basicEntity_choix);
      console.log("be : ", basicEntity);
      if (basicEntity_choix) {
        const formattedUrl = flowUrl(
          "/screening/basic/fr/result/" + basicEntity_choix + "?unique=ok"
        );
        navigate(formattedUrl);
      }
    },
    [
      /*basicEntity_choix, navigate*/
    ]
  );

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      {loading && <Loader />}

      <div className={styles.body}>
        <GridRow justify="space-between">
          <GridCol>
            <Title tag="h2">
              {i18n.t("pages.screening.entity.choose-entity.title")}
            </Title>
          </GridCol>
          <GridCol>
            <div className={styles.aligner}>
              {(rootStore.screeningStore.basicEntities.length ||
                rootStore.screeningStore.basicEntitiesFuzzy.length > 0) && (
                <p className={styles.message}>
                  {`${length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${getCompany()}"                    
                    `}
                </p>
              )}
            </div>
          </GridCol>
        </GridRow>

        <div className={styles.entities}>
          {(rootStore.screeningStore.basicEntities.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy.length !== 0) && (
            <p className={styles.message}>{i18n.t("global.fuzzy")}</p>
          )}
          {!fuzzyResult &&
            rootStore.screeningStore.basicEntities.map((basicEntity, index) => {
              return (
                <EntityComponent
                  rootStore={rootStore}
                  key={index}
                  basicEntity={basicEntity}
                  onClick={() => {
                    setBasicEntity(() => {
                      return basicEntity;
                    });
                    handleSubmit();
                  }}
                  selected={
                    basicEntity && basicEntity.source === basicEntity.source
                  }
                />
              );
            })}

          {fuzzyResult &&
            rootStore.screeningStore.basicEntitiesFuzzy.map(
              (basicEntityMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntityMap}
                    onClick={() => {
                      setBasicEntity(() => {
                        return basicEntity;
                      });
                      handleSubmit();
                    }}
                    selected={
                      basicEntity &&
                      basicEntity.source === basicEntityMap.source
                    }
                  />
                );
              }
            )}
          {rootStore.screeningStore.basicEntitiesFuzzy.length !== 0 &&
            !fuzzyResult && (
              <div>
                <Button onClick={() => setFuzzyResult(true)}>
                  {i18n.t("global.view-more-interested")}
                </Button>
              </div>
            )}

          {rootStore.screeningStore.basicEntities.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy.length === 0 && (
              <p className={styles.message}>
                {i18n.t("pages.screening.entity.choose-entity.no-match")}
              </p>
            )}
        </div>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTowards("/screening/basic/other"),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("global.noMatch"),
            primary: true,
          },
        ]}
      >
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(BasicEntityChoosefr));

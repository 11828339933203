import React, { Component, ReactNode } from "react";

import "./Fade.css"; // Assurez-vous de créer le fichier CSS correspondant

interface FadeProps {
  in: boolean; // Utilisez la propriété "in" pour contrôler la visibilité
  children: ReactNode;
}

class Fade extends Component<FadeProps> {
  render() {
    const { in: isVisible, children } = this.props;

    return (
      <div className={`fade ${isVisible ? "visible" : "hidden"}`}>
        {children}
      </div>
    );
  }
}

export default Fade;

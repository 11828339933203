/* Styles import */
import styles from "../regulation.module.css";

/* Styles import */
import globalStyle from "../../../globalStyles.module.css";

/* Global import */
import React, { Component } from "react";
import i18n from "i18next";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderRegulation from "../../../components/headerRegulation";
import {
  Card,
  CardContent,
  Title,
} from "../../../components/horizon-components-react/src/components";
import ListCountry from "../../../components/listCountry";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  data: any;
}

export default class Sanctions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    await fetch(`${process.env.REACT_APP_SCREEN}/controle?categorie=sanctions`)
      .then((res) => res.json())
      .then((resultat) => {
        this.setState({ data: resultat });
      });
  }

  renderAllList() {
    const liste_country = [
      { index: 3, name: "European sanctions" },
      { index: 9, name: "France" },
      { index: 10, name: "Canada" },
      { index: 5, name: "UK" },
      { index: 6, name: "US sanctions" },
    ];
    return liste_country.map((pays, index: number) => (
      <ListCountry
        key={pays.index}
        list={this.state.data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
      />
    ));
  }

  render() {
    const listAllCountries = i18n.t("pages.regulation.sanctions.list", {
      returnObjects: true,
    });

    return (
      <div>
        <AppBar rootStore={this.props.rootStore} selectedPage={"REGULATION"} />

        <HeaderRegulation pages="SANCTIONS" />

        <div className={globalStyle.body}>
          <Card>
            <Title tag="h2">{i18n.t("pages.regulation.sanctions.title")}</Title>
            <CardContent>
              <ul className={styles.left_padding_1}>{this.renderAllList()}</ul>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

/* Global import */
import { observable, action, makeAutoObservable } from "mobx";
import i18n from "i18next";

/* Custom import */
import Api from "../utils/api";

export default class ControlReasonsStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable countriesState: RequestState = "INITIALIZE";
  @observable countriesPourcent: number = 0;

  @observable eccnsState: RequestState = "INITIALIZE";
  @observable eccnsPourcent: number = 0;

  @observable germanEccnState: RequestState = "INITIALIZE";
  @observable germanEccnPourcent: number = 0;

  @observable frenchEccnState: RequestState = "INITIALIZE";
  @observable frenchEccnPourcent: number = 0;

  @observable EccnExclusState: RequestState = "INITIALIZE";
  @observable EccnExclusState1: RequestState = "INITIALIZE";
  @observable EccnExclusState2: RequestState = "INITIALIZE";
  @observable EccnExclusState3: RequestState = "INITIALIZE";
  @observable EccnExclusState4: RequestState = "INITIALIZE";
  @observable EccnExclusState5: RequestState = "INITIALIZE";
  @observable EccnExclusState6: RequestState = "INITIALIZE";

  @observable EccnExclusPourcent: number = 0;
  @observable EccnExclusPourcent1: number = 0;
  @observable EccnExclusPourcent2: number = 0;
  @observable EccnExclusPourcent3: number = 0;
  @observable EccnExclusPourcent4: number = 0;
  @observable EccnExclusPourcent5: number = 0;
  @observable EccnExclusPourcent6: number = 0;

  @observable annexeState: RequestState = "INITIALIZE";
  @observable annexePourcent: number = 0;

  @observable list744State: RequestState = "INITIALIZE";
  @observable list744Pourcent: number = 0;

  @observable airbusModelState: RequestState = "INITIALIZE";
  @observable airbusModelPourcent: number = 0;

  @observable classificationDatabaseState: RequestState = "INITIALIZE";
  @observable classificationDatabasePourcent: number = 0;

  @observable countriesVersion: FileVersion = {
    date: "",
    fileName: "",
  };
  @observable eccnsVersion: FileVersion = {
    date: "",
    fileName: "",
  };
  @observable germanEccnVersion: FileVersion = {
    date: "",
    fileName: "",
  };

  @observable ECCN_EXCLUSVersion: FileVersion = {
    date: "",
    fileName: "",
  };
  @observable norwaysanctionVersion: FileVersion = {
    date: "",
    fileName: "",
  };
  @observable annexeVersion: FileVersion = {
    date: "",
    fileName: "",
  };

  @observable list744Version: FileVersion = {
    date: "",
    fileName: "",
  };

  @observable airbusModelVersion: FileVersion = {
    date: "",
    fileName: "",
  };

  @observable classificationDatabaseVersion: FileVersion = {
    date: "",
    fileName: "",
    content: "",
  };

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.api = new Api();
    this.rootStore = rootStore;
  }




 @action async uploadContournement_russia(file: File) {
    try {
      this.EccnExclusState6= "PENDING";

      await this.api.uploadReasons(file, "Contournement_russia", (pourcent) => {
        this.EccnExclusPourcent6 = pourcent;
      });

      this.EccnExclusState6 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      // this.getUploadGermanEccnVersion()
    } catch (e) {
      this.EccnExclusState6 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState6 = "INITIALIZE";
    }, 5000);
  }
  
  @action async uploadCodesRussia(file: File) {
    try {
      this.EccnExclusState4 = "PENDING";

      await this.api.uploadReasons(file, "ECCN_RUSSIA", (pourcent) => {
        this.EccnExclusPourcent4 = pourcent;
      });

      this.EccnExclusState4 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      // this.getUploadGermanEccnVersion()
    } catch (e) {
      this.EccnExclusState4 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState4 = "INITIALIZE";
    }, 5000);
  }

  @action async uploadCodesexceptions(file: File) {
    try {
      this.EccnExclusState4 = "PENDING";

      await this.api.uploadReasons(file, "ECCN_exceptions", (pourcent) => {
        this.EccnExclusPourcent4 = pourcent;
      });

      this.EccnExclusState4 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      // this.getUploadGermanEccnVersion()
    } catch (e) {
      this.EccnExclusState4 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState4 = "INITIALIZE";
    }, 5000);
  }

  @action async uploadEccnsExclus(file: File) {
    try {
      this.EccnExclusState = "PENDING";

      await this.api.uploadReasons(file, "ECCN_EXCLUS", (pourcent) => {
        this.EccnExclusPourcent = pourcent;
      });

      this.EccnExclusState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      // this.getUploadGermanEccnVersion()
    } catch (e) {
      this.EccnExclusState = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState = "INITIALIZE";
    }, 5000);
  }

  @action async norwaysanction(file: File) {
    try {
      this.EccnExclusState = "PENDING";

      await this.api.uploadReasons(file, "NORWAY_SANCTION", (pourcent) => {
        this.EccnExclusPourcent = pourcent;
      });

      this.EccnExclusState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState = "INITIALIZE";
    }, 5000);
  }

  @action async screeningcanada(file: File) {
    try {
      this.EccnExclusState3 = "PENDING";

      await this.api.uploadReasons(file, "SCREENING_CANADA", (pourcent) => {
        this.EccnExclusPourcent3 = pourcent;
      });

      this.EccnExclusState3 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState3 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState = "INITIALIZE";
    }, 5000);
  }

  @action async screeningeu(file: File) {
    try {
      this.EccnExclusState = "PENDING";

      await this.api.uploadReasons(file, "SCREENING_EU", (pourcent) => {
        this.EccnExclusPourcent2 = pourcent;
      });

      this.EccnExclusState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState = "INITIALIZE";
    }, 5000);
  }

  @action async screeningfr(file: File) {
    try {
      this.EccnExclusState2 = "PENDING";

      await this.api.uploadReasons(file, "SCREENING_FR", (pourcent) => {
        this.EccnExclusPourcent = pourcent;
      });

      this.EccnExclusState2 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState2 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState = "INITIALIZE";
    }, 5000);
  }

  @action async screeninguk(file: File) {
    try {
      this.EccnExclusState5 = "PENDING";

      await this.api.uploadReasons(file, "SCREENING_UK", (pourcent) => {
        this.EccnExclusPourcent5 = pourcent;
      });

      this.EccnExclusState5 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState5 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState5 = "INITIALIZE";
    }, 5000);
  }

  @action async aliasscreening(file: File) {
    try {
      this.EccnExclusState1 = "PENDING";

      await this.api.uploadReasons(file, "ALIAS_SCREENING", (pourcent) => {
        this.EccnExclusPourcent1 = pourcent;
      });

      this.EccnExclusState1 = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      //this.getUploadnorwaysanctionVersion()
    } catch (e) {
      this.EccnExclusState1 = "FAIL";
    }

    setTimeout(() => {
      this.EccnExclusState1 = "INITIALIZE";
    }, 5000);
  }
  @action async uploadFrenchEccns(file: File) {
    try {
      this.frenchEccnState = "PENDING";

      await this.api.uploadReasons(file, "FRENCH_ECCN", (pourcent) => {
        this.frenchEccnPourcent = pourcent;
      });

      this.frenchEccnState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
     // this.getUploadGermanEccnVersion();
    } catch (e) {
      this.frenchEccnState = "FAIL";
    }

    setTimeout(() => {
      this.germanEccnState = "INITIALIZE";
    }, 5000);
  }
  @action async uploadGermanEccns(file: File) {
    try {
      this.germanEccnState = "PENDING";

      await this.api.uploadReasons(file, "GERMAN_ECCN", (pourcent) => {
        this.germanEccnPourcent = pourcent;
      });

      this.germanEccnState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadGermanEccnVersion();
    } catch (e) {
      this.germanEccnState = "FAIL";
    }

    setTimeout(() => {
      this.germanEccnState = "INITIALIZE";
    }, 5000);
  }

  @action async uploadList744(file: File) {
    try {
      this.list744State = "PENDING";

      await this.api.uploadReasons(file, "ECCN_744_21", (pourcent) => {
        this.list744Pourcent = pourcent;
      });

      this.list744State = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadList744Version();
    } catch (e) {
      this.list744State = "FAIL";
    }

    setTimeout(() => {
      this.list744State = "INITIALIZE";
    }, 5000);
  }

  @action async uploadEccns(file: File) {
    try {
      this.eccnsState = "PENDING";

      await this.api.uploadReasons(file, "ECCN_CONTROL_REASON", (pourcent) => {
        this.eccnsPourcent = pourcent;
      });

      this.eccnsState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadEccnsVersion();
    } catch (e) {
      this.eccnsState = "FAIL";
    }

    setTimeout(() => {
      this.eccnsState = "INITIALIZE";
    }, 5000);
  }

  @action async uploadAnnexe(file: File) {
    try {
      this.annexeState = "PENDING";

      await this.api.uploadReasons(file, "EU_ANNEX_IV", (pourcent) => {
        this.annexePourcent = pourcent;
      });

      this.annexeState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadAnnexeVersion();
    } catch (e) {
      this.annexeState = "FAIL";
    }

    setTimeout(() => {
      this.annexeState = "INITIALIZE";
    }, 5000);
  }

  @action async uploadCountries(file: File) {
    try {
      this.countriesState = "PENDING";

      await this.api.uploadReasons(
        file,
        "COUNTRY_CONTROL_REASON",
        (pourcent) => {
          this.countriesPourcent = pourcent;
        }
      );

      this.countriesState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadCountriesVersion();
    } catch (e) {
      this.countriesState = "FAIL";
    }

    setTimeout(() => {
      this.countriesState = "INITIALIZE";
    }, 5000);
  }

  @action async uploadAirbusModel(file: File) {
    try {
      this.airbusModelState = "PENDING";

      await this.api.uploadReasons(file, "AIRBUS_MODEL", (pourcent) => {
        this.airbusModelPourcent = pourcent;
      });

      this.airbusModelState = "DONE";
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.upload.notification.success.title"),
        i18n.t("pages.admin.upload.notification.success.message"),
        "success"
      );
      this.getUploadAirbusModelVersion();
    } catch (e) {
      this.airbusModelState = "FAIL";
    }

    setTimeout(() => {
      this.airbusModelState = "INITIALIZE";
    }, 5000);
  }

  @action async submitClassificationDatabase(
    file: File,
    type: string,
    choix_file: string
  ) {
    try {
      this.classificationDatabaseState = "PENDING";
      var tenant = this.rootStore.userStore.user?.tenantId!;
      var author =
        this.rootStore.userStore.user?.lastname! +
        " " +
        this.rootStore.userStore.user?.firstname!;
        
        const rep = await this.api.submitClassificationDatabase(
        file,
        type,
        tenant,
        author,
        choix_file,
        (pourcent) => {
          this.classificationDatabasePourcent = pourcent;
        }
      );

      if (rep === "OK") {
        this.classificationDatabaseState = "DONE";
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.upload.notification.success.title"),
          i18n.t("pages.admin.upload.notification.success.message"),
          "success"
        );
      } else {

        if (typeof rep === 'string' && rep.includes("p_erreur"))
        {
          
        this.classificationDatabaseState = "FAIL";
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.upload.notification.error.title"),
          i18n.t("pages.admin.upload.notification.error.partie3") +
            " " +
            rep.replace("p_erreur","") +
            " " +
            i18n.t("pages.admin.upload.notification.error.partie4"),
          "error"
        );
      }
      else
      {
        this.classificationDatabaseState = "FAIL";
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.upload.notification.error.title"),
          i18n.t("pages.admin.upload.notification.error.partie1") +
            " " +
            rep +
            " " +
            i18n.t("pages.admin.upload.notification.error.partie2"),
          "error"
        );
      };
        //alert(rep);
      }
    } catch (e) {
      this.classificationDatabaseState = "FAIL";
    }

    setTimeout(() => {
      this.classificationDatabaseState = "INITIALIZE";
    }, 5000);
  }

  @action async getUploadGermanEccnVersion() {
    try {
      const version = await this.api.getUploadVersionApi("GERMAN_ECCN");

      if (version) {
        this.germanEccnVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadECCN_EXCLUS() {
    try {
      const version = await this.api.getUploadVersionApi("ECCN_EXCLUS");

      if (version) {
        this.ECCN_EXCLUSVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadnorwaysanctionVersion() {
    try {
      const version = await this.api.getUploadVersionApi("NORWAY_SANCTION");

      if (version) {
        this.norwaysanctionVersion = version;
      }
    } catch (e) {}
  }
  @action async getUploadList744Version() {
    try {
      const version = await this.api.getUploadVersionApi("ECCN_744_21");

      if (version) {
        this.list744Version = version;
      }
    } catch (e) {}
  }

  @action async getUploadCountriesVersion() {
    try {
      const version = await this.api.getUploadVersionApi(
        "COUNTRY_CONTROL_REASON"
      );

      if (version) {
        this.countriesVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadEccnsVersion() {
    try {
      const version = await this.api.getUploadVersionApi("ECCN_CONTROL_REASON");

      if (version) {
        this.eccnsVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadAnnexeVersion() {
    try {
      const version = await this.api.getUploadVersionApi("EU_ANNEX_IV");

      if (version) {
        this.annexeVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadAirbusModelVersion() {
    try {
      const version = await this.api.getUploadVersionApi("AIRBUS_MODEL");

      if (version) {
        this.airbusModelVersion = version;
      }
    } catch (e) {}
  }

  @action async getUploadClassificationDatabaseVersion() {
    try {
      const version = await this.api.getUploadVersionApi(
        "AIRBUS_CLASSIFICATION"
      );

      if (version) {
        this.classificationDatabaseVersion = version;
      }
    } catch (e) {}
  }
}

/* Styles import */
import styles from "./eccn.module.css";

/* Global import */
import React, { Component } from "react";
import { Title } from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import Api from "../../utils/api";
//import { refStructEnhancer } from 'mobx/lib/internal'
interface Props {
  element: Eccn;
  elementus?: Eccn;
  afficheUs: boolean;
  synonym: string;
  type: string;
  country: EccnCountry;
  search: string;
  onClick: (eccn: Eccn) => void;
  onCodeClick: (eccn: Eccn) => void;
  seeDetails: boolean;
  selected?: boolean;
}
interface State {
  isFull: boolean;
}

export default class EccnComponent extends Component<Props, State> {
  api: Api;
  constructor(props: Props) {
    super(props);

    this.api = new Api();
    this.state = {
      isFull: false,
    };
  }

  NcToNotListed(chaine: string) {
    if (chaine.toUpperCase() === "NC") {
      chaine = "NOT LISTED";
    }

    return chaine;
  }

  affichage(chaine: string) {
    this.props.synonym
      .toString()
      .split(" ")
      .map((i, key) => {
        // alert(i)
        var pattern = new RegExp(i, "gi");
        if (i !== "or")
          chaine = chaine
            .toString()
            .replace(
              pattern,
              '<Text style="background-color:yellow;padding:0">' + i + "</Text>"
            );
      });

    return chaine;
  }

  render() {
    //alert(this.props.type)
    const description = this.props.element.description;
    let description_en = this.props.element.description_en;
    const keywords = this.props.element.keywords;

    if (this.props.type == "CHECKLIST") description_en = "";
    //alert(description_en)
    const propOrigin =
      this.props.element.publicListName.substring(0, 2) == "US";
    let titre_col_2 = `EU dual-use list (2021/821)`;
    let titre_col = this.props.element.publicListName;

    if (description_en) {
      if (this.props.element.publicListName == "FR Munitions list")
        titre_col_2 = `EU Common Military List`;
      if (this.props.element.publicListName == "DE Munitions list")
        titre_col_2 = `EU Common Military List`;
    }

    if (
      this.props.element.publicListName == "FR Munitions list" &&
      this.props.country == "BE"
    )
      titre_col = "EU Common Military List";

    if (
      this.props.element.publicListName == "FR Liste Double Usage" &&
      this.props.country == "BE"
    )
      titre_col = "EU Liste double usage (2021/821)";

    if (
      this.props.element.publicListName == "FR Liste Double Usage" &&
      this.props.country == "FR"
    )
      titre_col = "Liste double usage (2021/821)";

    if (
      this.props.element.publicListName == "EU Munitions List" &&
      this.props.country == "SG"
    )
      titre_col = "SG Military Goods";

    if (
      this.props.element.publicListName == "EU Munitions List" &&
      this.props.country == "ZA"
    )
      titre_col = "Munitions List";
    if (
      this.props.element.publicListName == "EU Dual-Use List" &&
      this.props.country == "ZA"
    )
      titre_col = "Dual Use Goods and Technologies";

    if (
      this.props.element.publicListName == "EU Dual-Use List" &&
      this.props.country == "SG"
    )
      titre_col = "SG Dual-Use Goods";
    if (
      this.props.element.publicListName == "EU Munitions List" &&
      this.props.country == "CA"
    )
      titre_col = "Group 2 – Munitions";

    if (
      this.props.element.publicListName == "EU Dual-Use List" &&
      this.props.country == "CA"
    )
      titre_col = "Group 1 – Dual Use";

    if (
      this.props.element.publicListName == "EU Dual-Use List" &&
      this.props.country == "UK" &&
      this.props.type == "KEYWORD"
    )
      titre_col = "Dual-use list (EC) No 428/2009";

    return (
      ((this.props.afficheUs && propOrigin) || !propOrigin) && (
        <div
          id={this.props.element.code}
          className={`${styles.container} ${
            this.props.selected ? styles.selected : ""
          } ${
            (this.props.element.code.slice(0, -1).includes(".") ||
              this.props.element.code.slice(0, -1).includes("(")) &&
            this.props.type !== "PARTNUMBER"
              ? styles.eccncomponentleft
              : ""
          }`}
          onClick={() => this.props.onClick(this.props.element)}
          style={{
            width:
              this.props.element.code.slice(0, -1).includes(".") ||
              this.props.element.code.slice(0, -1).includes("(")
                ? "97%"
                : "100%",
            paddingRight:
              this.props.element.code.slice(0, -1).includes(".") ||
              this.props.element.code.slice(0, -1).includes("(")
                ? "10px"
                : "0px",
          }}
        >
          <table>
            <tr style={{ paddingBottom: 0 }}>
              <td
                style={{
                  width: description_en
                    ? this.props.element.hs_code?.length == 0
                      ? "30%"
                      : "30%"
                    : this.props.element.hs_code?.length == 0
                      ? "47%"
                      : "47%",
                  verticalAlign: "top",
                }}
              >
                <Title tag="h4" className={styles.title}>
                  <div className={styles.buttonsRow1}>
                    <button
                      className={styles.button1}
                      type="button"
                      onClick={() => this.props.onCodeClick(this.props.element)}
                    >
                      {(!this.props.element.isUsOrigin ||
                        !this.props.afficheUs ||
                        (this.props.element.code &&
                          this.props.elementus?.code)) &&
                        this.NcToNotListed(this.props.element.code)}
                    </button>
                  </div>
                </Title>
                <b>
                  {" "}
                  {this.props.element.code.toUpperCase() !== "NC" &&
                    (!this.props.element.isUsOrigin ||
                      !this.props.afficheUs ||
                      (this.props.element.code &&
                        this.props.elementus?.code)) &&
                    titre_col.replace(
                      "US Dual-Use List (EAR)",
                      "US Commerce Control List (CCL)"
                    )}
                </b>

                {(!this.props.element.isUsOrigin ||
                  !this.props.afficheUs ||
                  (this.props.element.code && this.props.elementus?.code)) && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.affichage(description),
                      }}
                    />
                    {this.props.type !== "CHECKLIST" &&
                      this.props.element.keywords !== "" &&
                      this.props.element.keywords !== undefined &&
                      this.props.element.keywords
                        .toLowerCase()
                        .includes(
                          this.props.synonym
                            .toString()
                            .replace("$", "")
                            .toLowerCase()
                        ) && <br />}
                    <b>
                      {this.props.type !== "CHECKLIST" &&
                        this.props.element.keywords !== "" &&
                        this.props.element.keywords !== undefined &&
                        this.props.element.keywords
                          .toLowerCase()
                          .includes(
                            this.props.synonym
                              .toString()
                              .replace("$", "")
                              .toLowerCase()
                          ) &&
                        "Info EC Compliance:"}{" "}
                    </b>
                    {this.props.type !== "CHECKLIST" &&
                      this.props.element.keywords !== "" &&
                      this.props.element.keywords !== undefined &&
                      this.props.element.keywords
                        .toLowerCase()
                        .includes(
                          this.props.synonym
                            .toString()
                            .replace("$", "")
                            .toLowerCase()
                        ) && <br />}
                    {this.props.type !== "CHECKLIST" &&
                      this.props.element.keywords !== "" &&
                      this.props.element.keywords !== undefined &&
                      this.props.element.keywords
                        .replace(/#/g, ",")
                        .split(",")
                        .map((i, key) => {
                          return (
                            <div key={key}>
                              {i
                                .toLowerCase()
                                .includes(
                                  this.props.synonym
                                    .toString()
                                    .replace("$", "")
                                    .toLowerCase()
                                ) && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: this.affichage(i + "."),
                                  }}
                                />
                              )}
                            </div>
                          );
                        })}

                    {this.props.element.notes && (
                      <div>
                        <b>Notes :</b>
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.affichage(this.props.element.notes),
                          }}
                        />
                      </div>
                    )}

                    {this.props.element.tech_notes && (
                      <p>
                        <div>
                          <b>Notes Tech : </b>
                          <br />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.affichage(
                                this.props.element.tech_notes
                              ),
                            }}
                          />
                        </div>
                      </p>
                    )}
                  </div>
                )}

                {(!this.props.element.isUsOrigin ||
                  !this.props.afficheUs ||
                  (this.props.element.code && this.props.elementus?.code)) &&
                  this.props.seeDetails &&
                  this.props.elementus?.type !== "PR" && (
                    <button
                      className={styles.button}
                      type="button"
                      onClick={() => this.props.onCodeClick(this.props.element)}
                    >
                      {i18n.t("global.searchCode")}
                    </button>
                  )}
              </td>

              {description_en && (
                <React.Fragment>
                  <td style={{ width: "5%" }}></td>
                  <td
                    style={{
                      width:
                        this.props.element.hs_code?.length == 0 ? "30%" : "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <Title tag="h4" className={styles.title}>
                      <div className={styles.buttonsRow1}>
                        <button
                          className={styles.button1}
                          type="button"
                          onClick={() =>
                            this.props.onCodeClick(this.props.element)
                          }
                        >
                          {(!this.props.element.isUsOrigin ||
                            !this.props.afficheUs ||
                            (this.props.element.code &&
                              this.props.elementus?.code)) &&
                            description_en &&
                            this.NcToNotListed(this.props.element.code)}
                        </button>
                      </div>
                    </Title>

                    <b>
                      {" "}
                      {description_en
                        ? this.props.element.code.toUpperCase() !== "NC" &&
                          titre_col_2
                        : ""}
                    </b>

                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.affichage(description_en),
                        }}
                      />

                      {this.props.type !== "CHECKLIST" &&
                        this.props.element.keywords !== "" &&
                        this.props.element.keywords
                          .toLowerCase()
                          .includes(
                            this.props.synonym
                              .toString()
                              .replace("$", "")
                              .toLowerCase()
                          ) && <br />}
                      <b>
                        {this.props.type !== "CHECKLIST" &&
                          this.props.element.keywords !== "" &&
                          this.props.element.keywords
                            .toLowerCase()
                            .includes(
                              this.props.synonym
                                .toString()
                                .replace("$", "")
                                .toLowerCase()
                            ) &&
                          "EC Compliance info  :"}{" "}
                      </b>
                      {this.props.type !== "CHECKLIST" &&
                        this.props.element.keywords !== "" &&
                        this.props.element.keywords
                          .toLowerCase()
                          .includes(
                            this.props.synonym
                              .toString()
                              .replace("$", "")
                              .toLowerCase()
                          ) && <br />}
                      {this.props.type !== "CHECKLIST" &&
                        this.props.element.keywords !== "" &&
                        this.props.element.keywords
                          .replace(/#/g, ",")
                          .split(",")
                          .map((i, key) => {
                            return (
                              <div key={key}>
                                {i
                                  .toLowerCase()
                                  .includes(
                                    this.props.synonym
                                      .toString()
                                      .replace("$", "")
                                      .toLowerCase()
                                  ) && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: this.affichage(i + "."),
                                    }}
                                  />
                                )}
                              </div>
                            );
                          })}

                      {this.props.element.notes_en && (
                        <div>
                          <b> Notes : </b>
                          <br />

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.affichage(
                                this.props.element.notes_en
                              ),
                            }}
                          />
                        </div>
                      )}

                      {this.props.element.tech_notes_en && (
                        <p>
                          <div>
                            <b>'Notes Tech :' </b>
                            <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.affichage(
                                  this.props.element.tech_notes_en
                                ),
                              }}
                            />
                          </div>
                        </p>
                      )}
                    </div>

                    {description_en && this.props.seeDetails && (
                      <button
                        className={styles.button}
                        type="button"
                        onClick={() =>
                          this.props.onCodeClick(this.props.element)
                        }
                      >
                        {i18n.t("global.searchCode")}
                      </button>
                    )}
                  </td>
                </React.Fragment>
              )}
              <td style={{ width: description_en ? "5%" : "6%" }}></td>
              <td
                style={{
                  width: description_en
                    ? this.props.element.hs_code?.length == 0
                      ? "30%"
                      : "30%"
                    : this.props.element.hs_code?.length == 0
                      ? "47%"
                      : "47%",
                  verticalAlign: "top",
                  paddingLeft:
                    this.props.element.code.slice(0, -1).includes(".") ||
                    this.props.element.code.slice(0, -1).includes("(")
                      ? this.props.element.hs_code?.length == 0
                        ? "0px%"
                        : "0px%"
                      : "0px",
                }}
              >
                <Title tag="h4" className={styles.title}>
                  <div className={styles.buttonsRow1}>
                    <button
                      className={styles.button1}
                      type="button"
                      onClick={() =>
                        this.props.onCodeClick(
                          this.props.elementus || this.props.element
                        )
                      }
                    >
                      {this.props.afficheUs && this.props.elementus?.code}
                      {this.props.element.isUsOrigin &&
                        this.props.afficheUs &&
                        !this.props.elementus?.code &&
                        this.NcToNotListed(this.props.element.code)}
                    </button>
                  </div>
                </Title>
                <b>
                  {this.props.afficheUs &&
                    this.props.elementus?.publicListName.replace(
                      "US Dual-Use List (EAR)",
                      "US Commerce Control List (CCL)"
                    )}
                  {this.props.element.isUsOrigin &&
                    this.props.afficheUs &&
                    !this.props.elementus?.publicListName &&
                    this.props.element.publicListName.replace(
                      "US Dual-Use List (EAR)",
                      "US Commerce Control List (CCL)"
                    )}
                </b>

                {this.props.afficheUs && this.props.element.isUsOrigin && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.affichage(description),
                      }}
                    />

                    {this.props.type !== "CHECKLIST" &&
                      this.props.element.keywords !== "" &&
                      this.props.element.keywords &&
                      this.props.element.keywords
                        .toLowerCase()
                        .includes(
                          this.props.synonym
                            .toString()
                            .replace("$", "")
                            .toLowerCase()
                        ) && (
                        <div>
                          <b>
                            <br />
                            EC Compliance info :
                          </b>
                          <br />
                          {this.props.element.keywords
                            .replace(/#/g, ",")
                            .split(",")
                            .map((i, key) => {
                              return (
                                <div key={key}>
                                  {i
                                    .toLowerCase()
                                    .includes(
                                      this.props.synonym
                                        .toString()
                                        .replace("$", "")
                                        .toLowerCase()
                                    ) && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.affichage(i + "."),
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                  </div>
                )}

                {this.props.afficheUs && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.affichage(
                          this.props.elementus?.description || ""
                        ),
                      }}
                    />

                    {this.props.type !== "CHECKLIST" &&
                      this.props.elementus?.keywords !== "" &&
                      this.props.elementus?.keywords &&
                      this.props.elementus?.keywords
                        .toLowerCase()
                        .includes(
                          this.props.synonym
                            .toString()
                            .replace("$", "")
                            .toLowerCase()
                        ) && (
                        <div>
                          <br />
                          <b>EC Compliance info :</b>
                          <br />
                          {this.props.elementus?.keywords
                            .replace(/#/g, ",")
                            .split(",")
                            .map((i, key) => {
                              return (
                                <div key={key}>
                                  {i
                                    .toLowerCase()
                                    .includes(
                                      this.props.synonym
                                        .toString()
                                        .replace("$", "")
                                        .toLowerCase()
                                    ) && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.affichage(i + "."),
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                  </div>
                )}

                {this.props.afficheUs &&
                  this.props.element.type !== "PR" &&
                  this.props.element.isUsOrigin &&
                  this.props.seeDetails && (
                    <button
                      className={styles.button}
                      type="button"
                      onClick={() => this.props.onCodeClick(this.props.element)}
                    >
                      {i18n.t("global.searchCode")}
                    </button>
                  )}
                {this.props.afficheUs &&
                  this.props.elementus?.type !== "PR" &&
                  this.props.elementus?.description != null &&
                  this.props.seeDetails && (
                    <button
                      className={styles.button}
                      type="button"
                      onClick={() =>
                        this.props.onCodeClick(
                          this.props.elementus || this.props.element
                        )
                      }
                    >
                      {i18n.t("global.searchCode")}
                    </button>
                  )}
              </td>
            </tr>
          </table>
        </div>
      )
    );
  }
}

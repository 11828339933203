import React, { FC, ChangeEvent, ReactNode } from "react";
import cx from "classnames";
import classes from "../Stepper/styles.module.scss";

export interface StepHeaderProps {
  className?: string;
  /** The number to display */
  index?: number;
  /** The icon to display */
  icon?: ReactNode;
  /** @ignore */
  onClick?: (value: any) => void;
  children?: ReactNode;
}

export const StepHeader: FC<StepHeaderProps> = ({
  className,
  index = 0,
  icon,
  onClick,
  children,
}) => (
  <div
    onClick={(e) => {
      if (onClick !== undefined) {
        e.stopPropagation();
        onClick(index);
      }
    }}
    className={cx(
      classes["ds-steppers__step__header"],
      {
        [classes["ds-steppers__step--clickable"]]: onClick !== undefined,
      },
      className
    )}
  >
    <span className={classes["ds-steppers__ico"]}>
      {icon || <span>{index + 1}</span>}
    </span>
    <span className={classes["ds-steppers__label"]}>{children}</span>
  </div>
);

export default StepHeader;

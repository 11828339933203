import React, { Component, RefObject } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import Cached from "@mui/icons-material/Cached";
import CheckCircle from "@mui/icons-material/CheckCircle";

export interface AutosaveProps {
  /** Additional classes. */
  className?: string;

  /** A unique identifier. */
  id?: string;

  /** Autosave saving status */
  saved?: boolean;
}

export default class Autosave extends Component<AutosaveProps> {
  static defaultProps = {
    saved: false,
  };

  constructor(props: AutosaveProps) {
    super(props);
  }

  render() {
    const { className, id, saved } = this.props;

    return (
      <div
        id={id}
        className={cx(className, {
          [classes["ds-autosave"]]: true,
          [classes["ds-autosave--saved"]]: saved,
          [classes["ds-autosave--progress"]]: !saved,
        })}
      >
        {saved ? (
          <CheckCircle aria-hidden="true" fontSize="small" />
        ) : (
          <Cached aria-hidden="true" fontSize="small" />
        )}
        {/* <span
          className={cx({
            "ds-icon-check_circle": saved,
            "ds-icon-baseline-cached": !saved
          })}
        /> */}
        <span className={cx([classes["ds-autosave__label"]])}>
          {saved ? "Saved" : "Saving…"}
        </span>
      </div>
    );
  }
}

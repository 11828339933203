import React, { FC, ReactEventHandler, ReactNode } from "react";
import cx from "classnames";
import Paper from "../Paper";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  /** Shadow depth */
  elevation?: 1 | 2 | 4 | 8;
  /** Add background color on hover and focus */
  color?: boolean;
  children?: ReactNode;
  onClick?: ReactEventHandler<{}>;
};

const Card: FC<Props> = ({
  className,
  elevation,
  onClick,
  color = false,
  children,
}) => (
  <Paper
    elevation={elevation}
    className={cx(
      classes["ds-card"],
      {
        [classes["ds-card--border"]]: !elevation,
        [classes["ds-card--clickable"]]: onClick !== undefined,
        [classes["ds-card--color"]]: color !== false,
      },
      className
    )}
    onClick={onClick}
  >
    {children}
  </Paper>
);

export default Card;

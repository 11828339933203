/* Global import */
import {
  observable,
  action,
  computed,
  runInAction,
  makeAutoObservable,
} from "mobx";

/* Custom import */
import Api from "../utils/api";

export default class ExportabilityStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable matching?: MatchingDto;
  @observable associableLicenses?: MatchingLicenseDto[];
  @observable loading: boolean = false;
  @observable qtyRemaining: { [key: string]: number } = {};
  @observable amountRemaining: { [key: string]: number } = {};
  @observable entite: string = "";

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();
  }

  async searchLicense(
    page: number = 0,
    regulation?: Regulation,
    destinationCountry?: number,
    endUser?: string
  ) {
    try {
      return await this.api.searchLicense(
        page,
        regulation,
        destinationCountry,
        endUser
      );
    } catch (e) {}
  }

  async searchLicenseById(id: number) {
    try {
      const licenseInfo = await this.api.searchLicenseById(id);
      this.entite = licenseInfo.entite;

      return licenseInfo;
    } catch (e) {}
  }

  async postLicense(
    license: PostLicenseDto,
    file?: File,
    file1?: File,
    file2?: File,
    file3?: File,
    file4?: File,
    file5?: File
  ) {
    try {
      const licenseDto = { ...license };

      licenseDto.equipments = licenseDto.equipments.filter(
        (equipment) => equipment.eccn_code !== undefined
      );
      licenseDto.consignees = licenseDto.consignees.filter(
        (consignee) => consignee.consigneeCountryId !== 0
      );
      // console.log(file);
      return await this.api.postLicense(licenseDto, file);
    } catch (e) {
      console.log(e);
    }
  }
  @action async submitDelete(userId?: number) {
    this.loading = true;

    try {
      await this.api.licenceDelete(userId);
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }

  @action
  initializeRemainingValues(
    id: string,
    qty: number,
    amount: number,
    qtyImput: number,
    amountImput: number
  ) {
    if (!this.qtyRemaining[id]) {
      this.qtyRemaining[id] = qty - qtyImput;
    }
    if (!this.amountRemaining[id]) {
      this.amountRemaining[id] = amount - amountImput;
    }
  }

  @action
  updateQtyRemaining(id: string, value: number) {
    this.qtyRemaining[id] = value;
  }

  @action
  updateAmountRemaining(id: string, value: number) {
    this.amountRemaining[id] = value;
  }
  async getMatching(flowId: number, equipmentId?: number, type?: string) {
    this.matching = await this.api.getMatching(flowId);

    if (!this.matching) {
      this.matching = await this.api.getMatchedLicenseByEquipId(
        equipmentId ?? null,
        type ?? null
      );
    }
    return true;
  }
  async getnomficher(mediaId: string) {
    try {
      return await this.api.getnomficher(mediaId);
    } catch (e) {}
  }

  async getMedia(mediaId: string) {
    try {
      return await this.api.getMedia(mediaId);
    } catch (e) {
      alert(
        "Désolé, le fichier associé à cette licence n'est pas disponible ou n'a pas encore été enregistré. Veuillez vérifier et réessayer ultérieurement."
      );
    }
  }

  async getAssociableLicense(flowId: number, equipmentId: number) {
    try {
      this.associableLicenses = await this.api.getAssociableLicense(
        flowId,
        equipmentId
      );
    } catch (e) {}

    return true;
  }

  async sendLicenseUpdate(licence: {
    license_id: number;
    equipment_id: number;
    qtyRemaining: number;
    amountRemaining: number;
    amountEquip: number;
    quantityEquip: number;
  }) {
    try {
      await this.api.sendLicenseUpdate(licence);
    } catch (e) {}

    return true;
  }

  async saveLink(
    flowId: number,
    equipmentId: number,
    licenseId: number,
    type: string
  ) {
    const bond = await this.api.saveLink(flowId, equipmentId, licenseId, type);

    if (this.matching) {
      await this.loadLinkedEquipment(equipmentId, type);
    }
  }

  async loadLinkedEquipment(equipmentId: number, type: string) {
    try {
      const links = await this.api.getLink(equipmentId, type);

      runInAction(() => {
        if (this.matching) {
          if (!this.matching.linked) {
            this.matching.linked = [];
          }
          this.matching.linked = [...this.matching.linked, ...links];
        }
      });
    } catch (error) {
      console.error("Failed to load linked equipment", error);
    }
  }

  async deleteLink(
    flowId: number,
    equipmentId: number,
    licenseId: number,
    lEquipmentId: number,
    type: string
  ) {
    try {
      await this.api.deleteLink(
        flowId,
        equipmentId,
        licenseId,
        lEquipmentId,
        type
      );

      if (this.matching?.linked) {
        this.matching.linked = this.matching.linked.filter(
          (link) => link.equipment.id !== equipmentId
        );
      }
    } catch (error) {
      console.error("Failed to delete link", error);
    }
  }

  /* Altran
  async deleteLink(flowId: number, equipmentId: number) {
    try {
      const isSuccess = await this.api.deleteLink(flowId, equipmentId);

      if (isSuccess && this.matching) {
        const deletedLink = this.matching.linked.find(
          link => link.equipment.id === equipmentId
        );
        const linked = this.matching.linked.filter(
          link => link.equipment.id !== equipmentId
        );

        this.matching.linked = linked;

        if (deletedLink) {
          this.matching.equipments.push(deletedLink.equipment);
        }
      }
    } catch (e) {}
  }
  */

  clearAssociableLicenses() {
    this.associableLicenses = [];
  }

  @action.bound
  updateAssociableLicenses(licenses: MatchingLicenseDto[]) {
    console.log("updt");
    this.associableLicenses = licenses;
  }
}

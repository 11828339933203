/* Styles import */
import styles from "../admin.module.css";
import i18n from "i18next";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "../../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";
import axios from "axios";

interface State {
  filename: string;
  file_date: string;
  filename_fr: string;
  file_date_fr: string;
  file_date_uk: string;
  filename_uk: string;
  filename_alias: string;
  file_date_alias: string;
  filename_canada: string;
  file_date_canada: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Screening: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    filename: "",
    file_date: "",
    filename_fr: "",
    file_date_fr: "",
    file_date_uk: "",
    filename_uk: "",
    filename_alias: "",
    file_date_alias: "",
    filename_canada: "",
    file_date_canada: "",
  });

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const policy = await api.get_screening_file();
      if (isMounted) {
        if (policy) {
          setState((prevState) => ({
            ...prevState,
            file_date: policy[0].date_maj,
            filename: policy[0].file_name,
          }));
        }

        const policy2 = await api.get_screening_file_fr();

        if (policy2) {
          setState((prevState) => ({
            ...prevState,
            file_date_fr: policy2[0].date_maj,
            filename_fr: policy2[0].file_name,
          }));
        }

        const policy1 = await api.get_alias_screening_file();

        if (policy1) {
          setState((prevState) => ({
            ...prevState,
            file_date_alias: policy1[0].date_maj,
            filename_alias: policy1[0].file_name,
          }));
        }

        const policy3 = await api.get_screening_file_canada();

        if (policy3) {
          setState((prevState) => ({
            ...prevState,
            file_date_canada: policy3[0].date_maj,
            filename_canada: policy3[0].file_name,
          }));
        }

        const policy4 = await api.get_screening_file_uk();

        if (policy4) {
          setState((prevState) => ({
            ...prevState,
            file_date_uk: policy4[0].date_maj,
            filename_uk: policy4[0].file_name,
          }));
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="SCREENING" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={`UK screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeninguk(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent5}
            uploadState={rootStore.controlReasonsStore.EccnExclusState5}
            label={i18n.t("pages.admin.screening_uk.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date_uk}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename_uk}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`Canada screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.screeningcanada(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent3}
            uploadState={rootStore.controlReasonsStore.EccnExclusState3}
            label={i18n.t("pages.admin.screening_ca.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date_canada}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename_canada}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`EU screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeningeu(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent}
            uploadState={rootStore.controlReasonsStore.EccnExclusState}
            label={i18n.t("pages.admin.screening_eu.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`FR screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.screeningfr(file)}
            progress={rootStore.controlReasonsStore.EccnExclusPourcent2}
            uploadState={rootStore.controlReasonsStore.EccnExclusState2}
            label={i18n.t("pages.admin.screening_fr.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date_fr}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename_fr}`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={`Alias screening`} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.aliasscreening(file)
            }
            progress={rootStore.controlReasonsStore.EccnExclusPourcent1}
            uploadState={rootStore.controlReasonsStore.EccnExclusState1}
            label={i18n.t("pages.admin.alias_screening.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: 
                    ${state.file_date_alias}
                     |   ${i18n.t("pages.admin.upload.filename")}:
                    ${state.filename_alias}`}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Screening));

import React, { useState, useEffect } from "react";
import { InputAdornment, Stack, Typography } from "@mui/material";
import {
  AiOutlineEuro,
  AiOutlineDollar,
  AiOutlineCrown,
  AiOutlinePound,
} from "react-icons/ai";

type Currency = "EUR" | "USD" | "CAD" | "SEK" | "GBP";

type MonetaryTypoProps = {
  text: string,
  currency: string,
  numberValue: number,
};

const formatNumber = (num: number) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const getCurrencyIcon = (currency?: Currency) => {
  switch (currency) {
    case "EUR":
      return <AiOutlineEuro fontSize={24} />;
    case "USD":
    case "CAD":
      return <AiOutlineDollar fontSize={24} />;
    case "SEK":
      return <AiOutlineCrown fontSize={24} />;
    case "GBP":
      return <AiOutlinePound fontSize={24} />;
    default:
      return null;
  }
};

const MonetaryTypo: React.FC<MonetaryTypoProps> = ({
  text,
  currency,
  numberValue,
}) => {
  return (
    <Stack direction="row"
    spacing={1}
    sx={{
      justifyContent: "flex-start",
      alignItems: "flex-start",
    }}>
        <Typography>
            {text + formatNumber(numberValue)}
        </Typography>
        {getCurrencyIcon(currency as Currency)}
    </Stack>
  );
};

export default MonetaryTypo;
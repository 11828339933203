import React, { FC, ChangeEvent, ReactNode } from "react";
import cx from "classnames";
import classes from "../BottomNav/styles.module.scss";

type Props = {
  className?: string;
  /** The label of the action. */
  label?: string;
  /** You can provide your own value for each action. */
  value?: any;
  /** The icon of the action */
  icon?: ReactNode;
  /** @ignore */
  selected?: boolean;
  /** @ignore */
  onChange?: (event: ChangeEvent<{}>, value: any) => void;
};

const BottomNavAction: FC<Props> = ({
  className,
  label,
  selected,
  value,
  icon,
  onChange,
}) => (
  <a
    aria-selected={selected}
    onClick={(event) => {
      if (onChange) onChange(event, value.target.value);
    }}
    className={cx(className, classes["ds-bottom-nav__action"], {
      [classes["ds-bottom-nav__action--active"]]: selected,
    })}
  >
    <span className={classes["ds-bottom-nav__action__wrapper"]}>
      {icon}
      <span className={classes["ds-bottom-nav__action__label"]}>{label}</span>
    </span>
  </a>
);

export default BottomNavAction;

import React, { FC, ReactNode } from "react";
import cx from "classnames";
import classes from "../GridContainer/styles.module.scss";

type GridSize = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type Props = {
  /**
   * Defines the number of grid cells the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   */
  xs?: GridSize;
  /**
   * Defines the number of grid cells the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  sm?: GridSize;
  /**
   * Defines the number of grid cells the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  md?: GridSize;
  /**
   * Defines the number of grid cells the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  lg?: GridSize;
  /**
   * Defines the number of grid cells the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   */
  xl?: GridSize;
  /**
   * Defines the `align-self` style property.
   */
  alignSelf?: "start" | "center" | "end";
  children?: ReactNode;
  noMargin?: boolean;
};

const GridCol: FC<Props> = ({
  xs = false,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  alignSelf,
  children,
  noMargin = false,
}) => (
  <div
    className={cx({
      [classes["ds-col"]]: !xs && !sm && !md && !lg && !xl,
      [classes[`ds-col-${xs}`]]: xs !== false,
      [classes[`ds-col-sm-${sm}`]]: sm !== false,
      [classes[`ds-col-md-${md}`]]: md !== false,
      [classes[`ds-col-lg-${lg}`]]: lg !== false,
      [classes[`ds-col-xl-${xl}`]]: xl !== false,
      [classes["ds-align-self-start"]]: alignSelf === "start",
      [classes["ds-align-self-center"]]: alignSelf === "center",
      [classes["ds-align-self-end"]]: alignSelf === "end",
    })}
    style={noMargin ? { marginBottom: 0 } : {}}
  >
    {children}
  </div>
);

export default GridCol;

/* Global import */
import { observable, action, makeObservable, makeAutoObservable } from "mobx";

/* Custom import */
import Api from "../utils/api";
import { getCountry } from "../utils/navigator";
type liste = "CANADA" | "FRANCE" | "UK";
export default class ScreeningStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable entities: Entity[] = [];
  @observable skipPage: boolean = false;
  @observable basicEntities: BasicEntity[] = [];
  @observable basicEntitiesFuzzy: BasicEntity[] = [];

  @observable basicEntities_canada: BasicEntity[] = [];
  @observable basicEntitiesFuzzy_canada: BasicEntity[] = [];

  @observable basicEntities_eu: BasicEntity[] = [];
  @observable basicEntitiesFuzzy_eu: BasicEntity[] = [];

  @observable basicEntities_fr: BasicEntity[] = [];
  @observable basicEntitiesFuzzy_fr: BasicEntity[] = [];

  @observable basicEntities_uk: BasicEntity[] = [];
  @observable basicEntitiesFuzzy_uk: BasicEntity[] = [];

  @observable basicEntitiesFinale: BasicEntityFinale[] = [];
  @observable entitiesState: RequestState;

  @observable liste_fr?: boolean;
  @observable liste_us?: boolean;
  @observable liste_eu?: boolean;
  @observable liste_canadien?: boolean;
  @observable liste_uk?: boolean;

  @observable company?: string;
  @observable country?: number;
  @observable screeningEntityResult?: ScreeningEntityResult;
  @observable screeningBasicEntityResult?: ScreeningEntityResult;
  @observable screeningEntityState: RequestState;
  @observable liste: liste = "FRANCE";
  @observable screeningEndUserResult?: ScreeningEndUserDto;

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();

    this.entitiesState = "INITIALIZE";
    this.screeningEntityState = "INITIALIZE";
  }

  @action
  setSkipPage(value: boolean) {
    this.skipPage = value;
  }
  @action setCountry(country?: number) {
    this.country = country;
  }

  @action setCompany(company?: string) {
    this.company = company;
  }

  async getEntities(company: string, country: number, nationalId?: string) {
    try {
      this.entitiesState = "PENDING";

      this.entities = await this.api.getEntities(company, country, nationalId);
      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "DONE";
    }
  }
  async getBasicEntitiesfinale(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntitiesFinale =
        await this.api.getBasicEntitiesfinale(company);

      this.basicEntitiesFinale = resultBasicEntitiesFinale.results;

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntitieseu(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities = await this.api.getBasicEntitieseu(company);

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening EU request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntitiesah(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities = await this.api.getBasicEntitiesah(
        company,
        this.rootStore.userStore.user?.tenantId || "",
      );

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening AH Menu request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntitiescanada(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities =
        await this.api.getBasicEntitiescanada(company);

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening CA request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntitiesuk(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities = await this.api.getBasicEntitiesuk(company);

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening UK request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntitiesfr(company: string) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities = await this.api.getBasicEntitiesfr(company);

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening FR request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntities_all(
    company: string,
    country: number,
    liste_us: boolean,
    liste_fr: boolean,
    liste_eu: boolean,
    liste_canada: boolean,
    liste_uk: boolean,
  ) {
    try {
      this.entitiesState = "PENDING";
      let resultBasicEntities,
        resultBasicEntities_fr,
        resultBasicEntities_canada,
        resultBasicEntities_eu,
        resultBasicEntities_uk;

      if (liste_us)
        resultBasicEntities = await this.api.getBasicEntities(company, country);

      if (liste_fr)
        resultBasicEntities_fr = await this.api.getBasicEntitiesfr(company);
      if (liste_canada)
        resultBasicEntities_canada =
          await this.api.getBasicEntitiescanada(company);

      if (liste_uk)
        resultBasicEntities_uk = await this.api.getBasicEntitiesuk(company);

      if (liste_eu)
        resultBasicEntities_eu = await this.api.getBasicEntitieseu(company);

      this.liste_fr = liste_fr;
      this.liste_canadien = liste_canada;
      this.liste_eu = liste_eu;
      this.liste_us = liste_us;
      this.liste_uk = liste_uk;

      if (liste_us && resultBasicEntities && resultBasicEntities) {
        this.basicEntities = resultBasicEntities.results;
        this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;
      }

      if (
        liste_canada &&
        resultBasicEntities_canada &&
        resultBasicEntities_canada
      ) {
        this.basicEntities_canada = resultBasicEntities_canada.results;
        this.basicEntitiesFuzzy_canada =
          resultBasicEntities_canada.fuzzyResults;
      }

      if (liste_fr && resultBasicEntities_fr && resultBasicEntities_fr) {
        this.basicEntities_fr = resultBasicEntities_fr.results;
        this.basicEntitiesFuzzy_fr = resultBasicEntities_fr.fuzzyResults;
      }
      if (liste_eu && resultBasicEntities_eu && resultBasicEntities_eu) {
        this.basicEntities_eu = resultBasicEntities_eu.results;
        this.basicEntitiesFuzzy_eu = resultBasicEntities_eu.fuzzyResults;
      }
      if (liste_uk && resultBasicEntities_uk && resultBasicEntities_uk) {
        this.basicEntities_uk = resultBasicEntities_uk.results;
        this.basicEntitiesFuzzy_uk = resultBasicEntities_uk.fuzzyResults;
      }
      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening global request",
        company,
        "",
        "",
        "" + this.rootStore.countriesStore.getCountryById(country)?.name,
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async getBasicEntities(company: string, country: number) {
    try {
      this.entitiesState = "PENDING";
      const resultBasicEntities = await this.api.getBasicEntities(
        company,
        country,
      );

      this.basicEntities = resultBasicEntities.results;
      this.basicEntitiesFuzzy = resultBasicEntities.fuzzyResults;

      //******************** la log      *********************/*

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening US request",
        company,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        JSON.stringify(this.basicEntities),
      );

      /** ***********************************************/

      this.entitiesState = "DONE";
    } catch (error) {
      this.entitiesState = "FAIL";
    }
  }

  async screeningEntity(bvdId: string, name: string, flowId?: number) {
    try {
      this.screeningEntityState = "PENDING";

      this.screeningEntityResult = await this.api.screeningEntity(
        bvdId,
        name,
        flowId,
      );
      this.screeningEntityState = "DONE";
    } catch (error) {
      this.screeningEntityState = "FAIL";
    }
  }

  async screeningBasic(
    hasSanction: boolean,
    flowId?: number,
    source?: string,
    name?: string,
  ) {
    try {
      this.screeningEntityState = "PENDING";

      this.screeningBasicEntityResult = await this.api.screeningBasic(
        hasSanction,
        flowId,
        source,
        name,
      );

      //******************** la log      *********************/

      //Addlog
      const logr = this.api.AddLog(
        this.rootStore.userStore.user?.tenantId || "",
        this.rootStore.userStore.user?.email || "",
        this.rootStore.userStore.user?.role || "",
        "Screening result ",
        name || "",
        this.screeningBasicEntityResult.trafficLightColor,
        "",
        "",
        "",
        "",
        "",
        "",
        source || "",
        "",
        "",
      );

      /** ***********************************************/

      this.screeningEntityState = "DONE";
    } catch (error) {
      this.screeningEntityState = "FAIL";
    }
  }

  async screeningEndUser(firstname: string, lastname: string, flowId?: number) {
    try {
      this.screeningEndUserResult = await this.api.screeningEndUser(
        firstname,
        lastname,
        flowId,
      );

      return true;
    } catch (e) {}
  }

  setListe(liste: liste) {
    //this.clear()
    this.liste = liste;
  }
}

/* Global import */
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
window.moment = moment;
/* Custom import */
import App from "./app/App";
import { MobXProvider } from "./app/stores/mobXContext";
import * as serviceWorker from "./serviceWorker";
import RootStore from "./app/stores/rootStore";
const rootStore = new RootStore();

ReactDOM.render(
  <MobXProvider>
    <App rootStore={rootStore} />
  </MobXProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();

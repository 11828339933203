import React, { Component, PointerEvent } from "react";
import cx from "classnames";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "../Collapse";
import classes from "./styles.module.scss";

type Props = {
  /** The title of the panel */
  title?: string;
  /** If `true`, the panel is expanded */
  expanded?: boolean;
  /** The default expanded status for an uncontrolled panel */
  defaultExpanded?: boolean;
  /** The callback for when the expansion state changes */
  onChange?: (
    event: PointerEvent<HTMLButtonElement>,
    expanded: boolean
  ) => void;
};

type State = {
  expanded?: boolean;
};

export default class Panel extends Component<Props, State> {
  private isControlled: boolean;

  constructor(props: Props) {
    super(props);
    this.isControlled = props.expanded != null;
    this.state = this.isControlled
      ? {}
      : {
          expanded:
            props.defaultExpanded !== undefined ? props.defaultExpanded : false,
        };
  }

  handleChange = (event: PointerEvent<HTMLButtonElement>) => {
    const expanded = this.isControlled
      ? this.props.expanded
      : this.state.expanded;

    if (!this.isControlled) {
      this.setState({ expanded: !expanded });
    }
    if (this.props.onChange) {
      this.props.onChange(event, !expanded);
    }
  };

  render() {
    const { title } = this.props;
    const expanded = this.isControlled
      ? this.props.expanded
      : this.state.expanded;
    return (
      <div
        className={cx(classes["ds-accordion__item"], {
          [classes["ds-accordion__item--active"]]: expanded,
        })}
      >
        <button
          type="button"
          className={classes["ds-accordion__button"]}
          aria-haspopup="true"
          onClick={this.handleChange}
        >
          {title}
          {!expanded && <AddIcon className={classes["ds-icon-add"]} />}
          {expanded && <RemoveIcon className={classes["ds-icon-minimize"]} />}
        </button>
      </div>
    );
  }
}

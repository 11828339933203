/* Styles import */
import styles from "./countryEdit.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import {
  Title,
  FormGroup,
  Checkbox,
  Button,
  Loading,
  TextInput,
} from "../../components/horizon-components-react/src/components";
import Api from "../../utils/api";
/* Custom import */
import RightPanel from "../rightPanel";
import i18n from "i18next";
import { Divider } from "@mui/material";

interface CorporateSanction {
  countryId: number;
  active: boolean;
}

interface Props {
  country?: Country;
  onClose: () => void;
  rootStore: import("../../stores/rootStore").default;
}
interface State {
  corporateSanction?: CorporateSanction;
  message: string;
  feu: string;
}

const CountryEdit: React.FC<Props> = ({ rootStore, country, onClose }) => {
  const api = new Api();
  const [state, setState] = useState({
    feu: "GREEN",
    message: "",
    corporateSanction: {
      countryId: 0,
      active: false,
    },
  });
const [feu, setfeu] = useState("GREEN");
  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        await rootStore.countriesStore.getControlCategoriesByCountry(
          country.id,
        );

         const policy = await api.Getpolicy(
            country.id,
            rootStore.userStore.user?.tenantId || "",
          );

       
         
          if (policy && policy.length > 0) {
            setState({ ...state, feu: policy[0].feu });
            setState({ ...state, message: policy[0].message });

            setfeu(policy[0].feu)
          } else {
            setState({ ...state, feu: "GREEN" });
            setState({ ...state, message: "" });
            setfeu( "GREEN")
          }
       //alert (policy[0].feu )

        
      
      }
    
    };

    // Appelez la fonction fetchData lorsque la propriété country change
    fetchData();
  }, [country]);

  const saveCountry = () => {
    if (country) {
      rootStore.countriesStore.setCountryControlCategory(country.id);
      if (state && state.corporateSanction) {
        rootStore.countriesStore.setCountryControlCategorySanction(
          state.corporateSanction.countryId,
          state.corporateSanction.active,
        );
      }

      if (rootStore.countriesStore.countryControlCategoriesSanctions.present)
        api.Postpolicy(
          country.id,
          rootStore.userStore.user?.tenantId || "",
          state.message,
          state.feu,
        );
      onClose();
    }
  };

  return (
    <div>
      <RightPanel display={country !== undefined} onClose={() => onClose()}>
        <Title tag="h4">{country && country.name}</Title>

        <form className={styles.form}>
          {rootStore.countriesStore.loadingControlCategories && <Loading />}
          {rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"]) &&
            rootStore.countriesStore.countryControlCategories.map(
              (countryControlCategory, index) => {
                return (
                  <FormGroup key={index}>
                    <Checkbox
                      className={styles.checkbox}
                      large={true}
                      label={countryControlCategory.controlCategory.name}
                      checked={countryControlCategory.active}
                      onChange={(e) => {
                        rootStore.countriesStore.setControlCategory(
                          countryControlCategory.controlCategory.id,
                          e.target.checked,
                        );
                      }}
                    />
                  </FormGroup>
                );
              },
            )}
          {rootStore.userStore.isAuthorized([
            "ADMINISTRATOR_GROUP",
            "C_E_ADMIN",
            "ADMINISTRATOR_SITE",
          ]) && (
            <div>
              <FormGroup key={"controlSanction"}>
                <label className={styles.label}>Light</label>
                <select
                  className={styles.select}
                  value={feu}
                  onChange={(e) => {
                    setState({ ...state, feu: e.target.value });
                  }}
                >
                  <option value="GREEN">GREEN</option>
                  <option value="YELLOW">YELLOW </option>
                  <option value="RED">RED</option>
                </select>
              </FormGroup>
              <FormGroup>
                <TextInput
                  id="equipment-supplier"
                  label="Message"
                  value={state.message}
                  errorText={i18n.t("errors")}
                  onChange={(value) =>
                    setState({ ...state, message: value.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={"Corporate Sanctions"}
                  checked={
                    rootStore.countriesStore.countryControlCategoriesSanctions
                      .present
                  }
                  onChange={(e) => {
                    country &&
                      setState({
                        ...state,
                        corporateSanction: {
                          countryId: country.id,
                          active: e.target.checked,
                        },
                      });
                    rootStore.countriesStore.countryControlCategoriesSanctions.present =
                      e.target.checked;
                  }}
                />
              </FormGroup>
            </div>
          )}
        </form>
        <div className={styles.Button}>
          <Button
            id="save-panel"
            onClick={() => saveCountry()}
            primary
            size="large"
          >
            {i18n.t("global.save")}
          </Button>
        </div>
      </RightPanel>
    </div>
  );
};

export default inject("rootStore")(observer(CountryEdit));

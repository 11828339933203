import React, { Component } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";
import Tabs from "../Tabs";
import { cloneElementWithClassName } from "../../lib/utils";

const SCROLL_TRESHOLD = 250;

type Props = {
  className?: string;
  /** @ignore */
  prominent?: boolean;

  static?: boolean;
  children?: React.ReactNode;
};
type State = {
  collapsed: boolean;
};

export default class AppBar extends Component<Props, State> {
  private scrollTarget: any = window;
  state = {
    collapsed: false,
  };

  onScroll = () => {
    const scrollY =
      this.scrollTarget[
        this.scrollTarget === window ? "pageYOffset" : "scrollTop"
      ];
    if (scrollY > SCROLL_TRESHOLD) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: false });
    }
  };

  componentDidMount() {
    this.onScroll();
  }

  componentWillMount() {
    if (this.props.prominent) {
      this.scrollTarget.addEventListener("scroll", this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.prominent) {
      this.scrollTarget.removeEventListener("scroll", this.onScroll);
    }
  }

  render() {
    const { className, prominent, static: staticBar } = this.props;
    const { collapsed } = this.state;
    const children = React.Children.map(this.props.children, (child) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      if (child.type === Tabs) {
        return cloneElementWithClassName(child, classes["ds-appbar-tabs"]);
      }
      return child;
    });
    return (
      <div
        className={cx(className, classes["ds-appbar"], {
          [classes["ds-appbar--static"]]: staticBar,
          [classes["ds-appbar--prominent"]]: prominent,
          [classes["ds-appbar--prominent-collapsed"]]: prominent && collapsed,
        })}
      >
        {children}
      </div>
    );
  }
}

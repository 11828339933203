/* Styles import */
import styles from "./consignee.module.css";

/* Global import */
import React, { useEffect, useState } from "react";
import {
  GridRow,
  GridCol,
  TextInput,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";

/* Custom import */
import AutoComplete from "../autoComplete";

interface Props {
  index: number;
  countries: Option[];
  consignee: Consignee;
  onChange: (consignee: Consignee) => void;
  historyValues?: Consignee;
  disabled?: boolean;
}

const ConsigneeComponent = ({
  index,
  countries,
  consignee,
  onChange,
  historyValues,
  disabled,
}: Props) => {
  const [name, setName] = useState(consignee.consigneeName);
  const [countryId, setCountryId] = useState(consignee.consigneeCountryId);

  useEffect(() => {
    if (historyValues) {
      setName(historyValues.consigneeName);
      setCountryId(historyValues.consigneeCountryId);
    }
  }, [name, countryId, historyValues]);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);

    onChange({
      consigneeName: e.target.value,
      consigneeCountryId: countryId,
    });
  };

  const handleCountry = (countryId: number) => {
    setCountryId(countryId);

    onChange({
      consigneeName: name,
      consigneeCountryId: countryId,
    });
  };

  return (
    <div className={styles.box}>
      <div className={styles.boxHeader}>
        {`${i18n.t("pages.license.add.consignees")} ${index + 1}`}
      </div>

      <GridRow>
        <GridCol md={4} xs={12}>
          <TextInput
            id={"consignee-name"}
            label={i18n.t("pages.license.add.consignee-name")}
            value={name || ""}
            errorText={i18n.t("errors")}
            disabled={disabled || false}
            onChange={handleName}
          />
        </GridCol>

        <GridCol md={4} xs={12}>
          <AutoComplete
            id={"consignee-country"}
            label={i18n.t("pages.license.add.consignee-country")}
            onChange={handleCountry}
            disabled={disabled || false}
            options={countries}
            value={countryId || ""}
          />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default ConsigneeComponent;

import React, {
  Component,
  InputHTMLAttributes,
  ReactNode,
  ChangeEvent,
} from "react";
import cx from "classnames";
import uniqueId from "lodash.uniqueid";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import classes from "./styles.module.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  /** The label to show next to the <input /> */
  label?: string;
  /** The icon to display when unchecked */
  icon?: ReactNode;
  /** The icon to display when checked */
  checkedIcon?: ReactNode;
  /** If `true` the radio will be larger */
  large?: boolean;
  /** The callback for when the input checked status changes */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  capture?: boolean | "user" | "environment"; // Mettez capture en conformité avec le HTML5
}

interface IRadioState {
  checked?: boolean;
}

type IRadioProps = IProps &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof InputHTMLAttributes<HTMLInputElement>, "onChange">
  >;

export default class Radio extends Component<IRadioProps, IRadioState> {
  private id: string = uniqueId("radio-");
  private isControlled: boolean = false;

  constructor(props: IRadioProps) {
    super(props);
    this.isControlled = props.checked != null;
    this.state = this.isControlled
      ? {}
      : {
          checked:
            props.defaultChecked !== undefined ? props.defaultChecked : false,
        };
  }

  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    if (!this.isControlled) {
      // Créer un événement synthétique et y ajouter la valeur booléenne
      const syntheticEvent = new Event("change");
      Object.defineProperty(syntheticEvent, "target", {
        writable: false,
        value: { checked },
      });

      // Appeler onChange avec l'événement synthétique
      this.props.onChange(
        syntheticEvent as unknown as ChangeEvent<HTMLInputElement>,
      );
    }
  };

  render() {
    const {
      label,
      large,
      icon = <RadioButtonUncheckedIcon />,
      checkedIcon = <RadioButtonCheckedIcon />,
      disabled,
      onChange,
      ...rest
    } = this.props;
    const id = this.props.id || this.id;

    const checked = this.isControlled ? this.props.checked : this.state.checked;

    return (
      <div
        className={cx(classes["ds-radio"], {
          [classes["ds-radio--standalone"]]: label === undefined,
          [classes["ds-radio--checked"]]: checked,
          [classes["ds-radio--large"]]: large,
          [classes["ds-radio--disabled"]]: disabled,
        })}
      >
        <span className={classes["ds-radio-button"]}>
          {checked ? checkedIcon : icon}
          <input
            id={id}
            type="radio"
            disabled={disabled}
            onChange={this.handleInputChange}
            {...rest}
          />
        </span>

        {label && <label htmlFor={id}>{label}</label>}
      </div>
    );
  }
}

/* Styles import */
import styles from "./results.module.css";
import Api from "../../utils/api";
/* Global import */
import React, { Component, Fragment, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Title,
  GridRow,
  FormGroup,
  Checkbox,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import ArrowIcon from "@mui/icons-material/ArrowRightAlt";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";
import { toJS } from "mobx";

/* Custom import */
import AppBar from "../../components/appBar";
import ScreeningResult from "../../components/screeningResult/screeningResult";
import Footer from "../../components/footer";
import { flowUrl } from "../../utils/generator";
import TimeLine from "../../components/timeLine";
import axios, { AxiosError } from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

// import Button from "@mui/material/Button";
import { Button } from "../../components/horizon-components-react/src/components";

import Typography from "@mui/material/Typography";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "1px solid #0085AD",
  boxShadow: 24,
  p: 4,
};

interface State {
  Chk0: boolean;
  Chk1: boolean;
  Chk2: boolean;
  Chk3: boolean;
  Chk4: boolean;
  Chk5: boolean;
  Chk6: boolean;
  Accord: String;
  orderNum: String;
}

interface Props {
  rootStore: import("../../stores/rootStore").default;
}
const Shipping: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  // const goBack = () => {
  //   navigate(-1);
  // };
  const { id } = useParams() as {
    id?: number;
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Chk0, setChk0] = useState(false);
  const [Chk1, setChk1] = useState(false);
  const [Chk2, setChk2] = useState(false);
  const [Chk3, setChk3] = useState(false);
  const [Chk4, setChk4] = useState(false);
  const [Chk5, setChk5] = useState(false);
  const [Chk6, setChk6] = useState(false);
  const [Accord, setAccord] = useState("");
  const [orderNum, setOrderNum] = useState("");
  const [linknat, setlinknat] = useState<MatchingLinkedDto[]>([]);
  const [linkus, setlinkus] = useState<MatchingLinkedDto[]>([]);
  const [imputated, setImputated] = useState<Boolean>(false);
  interface FlowDto {
    id: number;
    flowType: string;
    intangibleType: string;
    delivery_date: string | null;
    customer: string;
    status: string;
    itemType: string;
    departureCountryId: number;
    departureCountryName: string;
    destinationCountryId: number;
    destinationCountryName: string;
    transitCountryId: number;
    userId: number;
    flowEquipments: FlowEquipment[];
    flowConsignees: any[];
    createdAt: string;
    updatedAt: string;
    hasMlEccn: boolean;
    hasDuEccn: boolean;
    hasItarEccn: boolean;
    hasEarEccn: boolean;
    order_num: string | null;
    hasNcEccn: boolean;
    hasEar99Eccn: boolean;
    quantity: number;
  }

  interface FlowEquipment {
    id: number;
    equipmentType: string;
    model: string;
    quantity: number;
    partNumber: string;
    designation: string;
    nationalEccn: string;
    usEccn: string;
    amount: number;
    unitPrice: number;
    currency: string;
    cufCnr: boolean;
    orderLine: string;
  }

  const [state, setState] = useState({ vertical: "top", horizontal: "center" });
  const [resultFlow, setResultFlow] = useState<FlowDto>({} as FlowDto);
  const { vertical, horizontal } = state;
  const [snackOpen, setSnackOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);
  const handleCloseSnack = () => {
    setSnackOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      // const result = rootStore.flowStore.flowExportability;
      let resultFlow;

      // if (result && result.flowId) {
      resultFlow = await rootStore.flowStore.getById(id as number);
      if (resultFlow && resultFlow.order_num) {
        setResultFlow(resultFlow as unknown as FlowDto);
        setOrderNum(resultFlow.order_num);
      }
      // }
      if (resultFlow && resultFlow.flowEquipments) {
        let typeNational = "NATIONAL";
        let typeUS = "US";

        let promises = resultFlow.flowEquipments.map(async (equipment) => {
          if (equipment.id !== undefined) {
            let equipmentId = equipment.id;

            let linksNational = api.getLink(equipmentId, typeNational);
            let linksUS = api.getLink(equipmentId, typeUS);

            return Promise.all([linksNational, linksUS]);
          } else {
            return Promise.resolve([[], []]);
          }
        });

        let results = await Promise.all(promises);

        let allLinksNational = results.flatMap((result) => result[0]);
        let allLinksUS = results.flatMap((result) => result[1]);
        setlinknat(results.flatMap((result) => result[0]));
        setlinkus(results.flatMap((result) => result[1]));
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    setAccord(i18n.t("global.accord_flux"));
    //match.params.id
    // const { id } = useParams();
    const msg = await api.archiveflow(id + "");
  };

  const goToScreeningBasic = () => {
    // const { id } = useParams();
    const newUrl = flowUrl("/screening/basic/all", id?.toString());
    navigate(newUrl);
  };

  const goToScreeningEntity = () => {
    // const { id } = useParams();
    const newUrl = flowUrl("/screening/entity", id?.toString());
    navigate(newUrl);
  };

  const goToLicenseMatching = () => {
    // const { id } = useParams();
    const newUrl = flowUrl("/license/matching", id?.toString());
    navigate(newUrl);
  };

  const getScreeningButton = () => {
    const screeningType =
      (rootStore.userStore.user && rootStore.userStore.user.screeningType) ||
      undefined;

    if (
      rootStore.userStore.user?.role == "L_A" ||
      rootStore.userStore.user?.role == "L_R_A"
    ) {
      return {
        id: "previousLink",
        onClick: () => navigate(-1),
        label: i18n.t("global.previous"),
        primary: true,
      };
    }
    if (screeningType === "USE_BUILT_IN_SCREENING") {
      return {
        id: "screeningLink",
        onClick: () => goToScreeningBasic(),
        label: i18n.t("global.screening"),
        primary: true,
      };
    }
    return {
      id: "screeningLink",
      onClick: () => goToScreeningEntity,
      label: i18n.t("global.screening"),
      primary: true,
    };
  };

  const areAllCheckboxesChecked = () => {
    return Chk0 && Chk1 && Chk2 && Chk3 && Chk4 && Chk5 && Chk6;
  };

  const getTrafficLightColor = () => {
    if (areAllCheckboxesChecked()) {
      return "green";
    }

    return "yellow";
  };

  // dégueulasse : permet d'avoir les valeurs mts/qty quand la licence est matchée
  const renameKeys = (obj: any) => {
    const keyMap = {
      amountOrdered: "amount_ordered",
      amountDelivered: "amount_delivered",
      quantityOrdered: "quantity_ordered",
      quantityDelivered: "quantity_delivered",
      unitPrice: "unit_price",
    } as any;

    return Object.keys(obj).reduce((acc: any, key: any) => {
      const newKey = keyMap[key] || key;
      acc[newKey] = obj[key];
      return acc;
    }, {});
  };
  // dégueulasse : permet d'avoir les valeurs mts/qty quand la licence est matchée

  const renderResults = () => {
    const trafficLightColor = getTrafficLightColor();

    const result = rootStore.flowStore.flowExportability;
    // let resultFlow;

    // if (result && result.flowId) {
    //   resultFlow = rootStore.flowStore.getById(result.flowId);
    // }
    let feu_global = "green";
    let feu = "green";
    let feu_exportabilite = "green";
    let feu_screnning = "green";
    let feu_licence = "green";

    if (result?.neededLicences.length != 0) feu_licence = "yellow";
    if (result?.generalScreeningDetails.length != 0)
      feu_exportabilite = "yellow";
    if (result?.customerCountryScreeningDetails.length != 0)
      feu_screnning = "yellow";

    if (feu_licence === "yellow") feu_global = "yellow";
    if (feu_exportabilite === "yellow") feu_global = "yellow";
    if (feu_screnning === "yellow") feu_global = "yellow";

    {
      rootStore.userStore.user?.role == "L_A" && (feu_global = "yellow");
    }

    const formatNumberWithSpaces = (number: { toString: () => string }) => {
      return (+number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const licensesArray = [] as any;

    // Parcourir chaque objet dans votre tableau d'objets
    linknat.forEach((objet) => {
      // Extraire les valeurs nécessaires de l'objet licence
      const tmp = renameKeys(objet.license);
      let licenseValues = {
        id: tmp.id,
        quantity_delivered: tmp.quantity_delivered,
        quantity_ordered: tmp.quantity_ordered,
        quantity_actual: tmp.quantity_actual,
        quantity_initial: tmp.quantity_initial,
        amount_delivered: tmp.amount_delivered,
        amount_ordered: tmp.amount_ordered,
        amount_actual: tmp.amount_actual,
        amount_initial: tmp.amount_initial,
        unitPrice: objet.equipment.unit_price,
        quantity: objet.equipment.quantity,
        amount: objet.equipment.amount,
        lnumber: tmp.license_number,
        pnumber: objet.equipment.part_number,
        equipId: objet.equipment.id,
      };

      licensesArray.push(licenseValues);
    });

    const getFlowDataForEquipment = (equipmentId: any) => {
      const equipment = resultFlow.flowEquipments.find(
        (equip) => equip.id === equipmentId
      );

      if (equipment) {
        return {
          amount: equipment.amount,
          quantity: equipment.quantity,
        };
      } else {
        return null;
      }
    };

    const imput = async () => {
      licensesArray.forEach(
        async (obj: {
          id: number;
          equipId: number;
          quantity_delivered: number;
          amount_delivered: number;
          quantity_actual: number;
          amount_actual: number;
        }) => {
          // Récupérer les valeurs de l'objet
          const {
            id,
            equipId,
            quantity_delivered,
            amount_delivered,
            quantity_actual,
            amount_actual,
          } = obj;
          const flowData = getFlowDataForEquipment(equipId);
          if (flowData) {
            // Utilisez flowData.amount et flowData.quantity pour obtenir les données de flux
            const amount = flowData.amount;
            const quantity = flowData.quantity;
            const payload = {
              license_id: id,
              equipment_id: equipId,
              qtyRemaining: quantity_actual,
              amountRemaining: amount_actual,
              amountEquip: amount_delivered + amount,
              quantityEquip: quantity_delivered + quantity,
            };
            try {
              await rootStore.licenseStore.sendLicenseUpdate(payload);
              // Afficher un message de succès
              setMessageNotification(i18n.t("notifications.form.success"));
              setStatusSnack(0);
              setSnackOpen(true);
            } catch (error) {
              // Afficher un message d'échec
              setMessageNotification(i18n.t("notifications.form.failure"));
              setStatusSnack(1);
              setSnackOpen(true);
            } finally {
              // Rediriger après un délai
              // setTimeout(() => {
              //   navigate("#");
              // }, 2000);
            }
          } else {
            alert("no flow loaded");
          }
        }
      );
    };

    return (
      <div className={styles.messageBody}>
        <div className={styles.header}>
          <GridRow justify="space-between">
            <div className={styles.row}>
              {
                <img
                  className={styles.traficLight}
                  src={require(
                    `../../../statics/images/global-traffic-${
                      rootStore.userStore.user?.role == "L_A" ||
                      rootStore.userStore.user?.role == "L_R_A"
                        ? trafficLightColor
                        : feu_global
                    }.svg`
                  )}
                  alt={"traficLight"}
                />
              }

              <div>
                <Title tag="h2">{i18n.t("global.titre_rapport")}</Title>
                <Title tag="h4">
                  <p className={styles.arrow}>
                    {resultFlow?.departureCountryName}
                    <ArrowIcon />
                    {resultFlow?.destinationCountryName}
                  </p>
                </Title>
              </div>

              <div style={{ paddingLeft: "480px" }}>
                {orderNum && orderNum !== "null" ? (
                  <b>
                    {" "}
                    {i18n.t("global.flows_in")} N° {orderNum}
                  </b>
                ) : (
                  ""
                )}
                <br />
              </div>
            </div>
          </GridRow>
          <br />
          {rootStore.userStore.user?.role !== "L_A" &&
            rootStore.userStore.user?.role !== "L_R_A" && (
              <div>
                <GridRow justify="space-between">
                  <div className={styles.result}>
                    <div
                      className={`${styles.traficLightBox}${styles[feu]}`}
                    ></div>
                    <img
                      className={styles.traficLight}
                      src={require(
                        `../../../statics/images/traffic-${feu}.svg`
                      )}
                      alt={"traficLight"}
                    />

                    <div>
                      <Title className={styles.title} tag="h3">
                        {i18n.t("global.classification")}
                      </Title>

                      <div className={styles.reasons}>
                        <div
                          className="post__content"
                          dangerouslySetInnerHTML={{
                            __html: i18n.t("global.msg_classement"),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </GridRow>
                {/* EXPORTABILITE */}
                {/* <GridRow justify="space-between">
                  <div className={styles.result}>
                    <div
                      className={`${styles.traficLightBox} ${styles[feu_exportabilite]}`}
                    ></div>
                    <img
                      className={styles.traficLight}
                      src={require(
                        `../../../statics/images/traffic-${feu_exportabilite}.svg`
                      )}
                      alt={"traficLight"}
                    />

                    <div>
                      <Title className={styles.title} tag="h3">
                        {i18n.t("global.exportability")}
                      </Title>

                      {result?.generalScreeningDetails.map((reason, index) => {
                        return (
                          <div className={styles.reasons}>
                            <div
                              className="post__content"
                              dangerouslySetInnerHTML={{
                                __html: reason.message,
                              }}
                            ></div>

                            <br />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </GridRow> */}
                {/* SCREENING */}
                {/* <GridRow justify="space-between">
                  <div className={styles.result}>
                    <div
                      className={`${styles.traficLightBox} ${styles[feu_screnning]}`}
                    ></div>
                    <img
                      className={styles.traficLight}
                      src={require(
                        `../../../statics/images/traffic-${feu_screnning}.svg`
                      )}
                      alt={"traficLight"}
                    />

                    <div>
                      <Title className={styles.title} tag="h3">
                        {i18n.t("global.screening")}
                      </Title>

                      {result?.customerCountryScreeningDetails.map(
                        (reason, index) => {
                          return (
                            <div className={styles.reasons}>
                              <div
                                className="post__content"
                                dangerouslySetInnerHTML={{
                                  __html: reason.message,
                                }}
                              >
                                {" "}
                              </div>

                              <br />
                            </div>
                          );
                        }
                      )}

                      {result?.customerCountryScreeningDetails.length == 0 && (
                        <div className={styles.reasons}>
                          <div
                            className="post__content"
                            dangerouslySetInnerHTML={{
                              __html: i18n.t("global.msg_screening"),
                            }}
                          ></div>

                          <br />
                        </div>
                      )}
                    </div>
                  </div>
                </GridRow> */}
                <GridRow justify="space-between">
                  <div className={styles.result}>
                    <div
                      className={`${styles.traficLightBox} ${styles[feu_licence]}`}
                    ></div>
                    <img
                      className={styles.traficLight}
                      src={require(
                        `../../../statics/images/traffic-${feu_licence}.svg`
                      )}
                      alt={"traficLight"}
                    />

                    <div>
                      <Title className={styles.title} tag="h3">
                        {i18n.t("global.license-mngt")}
                      </Title>

                      {result?.neededLicences.length != 0 &&
                        result?.optionalItemsNationalLicenses.length != 0 &&
                        result?.optionalItemsUsEarLicenses.length == 0 &&
                        result?.optionalItemsUsItarLicenses.length == 0 && (
                          <div className={styles.title}>
                            <div
                              className="post__content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  i18n.t("global.licence_msg2_1") +
                                  resultFlow?.departureCountryName +
                                  i18n.t("global.licence_msg2_2"),
                              }}
                            ></div>
                            <br />
                          </div>
                        )}

                      {result?.neededLicences.length != 0 &&
                        result?.optionalItemsNationalLicenses.length == 0 &&
                        (result?.optionalItemsUsEarLicenses.length != 0 ||
                          result?.optionalItemsUsItarLicenses.length != 0) && (
                          <div className={styles.title}>
                            <div
                              className="post__content"
                              dangerouslySetInnerHTML={{
                                __html: i18n.t("global.licence_msg3"),
                              }}
                            ></div>

                            <br />
                          </div>
                        )}

                      {result?.neededLicences.length != 0 &&
                        result?.optionalItemsNationalLicenses.length != 0 &&
                        (result?.optionalItemsUsEarLicenses.length != 0 ||
                          result?.optionalItemsUsItarLicenses.length != 0) && (
                          <div className={styles.title}>
                            <div
                              className="post__content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  i18n.t("global.licence_msg4_1") +
                                  resultFlow?.departureCountryName +
                                  i18n.t("global.licence_msg4_2") +
                                  "<br>",
                                // +
                                // i18n.t("global.licence_msg4_3"),
                              }}
                            ></div>

                            <br />
                          </div>
                        )}

                      {result?.neededLicences.length == 0 && (
                        <div className={styles.title}>
                          <div
                            className="post__content"
                            dangerouslySetInnerHTML={{
                              __html: i18n.t("global.licence_msg1"),
                            }}
                          ></div>

                          <br />
                        </div>
                      )}
                    </div>
                  </div>
                </GridRow>
              </div>
            )}
          {/* {imputated && (
            <Box sx={{ padding: "4em", margin: "0 auto" }}>
              <b> {i18n.t("pages.license.menu.depreciated")}</b>
            </Box>
          )} */}
          {!imputated && (
            <GridRow justify="end">
              {/* <Button onClick={handleOpen} primary>
                {i18n.t("pages.license.menu.depreciate_licences")}
              </Button> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction="column"
                    useFlexGap
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div>
                      {licensesArray.map(
                        (
                          license: {
                            quantity_delivered: any;
                            amount_delivered: any;
                            quantity: any;
                            amount: any;
                            lnumber: any;
                            pnumber: any;
                          },
                          index: React.Key | null | undefined
                        ) => {
                          return (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                              key={index}
                            >
                              <span>
                                {i18n.t("pages.license.imput.title-tab")} :{" "}
                                <b>{license.lnumber}</b>
                              </span>
                              <span>
                                {i18n.t("pages.license.add.part-number")} :{" "}
                                <b>{license.pnumber}</b>
                              </span>
                              <span>
                                {i18n.t("pages.license.add.quantity-delivered")}{" "}
                                :{" "}
                                <b>
                                  {formatNumberWithSpaces(
                                    license.quantity_delivered +
                                      license.quantity
                                  )}
                                </b>
                              </span>
                              <span>
                                {i18n.t("pages.license.add.amount-delivered")} :{" "}
                                <b>
                                  {formatNumberWithSpaces(
                                    license.amount_delivered + license.amount
                                  )}{" "}
                                </b>
                              </span>
                            </Stack>
                          );
                        }
                      )}
                    </div>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        onClick={() => {
                          handleClose();
                          imput();
                          setImputated(true);
                        }}
                        primary
                      >
                        Confirmer
                      </Button>
                      <Button onClick={handleClose}>Annuler</Button>
                    </Stack>
                  </Stack>
                </Box>
              </Modal>
            </GridRow>
          )}
          {/* COCHES */}
          <label>{i18n.t("global.accord_titre")}</label>
          <GridRow justify="space-between">
            <div className={styles.result}>
              <br />
              <FormGroup>
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch0")}
                  checked={Chk0}
                  onChange={(e) => {
                    setChk0(e.target.checked);
                  }}
                />
                <br />

                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch1")}
                  checked={Chk1}
                  onChange={(e) => {
                    setChk1(e.target.checked);
                  }}
                />
                <br />
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch2")}
                  checked={Chk2}
                  onChange={(e) => {
                    setChk2(e.target.checked);
                  }}
                />
                <br />
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch3")}
                  checked={Chk3}
                  onChange={(e) => {
                    setChk3(e.target.checked);
                  }}
                />

                <br />
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch4")}
                  checked={Chk4}
                  onChange={(e) => {
                    setChk4(e.target.checked);
                  }}
                />
                <br />
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch5")}
                  checked={Chk5}
                  onChange={(e) => {
                    setChk5(e.target.checked);
                  }}
                />
                <br />
                <Checkbox
                  className={styles.checkbox}
                  large={true}
                  label={i18n.t("global.accord_ch6")}
                  checked={Chk6}
                  onChange={(e) => {
                    setChk6(e.target.checked);
                  }}
                />
              </FormGroup>
            </div>
          </GridRow>

          <label>
            <b>{Accord}</b>
          </label>
        </div>
      </div>
    );
  };
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="EXPORTABILITY" />

      {renderResults()}
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: state.vertical as any,
          horizontal: state.horizontal as any,
        }}
        open={snackOpen}
        onClose={handleCloseSnack}
        message=""
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={statusSnack === 1 ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageNotification}
        </Alert>
      </Snackbar>
      <Footer
        buttons={[
          ...(rootStore.userStore.user?.role !== "L_A"
            ? [
                {
                  id: "previous",
                  onClick: () => goToLicenseMatching(),
                  label: i18n.t("global.previous"),
                },
              ]
            : []),
          getScreeningButton(),
          {
            id: "licensing",
            label: "Autoriser",
            onClick: () => handleSubmit(),
            disabled: !(Chk0 && Chk1 && Chk2 && Chk3 && Chk4 && Chk5 && Chk6),
            primary: true,
          },
        ]}
      >
        <TimeLine step={4} rootStore={rootStore} />
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(Shipping));

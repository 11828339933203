import React, { Component, ReactEventHandler, SyntheticEvent } from "react";
import cx from "classnames";
import Modal from "@mui/material/Modal";
import Fade from "../Fade";
import DialogHeader from "../DialogHeader";
import DialogTitle from "../DialogTitle";
import { Dialog as DialogF, DialogActions, DialogContent } from "@mui/material";

import classes from "./styles.module.scss";

type Props = {
  /** If `true`, the Dialog is open. */
  open: boolean;
  /** An optional title for the Dialog.
   * If provided instead of a DialogTitle children,
   * a close cross button will be shown. */
  title?: string;
  /** Callback fired when the component wants to be closed. */
  onClose?: ReactEventHandler<{}>;
  /** Callback fired when the backdrop is clicked. */
  onBackdropClick?: ReactEventHandler<{}>;
  /** If `true`, the backdrop won't be shown. */
  disableBackdrop?: boolean;
  /** Determines the container for scrolling the dialog. */
  scroll?: "body" | "dialog";
  /** If true, the dialog will be full-screen */
  fullScreen?: boolean;
  /** Not much to say ;) */
  className?: string;
  children?: React.ReactNode;
};

const styles: { [name: string]: { [name: string]: React.CSSProperties } } = {
  wrapper: {
    base: {
      height: "100%",
      outline: "none",
    },
    scrollDialog: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    scrollBody: {
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  dialog: {
    base: {
      display: "flex",
      flexDirection: "column",
      margin: 48,
      position: "relative",
      overflowY: "auto",
      opacity: 1,
      visibility: "visible",
      pointerEvents: "auto",
    },
    scrollDialog: {
      flex: "0 1 auto",
      maxHeight: "calc(100% - 96px)",
    },
    scrollBody: {
      margin: "48px auto",
    },
    fullScreen: {
      margin: "0",
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "100%",
    },
  },
};

export default class Dialog extends Component<Props> {
  handleBackdropClick = (event: SyntheticEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (this.props.onBackdropClick) {
      this.props.onBackdropClick(event);
    }
    if (!this.props.disableBackdrop && this.props.onClose) {
      this.props.onClose(event);
    }
  };

  render() {
    const {
      open,
      title,
      onClose,
      onBackdropClick,
      children,
      fullScreen = false,
      disableBackdrop = false,
      scroll = "dialog",
      className,
    } = this.props;
    return (
      <DialogF
        open={open}
        onClose={onClose}
        onBackdropClick={onBackdropClick}
        hideBackdrop={disableBackdrop}
        role="dialog"
        className={cx({
          [classes["ds-modal--body-scroll"]]: scroll === "body",
          [classes["ds-modal--fullscreen"]]: fullScreen,
        })}
      >
        <Fade in={open}>
          <div
            role="document"
            onClick={this.handleBackdropClick}
            style={{
              ...styles.wrapper.base,
              ...(scroll === "dialog"
                ? styles.wrapper.scrollDialog
                : styles.wrapper.scrollBody),
            }}
          >
            <div
              className={cx(className, classes["ds-modal"])}
              style={{
                ...styles.dialog.base,
                ...(scroll === "dialog"
                  ? styles.dialog.scrollDialog
                  : styles.dialog.scrollBody),
                ...(fullScreen ? styles.dialog.fullScreen : {}),
              }}
            >
              {title && (
                <DialogHeader onClose={onClose}>
                  <DialogTitle>{title}</DialogTitle>
                </DialogHeader>
              )}
              {children}
            </div>
          </div>
        </Fade>
      </DialogF>
    );
  }
}

import { Stack, Skeleton, Paper, Box, Divider } from "@mui/material";
import React from "react";

const SkeletonUI = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        padding: 5,
        borderRadius: 1,
      }}
    >
      <Stack
        sx={{ marginBottom: 2, width: "100%" }}
        spacing={2}
        direction="row"
      >
        <Box sx={{ width: "30%" }}>
          <Skeleton variant="text" width="100%" height={40} animation="wave" />
        </Box>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} sx={{ flex: 3 }}>
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
        </Stack>
        <Stack direction="column" spacing={2} sx={{ flex: 3 }}>
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="text" width="50%" height={56} animation="wave" />
            <Skeleton variant="text" width="50%" height={56} animation="wave" />
          </Stack>
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack direction="column" spacing={2} sx={{ width: "30%" }}>
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Skeleton variant="text" width="100%" height={56} animation="wave" />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="text" width="50%" height={56} animation="wave" />
            <Skeleton variant="text" width="50%" height={56} animation="wave" />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SkeletonUI;

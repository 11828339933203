import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  TextInput,
  Button,
  Loading,
} from "../../components/horizon-components-react/src/components";
import { observer, inject } from "mobx-react";
import InputAdornment from "@mui/material/InputAdornment";
import i18n from "i18next";
import styles from "./activate.module.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import UserStore from "../../stores/userStore";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

const ActivateUser: React.FC<Props> = ({ rootStore }) => {
  const [userId, setUserId] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tokenHere, setTokenHere] = useState("");
  const [isValidToken, setIsValidToken] = useState(false);
  const [badPassword, setBadPassword] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { token, id } = params;
const encodedToken = encodeURIComponent(token!);
  useEffect(() => {

    
    if (encodedToken && id) {
      const numericId = parseInt(id, 10);

      if (!isNaN(numericId)) {
        rootStore.userStore
          .checkActiveUser(numericId, encodedToken)
          .then((response) => {
            setTokenHere(encodedToken);
            setUserId(numericId);
            setIsValidToken(rootStore.userStore.isValidUser);
          });
      }
    }
  }, [rootStore.userStore, id, token]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password && confirmPassword && password === confirmPassword) {
      await rootStore.userStore.confirmUser(userId, password, confirmPassword);
      setBadPassword(false);
      navigate("/home");
    } else {
      setBadPassword(true);
    }
  };

  if (rootStore.authStore.isLogged) {
    navigate("/home");
    return null;
  }

  if (!isValidToken && token !== "") {
    return (
      <div className={styles.container}>
        <Card className={styles.cardLogin}>
          <CardHeader title={i18n.t("pages.activate.title")} />
          <div className={styles.error}>
            <p>{i18n.t("errors.invalid-expired-token")}</p>
          </div>
          <Link to="">{i18n.t("global.home")}</Link>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Card className={styles.cardLogin}>
        <CardHeader title={i18n.t("pages.activate.title")} />
        {badPassword && (
          <div className={styles.error}>
            <p>{i18n.t("errors.wrong-password")}</p>
          </div>
        )}
        <CardContent>
          <form id="activation-form" onSubmit={onSubmit}>
            <FormGroup>
              <TextInput
                id="password"
                type="password"
                label={i18n.t("pages.activate.password")}
                value={password}
                required={true}
                errorText={i18n.t("errors.password-not-defined")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <TextInput
                id="confirmPassword"
                type="password"
                label={i18n.t("pages.activate.confirm-password")}
                value={confirmPassword}
                required={true}
                errorText={i18n.t("errors.confirmPassword-not-defined")}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>

            {rootStore.authStore.loading ? (
              <Loading />
            ) : (
              <Button id="button" type="submit" primary>
                {i18n.t("pages.activate.submit")}
              </Button>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(ActivateUser));

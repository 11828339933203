/* Global import */
import { observable, action, computed } from "mobx";

/* Custom import */
import Api from "../utils/api";

export default class ModelStore {
  api: Api;
  rootStore: import("./rootStore").default;
  @observable models: string[] = [];

  constructor(rootStore: import("./rootStore").default) {
    this.api = new Api();
    this.rootStore = rootStore;
  }

  @computed get modelToOptions() {
    return this.models.map((model, index) => {
      return {
        label: model,
        value: index,
      };
    });
  }

  @action async getModels() {
    try {
      this.models = await this.api.getModelsWithoutProduct();
    } catch (e) {}
  }
}

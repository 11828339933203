import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

interface CountrySelectProps {
  countries: CountryType[];
  size: "small" | "medium";
  label: string;
  selected?: string | number; // Nouvelle propriété pour l'ID du pays sélectionné
  onChangeInherited?: (selectedCountry: CountryType | null) => void; // Nouvelle prop pour gérer le changement de sélection
  width: string;
  error?: boolean;
  helperText?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
}

export default function CountrySelect(props: CountrySelectProps) {
  const {
    countries,
    size,
    label,
    selected,
    onChangeInherited,
    width,
    error,
    helperText,
    id,
    required,
    disabled,
  } = props;
  const [cleared, setCleared] = useState(false);

  return (
    <Autocomplete
      id={id ? id : "country-select-demo"}
      sx={{ width: width }}
      size={size}
      options={countries}
      autoHighlight
      onChange={(event, value) => {
        setCleared(true); // Réinitialiser cleared à false lorsque la sélection change
        if (onChangeInherited) {
          onChangeInherited(value); // Appeler la fonction de rappel onChange avec la valeur sélectionnée
        }
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.value})
        </Box>
      )}
      renderInput={(params) => {
        const selectedCountryByValue = countries.find(
          (country) => country.label === params.inputProps.value
        ); // Recherche du pays correspondant au libellé entré

        let selectedCountryById;
        if (!cleared) {
          selectedCountryById = countries.find(
            (country) => country.value === selected
          ); // Recherche du pays correspondant à l'ID sélectionné
        }

        // Sélectionne le pays en fonction de l'ID s'il est spécifié, sinon utilise la sélection par libellé
        const selectedCountry = selectedCountryByValue || selectedCountryById;
        return (
          <TextField
            {...params}
            size={size}
            required={required || false}
            disabled={disabled || false}
            label={label}
            variant="standard"
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCountry ? (
                <>
                  <img
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${selectedCountry.value.toLowerCase()}.png`}
                    alt=""
                  />
                  {!params.inputProps.value ? selectedCountry.label : ""}
                </>
              ) : null,
            }}
          />
        );
      }}
    />
  );
}

interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}

/* Styles import */
import styles from "./subHeader.module.css";

/* Global import */
import React, { Component } from "react";
  
interface Props {children: any}
interface State {}

export default class SubHeader extends Component<Props, State> {
  render() {
    return <div className={styles.header}>{this.props.children}</div>;
  }
}

/* Styles import */
import styles from "../admin.module.css";

/* Global import */
import React, { Component, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SearchInput,
  Loading,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import EditIcon from "@mui/icons-material/Create";
import { useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Api from "../../../utils/api";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ListSanction from "../../../components/listSanction";
import AutoComplete from "../../../components/autoComplete";
import SearchIcon from "@mui/icons-material/Add";

interface State {
  search: string;
  selectedCountry?: Country;
  dcountry: string;
  acountry: string;
}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Sanction: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const [state, setState] = useState({
    search: "",
    dcountry: "",
    acountry: "",
  });

  useEffect(() => {
    rootStore.countriesStore.getCountries();
    rootStore.countriesStore.getCountriesDeparture();
  }, []);

  const submit = async () => {
    const log = await api.AddSanction(state.dcountry, state.acountry);

    window.location.reload();
  };

  const { search } = state;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="SANCTION" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={"Specific sanctions"} />

        <div className={styles.headerCard}>
          <br />
          <AutoComplete
            id="departure-country"
            label={"Departure"}
            required={true}
            options={rootStore.countriesStore.countriesToOptions}
            errorText={i18n.t("errors.departure-country-not-defined")}
            value={state.dcountry}
            onChange={(value) => setState({ ...state, dcountry: value })}
          />
          <AutoComplete
            id="country-of-destination"
            label={"Destination"}
            required={true}
            options={rootStore.countriesStore.countriesToOptions}
            errorText={i18n.t("errors.country-of-destination-not-defined")}
            value={state.acountry}
            onChange={(value) => setState({ ...state, acountry: value })}
          />

          <IconButton
            id="search-national"
            className={styles.searchButton}
            onClick={() => {
              submit();
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>

        <CardContent>
          <ListSanction />

          {rootStore.countriesStore.loadingCountries && <Loading />}
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Sanction));

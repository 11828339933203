/* Styles import */
import styles from "./listCountry.module.css";

/* Global import */
import React from "react";

interface Props {
  list: any;
  country: string;
}

const ListCountry = ({ list, country }: Props) => {
  return (
    <li className={styles.element} key={country}>
      {" "}
      {country} :
      <ul className={styles.list}>
        {list.map((list: any) => {
          return (
            <li key={list.id}>
              <a target="_blank" rel="noopener noreferrer" href={list.url}>
                {list.titre}
              </a>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default ListCountry;

import axios from "axios";
import countriesI18 from "i18n-iso-countries";
import moment from "moment";
import configuration from "../../../configuration";
import RootStore from "../../../stores/rootStore";
countriesI18.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesI18.registerLocale(require("i18n-iso-countries/langs/fr.json"));
interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
  file: File;
  id: number;
  equipments: any;
}
const cleanString = (str: string) => str.replace(/[\t\n\r]+/g, " ").trim();

export const mapPdfDataToCurrentLicense = (data: any): LicenseObject => {
  return {
    first_recipient: cleanString(data.firstRecipient || ""),
    mention_cuf: cleanString(data.mentionCuf || ""),
    cnr: data.cnr || 0,
    comment: cleanString(data.comment || ""),
    company_name: cleanString(data.companyName || ""),
    end_user: cleanString(data.companyName || ""),
    created_at: data.createdAt || "",
    cuf: data.cuf || 0,
    currency: cleanString(data.currency || ""),
    customer_destination_country_id: data.customerDestinationCountryId || 0,
    deleted_at: data.deletedAt || null,
    departure_country_id: cleanString(data.departureCountryId || ""),
    destinataire: cleanString(data.destinataire || ""),
    destination_country_id: data.destinationCountryId || 0,
    end_use: "CIVIL", //merci la bonne database et le bon java du passé on apprécie vraiment ce genre de dinguerie
    end_use_comment: cleanString(data.endUseComment || ""),
    entite: cleanString(data.entite || ""),
    equipments: data.equipments.map((equipment: any) => ({
      active_line: equipment.activeLine,
      model: cleanString(equipment.model || ""),
      eccn_code: cleanString(equipment.eccnCode || ""),
      keyword: cleanString(equipment.keyword || ""),
      amount_initial: equipment.amountInitial || 0,
      amount_actual: equipment.amountActual || 0,
      quantity: equipment.quantity || 0,
      quantity_initial: equipment.quantityInitial || 0,
      quantity_actual: equipment.quantityActual || 0,
      unit_price: equipment.unitPrice || 0,
    })),
    expiration_date: data.expirationDate || "",
    first_destination_country_id: data.firstDestinationCountryId || 0,
    intermediair_destination_country_id:
      data.intermediairDestinationCountryId || 0,
    issuance_date: data.issuanceDate || "",
    license_number: cleanString(data.licenseNumber || ""),
    license_type: cleanString(data.licenseType || ""),
    manufacturer: cleanString(data.manufacturer || ""),
    media_id: data.mediaId || null,
    media_id1: data.mediaId1 || null,
    media_id2: data.mediaId2 || null,
    media_id3: data.mediaId3 || null,
    media_id4: data.mediaId4 || null,
    media_id5: data.mediaId5 || null,
    model: data.model || null,
    num_order: cleanString(data.numOrder || ""),
    provisos: cleanString(data.provisos || ""),
    recipient: cleanString(data.firstRecipient || ""),
    regulation: cleanString(data.regulation || ""),
    status: cleanString("ACTIVE"), //merci la bonne database et le bon java du passé on apprécie vraiment ce genre de dinguerie
    supplier: cleanString(data.supplier || ""),
    temporary_license: data.temporaryLicense || 0,
    // tenant_id: rootStore.userStore.user?.tenantId as string;
    total_amount: data.totalAmount || 0,
    updated_at: data.updatedAt || "",
  };
};

export const handleNlPDF = (
  data: any,
  rootStore: RootStore,
  equipments: any
) => {
  let newState = {
    expirationDate: "",
    licenseNumber: "",
    equipments: "",
    currency: "",
    endUseComment: "",
    companyName: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    regulation: "",
    licenseType: "",
    destinationCountryId: 0,
  };
  newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
  newState.licenseNumber = data[1];
  //newState.codedouanier = data[3];
  newState.equipments = data[4];
  newState.currency = "EUR";
  newState.endUseComment = data[7];
  newState.companyName = data[7];
  newState.destinataire = data[9];
  newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
  newState.departureCountryId = data[10];
  newState.regulation = data[11];
  newState.licenseType = data[16];

  if (data[11].includes("TWEEËRLEI GEBRUIK")) {
    newState.regulation = "EU_DUAL_USE_LIST";
  }

  const countryCode: CountryCodeDest = data[12] as CountryCodeDest;
  const specialCountryNames: Record<CountryCodeDest, string> = {
    CN: "China",
    UA: "Ukraine",
    KR: "Korea, South",
    US: "United States of America (USA)",
  };

  let countryName = specialCountryNames.hasOwnProperty(countryCode)
    ? specialCountryNames[countryCode] || null
    : countriesI18.getName(countryCode, "en");
  countryName = countryName || newState.destinataire;
  const country = rootStore.countriesStore.countries.find(
    (country) => country.name === countryName
  );

  if (country) {
    newState.destinationCountryId = country.id;
  }

  let items = data[14];
  let newEquipments = equipments;
  items.forEach((item: any, i: number) => {
    let equipment_add = {
      model: item[2].replace("\t\nF.S.\n0\t\n", "") ?? null,
      eccnCode: item[4] ?? null,
      keyword: item[3] ?? null,
      amountInitial: parseFloat(item[5]) ?? null,
      amountActual: parseFloat(item[5]) ?? null,
      quantity: parseFloat(item[6]) ?? null,
      quantityActual: parseFloat(item[6]) ?? null,
    };

    if (i < newEquipments.length) {
      newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
    } else {
      newEquipments.push(equipment_add);
    }
  });
  newState = { ...newState, equipments: newEquipments ? newEquipments : [] };
  return mapPdfDataToCurrentLicense(newState);
};

export const handleFrenchPDF = (
  data: any,
  rootStore: RootStore,
  equipments: any
) => {
  let newState = {
    expirationDate: "",
    licenseNumber: "",
    equipments: "",
    currency: "",
    endUseComment: "",
    companyName: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    regulation: "",
    licenseType: "",
    destinationCountryId: 0,
  };
  newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
  newState.licenseNumber = data[1];
  newState.currency = "EUR";
  newState.endUseComment = data[7];
  newState.companyName = data[7];
  newState.destinataire = data[9];
  newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
  newState.departureCountryId = data[10];
  newState.regulation = data[11];
  newState.destinationCountryId = data[12] || 0;

  const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

  /** Special Code */
  const specialCountryNames: Record<CountryCodeDest, string> = {
    CN: "China",
    UA: "Ukraine",
    KR: "Korea, South",
    US: "United States of America (USA)",
  };

  let countryName = countriesI18.getName(countryCode, "en");
  if (specialCountryNames.hasOwnProperty(countryCode)) {
    countryName = specialCountryNames[countryCode];
  }
  if (countryName && countryCode == ("TW" as CountryCodeDest)) {
    countryName = countryName
      ?.toLowerCase()
      .replace(", province of china", "")
      .trim();
    countryName = countryName.charAt(0).toUpperCase() + countryName.slice(1);
  }
  const country = rootStore.countriesStore.countries.find(
    (c) => c.name === countryName
  );

  if (country) {
    newState.destinationCountryId = country.id;
  }

  newState.companyName = data[13];
  newState.licenseType = data[16];

  let items = data[14];
  let newEquipments = equipments;
  items.forEach((item: any, i: number) => {
    let equipment_add = {
      model: item[2].replace("\t\nF.S.\n0\t\n", ""),
      eccnCode: item[4],
      keyword: item[3],
      amountInitial: parseFloat(item[5]),
      amountActual: parseFloat(item[5]),
      quantity: parseFloat(item[6]),
      quantityActual: parseFloat(item[6]),
    };

    if (i < newEquipments.length) {
      newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
    } else {
      newEquipments.push(equipment_add);
    }
  });
  newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

  return mapPdfDataToCurrentLicense(newState);
};

export const handleFR_DU_GLOB_PDF = (
  data: any,
  rootStore: RootStore,
  equipments: any
) => {
  let newState = {
    expirationDate: "",
    licenseNumber: "",
    equipments: "",
    currency: "",
    endUseComment: "",
    companyName: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    regulation: "",
    licenseType: "",
    destinationCountryId: 0,
  };

  newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
  newState.licenseNumber = data[1];
  newState.currency = "EUR";
  newState.endUseComment = data[7];
  newState.companyName = data[7];
  newState.destinataire = data[9];
  newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
  newState.departureCountryId = data[10];
  newState.regulation = data[11];

  const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

  /** Special Code */
  const specialCountryNames: Record<CountryCodeDest, string> = {
    CN: "China",
    UA: "Ukraine",
    KR: "Korea, South",
    US: "United States of America (USA)",
  };

  let countryName = countriesI18.getName(countryCode, "en");
  if (specialCountryNames.hasOwnProperty(countryCode)) {
    countryName = specialCountryNames[countryCode];
  }

  const country = rootStore.countriesStore.countries.find(
    (c) => c.name === countryName
  );

  if (country) {
    newState.destinationCountryId = country.id;
  }

  newState.companyName = data[13];
  newState.licenseType = "GLOBAL";

  let items = data[14];
  let newEquipments = equipments;
  items.forEach((item: any, i: number) => {
    let equipment_add = {
      model: item[2].replace("\t\nF.S.\n0\t\n", ""),
      eccnCode: item[4],
      keyword: item[3],
      amountInitial: parseFloat(item[5]),
      amountActual: parseFloat(item[5]),
      quantity: parseFloat(item[6]),
      quantityActual: parseFloat(item[6]),
    };

    if (i < newEquipments.length) {
      newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
    } else {
      newEquipments.push(equipment_add); // Ajout d'un nouvel élément
    }
  });
  newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

  return mapPdfDataToCurrentLicense(newState);
};

export const handleFR_EU001 = (
  data: any,
  rootStore: RootStore,
  equipments: any
) => {
  let newState = {
    expirationDate: "",
    licenseNumber: "",
    equipments: "",
    currency: "",
    endUseComment: "",
    companyName: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    regulation: "",
    licenseType: "",
    destinationCountryId: 0,
  };

  newState.expirationDate = moment(data[0]).format("YYYY-MM-DD");
  newState.licenseNumber = data[1];
  newState.currency = "EUR";
  newState.endUseComment = data[7];
  newState.companyName = data[7];
  newState.destinataire = data[9];
  newState.issuanceDate = moment(data[8]).format("YYYY-MM-DD");
  newState.departureCountryId = data[10];
  newState.regulation = data[11];

  const countryCode: CountryCodeDest = data[12] as CountryCodeDest;

  /** Special Code */
  const specialCountryNames: Record<CountryCodeDest, string> = {
    CN: "China",
    UA: "Ukraine",
    KR: "Korea, South",
    US: "United States of America (USA)",
  };

  let countryName = countriesI18.getName(countryCode, "en");
  if (specialCountryNames.hasOwnProperty(countryCode)) {
    countryName = specialCountryNames[countryCode];
  }

  // Je note que vous définissez une date d'expiration et un identifiant de pays de destination statiques ici.
  newState.expirationDate = moment("12/31/2030").format("YYYY-MM-DD");
  newState.destinationCountryId = 999; // Assurez-vous que cette valeur est correcte pour votre cas d'utilisation.
  newState.companyName = data[13];
  newState.licenseType = "EU001_AUTOR_GENERAL";

  let items = data[14];
  // Assurez-vous d'ajouter la logique pour gérer les éléments 'items'
  let newEquipments = equipments;
  items.forEach((item: any, i: number) => {
    let equipment_add = {
      model: item[2].replace("\t\nF.S.\n0\t\n", ""),
      eccnCode: item[4],
      keyword: item[3],
      amountInitial: parseFloat(item[5]),
      amountActual: parseFloat(item[5]),
      quantity: parseFloat(item[6]),
      quantityActual: parseFloat(item[6]),
    };

    if (i < newEquipments.length) {
      newEquipments[i] = equipment_add; // Mise à jour de l'élément existant
    } else {
      newEquipments.push(equipment_add); // Ajout d'un nouvel élément
    }
  });
  newState = { ...newState, equipments: newEquipments ? newEquipments : [] };

  return mapPdfDataToCurrentLicense(newState);
};

export const convertDateFormat = async (inputDate: any) => {
  let [day, month, year] = inputDate.split("-");
  let formattedDate = `${month}/${day}/${year}`;

  let date = new Date(formattedDate);

  let yearStr = date.getFullYear();
  let monthStr = (date.getMonth() + 1).toString().padStart(2, "0");
  let dayStr = date.getDate().toString().padStart(2, "0");

  return `${yearStr}-${monthStr}-${dayStr}`;
};

export const handleFR_MLPDF = async (
  data: any,
  rootStore: RootStore,
  id: number
) => {
  let newState = {
    expirationDate: "",
    licenseNumber: "",
    equipments: "",
    currency: "",
    endUseComment: "",
    companyName: "",
    destinataire: "",
    issuanceDate: "",
    departureCountryId: "",
    regulation: "",
    licenseType: "",
    destinationCountryId: 0,
    totalAmount: "",
    firstRecipient: "",
    messageCnr1: "",
    messageCnr2: "",
  };

  const formattedExpirationDate = await convertDateFormat(data[0]);
  const formattedIssuanceDate = await convertDateFormat(data[8]);

  newState.expirationDate = formattedExpirationDate;
  newState.licenseNumber = data[1];
  newState.currency = "EUR";
  newState.endUseComment = data[7];
  newState.companyName = data[7];
  newState.destinataire = data[9];
  newState.issuanceDate = formattedIssuanceDate;
  newState.regulation = data[11];
  newState.departureCountryId = data[10];
  newState.companyName = data[13];
  newState.licenseType = data[16];
  newState.totalAmount = data[21];
  newState.firstRecipient = data[22];
  newState.messageCnr1 = data[23];
  newState.messageCnr2 = data[24];
  const equipments = data[25].map(
    (
      group: {
        description: any;
        qty: any;
        cat: any;
        unit_price: string;
        total_price: string;
      },
      index: number
    ) => ({
      id: index + 1,
      model: group.description,
      eccnCode: group.cat,
      // partNumber: group.cat,
      // activeLine: newState.regulation === "FR_MUNITIONS_LIST" && !id,
      activeLine: 1,
      quantity: group.qty,
      quantityInitial: group.qty,
      amountInitial: parseFloat(group.total_price.replace(",", ".")),
      unitPrice: parseFloat(group.unit_price.replace(",", ".")),
    })
  );
  newState.equipments = equipments;
  /** Special Code */
  const specialCountryNames: Record<CountryCodeDest, string> = {
    CN: "China",
    UA: "Ukraine",
    KR: "Korea, South",
    US: "United States of America (USA)",
  };
  let countryNameInFrench = data[12];
  let countryCode = countriesI18.getAlpha2Code(countryNameInFrench, "fr");
  if (countryCode) {
    let countryNameInEnglish = countriesI18.getName(countryCode, "en");
    if (countryNameInEnglish === "United Kingdom") {
      countryNameInEnglish = "United Kingdom (UK)";
    }
    const country = rootStore.countriesStore.countries.find(
      (c) => c.name === countryNameInEnglish
    );

    if (country) {
      newState.destinationCountryId = country.id;
    }
    if (data[16] === "GLOBAL") {
      newState.destinationCountryId = 999;
    }
  }
  return mapPdfDataToCurrentLicense(newState);
};

export const pdfSubmit = async ({
  file,
  rootStore,
  id,
  equipments,
}: InjectedProps): Promise<any> => {
  type PdfSubmitProps = {
    file: File;
    rootStore: any;
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = new FormData();
      if (file) {
        data.append("file", file);
      } else {
        const emptyFile = new Blob([""], { type: "text/plain" });
        data.append("file", emptyFile, "empty_file.txt");
      }

      const response = await axios({
        method: "POST",
        url: `${configuration.LARAVEL_API}/pdfsubmit`,
        headers: {
          "content-type": "multipart/form-data",
        },
        data,
      });

      const responseData = response.data;

      resolve(responseData);

      const emptyCount = responseData
        .slice(0, 12)
        .filter((item: string) => item === "").length;
      if (emptyCount >= 8) {
        alert("modal pdf pas bon");
      }
    } catch (error) {
      reject(error);
    }
  });
};

/* Styles import */
import styles from "./chooseEntity.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import EntityComponent from "../../../../components/entity";
import Loader from "../../../../components/loader";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import entity from "..";

interface State {
  loading: boolean;
  entity?: Entity;
  flowId?: string;
}

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}
const ChooseEntity: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const [state, setState] = useState({
    flowId: "",
    loading: false,
    entity: { bvdId: "", name: "" },
  });

  const navigate = useNavigate();
  const navigateToEntity = (url: string) => {
    navigate(`${url}/${id}`);
  };

  useEffect(() => {
    if (id) {
      setState({ ...state, flowId: id });
    }
  }, [id]);

  const handleSubmit = async () => {
    const entity = state.entity;
    const flowId = id;

    setState({ ...state, loading: true });

    if (entity) {
      await rootStore.screeningStore.screeningEntity(
        entity.bvdId,
        entity.name,
        parseInt(flowId || "0")
      );

      setState({ ...state, loading: false });
      navigate(flowUrl("/screening/entity/result", flowId));
    }
  };

  const getCompany = () => {
    const company = rootStore.screeningStore.company;

    return company ? company : "";
  };

  const getCountry = () => {
    const countryId = rootStore.screeningStore.country;

    if (countryId) {
      const country = rootStore.countriesStore.getCountryById(countryId);

      return country ? country.name : "";
    }
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />
      {state.loading && <Loader />}

      <div className={styles.body}>
        <GridRow justify="space-between">
          <GridCol>
            <Title tag="h2">
              {i18n.t("pages.screening.entity.choose-entity.title")}
            </Title>
          </GridCol>
          <GridCol>
            <div className={styles.aligner}>
              {rootStore.screeningStore.entities.length > 0 && (
                <p className={styles.message}>
                  {`${rootStore.screeningStore.entities.length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${getCompany()}"
                    ${i18n.t("pages.screening.entity.choose-entity.in")}
                    "${getCountry()}"
                    `}
                </p>
              )}
            </div>
          </GridCol>
        </GridRow>

        <div className={styles.entities}>
          {rootStore.screeningStore.entities.map((entity, index) => {
            return (
              <EntityComponent
                rootStore={rootStore}
                key={index}
                entity={entity}
                onClick={() => setState({ ...state, entity })}
                selected={entity && entity.bvdId === entity.bvdId}
              />
            );
          })}

          {rootStore.screeningStore.entities.length === 0 && (
            <p className={styles.message}>
              {i18n.t("pages.screening.entity.choose-entity.no-result")}
            </p>
          )}
        </div>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateToEntity(flowUrl("/screening/entity")),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("components.searchEccn.search-button"),
            primary: true,
            disabled: state.entity === undefined,
          },
        ]}
      >
        {state.flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ChooseEntity));

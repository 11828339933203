/* Styles import */
import styles from "./result.module.css";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
/* Global import */
import React, { Component, useState } from "react";
import {
  GridCol,
  GridRow,
  TextArea,
  Title,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../utils/api";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import { flowUrl } from "../../../utils/generator";
import HeaderScreeningComponent from "../../../components/headerScreening";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

interface State {
  flowId?: string;
  post?: BasicEntityFinale_canada[];
  answers: any;
  showAdditionalInfoTextField: boolean;
  additionalInfoText: string;
}
interface DueDiligenceQuestions {
  [key: string]: string;
}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const DueScreening: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();

  const { entityId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    answers: [] as any,
    showAdditionalInfoTextField: false,
    additionalInfoText: "",
  });

  const getButtons = () => {
    const buttons = [
      {
        id: "previous",
        onClick: () => {
          navigate("/screening/basic/All");
        },
        label: i18n.t("global.previous"),
        primary: false,
        disabled: false,
      },
      {
        id: "PDF",
        onClick: () => handleDownloadImage(),
        label: i18n.t("global.pdf"),
      },
    ];

    return buttons;
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("questionnaire-content");
    if (element) {
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/jpg");
      const maDate = new Date();
      const auteur =
        rootStore.userStore.user?.firstname +
        " " +
        rootStore.userStore.user?.lastname;
      //let entityId = entityId ? entityId : "";
      const currentLocale = i18n.language;

      const societe = rootStore.userStore.user?.tenantId;
      var afDate = moment(maDate).format("YYYY-MM-DD");

      var doc = new jsPDF("p", "mm");
      let country = window.navigator.language;
      let datrap = moment().locale("en").format("ll");

      if (currentLocale === "fr" || currentLocale === "fr-FR") {
        datrap = moment().locale("fr").format("D MMM YYYY");
      }
      doc.setFontSize(14);
      doc.text(
        "Due diligence " +
          (entityId
            ? `${i18n.t("pages.screening.due.from_entity")} ${entityId}`
            : ""),
        doc.internal.pageSize.getWidth() / 2 - 20,
        10,
      );

      doc.setFontSize(10);
      doc.text(i18n.t("pages.screening.due.created_at") + " " + datrap, 10, 20);
      doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
      doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);
      let dcountry = "";
      let acountry = "";
      /*
    if (result) {
      dcountry = result.departureCountry.name
      acountry = result.countryOfDestination.name
    } 
    */
      let height = doc.internal.pageSize.getHeight();
      let width = doc.internal.pageSize.getWidth();
      let widthRatio = width / canvas.width;
      let heightRatio = height / canvas.height;
      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      doc.addImage(
        data,
        "JPEG",
        0,
        40,
        canvas.width * ratio,
        canvas.height * ratio,
      );

      let yPosition = canvas.height * ratio + 45;
      doc.setFontSize(8);
      let leftMargin = 21;

      if (state.additionalInfoText) {
        let lignes = state.additionalInfoText.split("\n");

        lignes.forEach((ligne) => {
          if (doc.internal.pageSize.getHeight() - yPosition < 10) {
            doc.addPage();
            yPosition = 20;
          }
          doc.text(ligne, leftMargin, yPosition);
          yPosition += 3;
        });
      }
      if (entityId != undefined) {
        doc.save(
          afDate +
            " Due diligence " +
            (entityId !== undefined
              ? `${i18n.t("pages.screening.due.from_entity")} ${entityId}`
              : "") +
            ".pdf",
        );
      } else {
        doc.save(afDate + " Due diligence" + ".pdf");
      }

      const link = document.createElement("a");
      // link.href = data;
      // link.download = 'downloaded-image.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleChange = (questionKey: string, value: string) => {
    setState((prevState) => ({
      ...prevState,
      answers: {
        ...prevState.answers,
        [questionKey]: value,
      },
      showAdditionalInfoTextField:
        questionKey === "qadditional_info" && value === "yes",
    }));
  };

  const handleAdditionalInfoChange = (value: string) => {
    setState({ ...state, additionalInfoText: value });
  };

  const renderQuestion = (questionKey: string, questionText: string) => {
    if (questionKey === "qadditional_info") {
      return (
        <div
          key={questionKey}
          style={{
            marginBottom: "6px",
            textAlign: "left",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <FormLabel
            style={{
              marginRight: "20px",
              flexBasis: "50%",
              wordBreak: "break-word",
            }}
          >
            {questionText}
          </FormLabel>
        </div>
      );
    } else {
      return (
        <div
          key={questionKey}
          style={{ marginBottom: "6px", textAlign: "left", width: "100%" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormLabel
              style={{
                marginRight: "20px",
                flexBasis: "50%",
                wordBreak: "break-word",
              }}
            >
              {questionText}
            </FormLabel>
            <RadioGroup
              aria-label={questionKey}
              name={questionKey}
              value={state.answers[questionKey] || ""}
              onChange={(e) => handleChange(questionKey, e.target.value)}
              row
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={i18n.t("pages.screening.due.yes")}
                style={{ marginLeft: "10px" }}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={i18n.t("pages.screening.due.no")}
                style={{ marginLeft: "10px" }}
              />
            </RadioGroup>
          </div>
        </div>
      );
    }
  };

  const dueDiligenceQuestions: DueDiligenceQuestions = {
    q1: i18n.t("pages.screening.due.q1"),
    q2: i18n.t("pages.screening.due.q2"),
    q3: i18n.t("pages.screening.due.q3"),
    q4: i18n.t("pages.screening.due.q4"),
    q5: i18n.t("pages.screening.due.q5"),
    q6: i18n.t("pages.screening.due.q6"),
    q7: i18n.t("pages.screening.due.q7"),
    q8: i18n.t("pages.screening.due.q8"),
    q9: i18n.t("pages.screening.due.q9"),
    q10: i18n.t("pages.screening.due.q10"),
    q11: i18n.t("pages.screening.due.q11"),
    q12: i18n.t("pages.screening.due.q12"),
    qadditional_info: i18n.t("pages.screening.due.qadditional_info"),
  };
 

  return (
    <div>
      <AppBar selectedPage={"SCREENING"} rootStore={rootStore} />
      <HeaderScreeningComponent
        type="BASIC"
        pages="DUEDILIGENCE"
        rootStore={rootStore}
      />

      <div className={styles.body}>
        <Title tag="h2">
          Due diligence{" "}
          {entityId
            ? `${i18n.t("pages.screening.due.from_entity")} ${entityId}`
            : ""}
        </Title>

        <Title tag="h5" className={styles.pStyles}>
          {i18n.t("pages.screening.due.text1")}
        </Title>

        <div id="questionnaire-content" style={{ marginTop: "12px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "80%",
              margin: "auto",
            }}
          >
            {Object.keys(dueDiligenceQuestions).map((key) => {
              const question =
                dueDiligenceQuestions[key as keyof DueDiligenceQuestions];
              if (key.startsWith("q")) {
                return renderQuestion(key, question);
              }
              return null;
            })}
          </div>
        </div>
        <div
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            margin: "auto",
            marginTop: 8,
          }}
        >
          <TextArea
            placeholder={i18n.t("global.holdtext")}
            rows={10}
            value={state.additionalInfoText}
            style={{ whiteSpace: "pre-wrap" }}
            onChange={(e, value) =>
              setState({ ...state, additionalInfoText: value })
            }
          />
        </div>
      </div>

      <Footer buttons={getButtons()} />
    </div>
  );
};

export default inject("rootStore")(observer(DueScreening));

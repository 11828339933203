// ErrorAlert.tsx
import React, { useState } from "react";

const ErrorAlert: React.FC<{
  message: string;
  code?: string;
  onClose?: () => void; // onClose est maintenant optionnel
}> = ({ message, code, onClose }) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose(); // Appelle la fonction de fermeture si elle est définie
  };

  if (!visible) return null; // Ne rend rien si l'alerte n'est pas visible

  return (
    <div
      style={{
        backgroundColor: "#f8d7da",
        color: "#721c24",
        border: "1px solid #f5c6cb",
        borderRadius: "4px",
        padding: "15px",
        margin: "20px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <strong>Erreur {code}:</strong> {message}
      </div>
      <span
        style={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={handleClose} // Appelle la fonction de fermeture interne
      >
        &#10005;
      </span>
    </div>
  );
};

export default ErrorAlert;

/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";

interface Props {
  pages: Pages;
}
type Pages = "CONTROL_LISTS" | "SANCTIONS" | "STATE-PORTALS"| "Wassenaar";

const HeaderRegulationEditComponent = ({ pages }: Props) => {
  return (
    <SubHeader>
      <Link
        id="control-lists"
        className={`${styles.link} ${
          pages === "CONTROL_LISTS" ? styles.selected : {}
        }`}
        to="/regulation/control-lists-edit"
      >
        {i18n.t("pages.regulation.control-lists.title")}
      </Link>

      <Link
        id="sanctions"
        className={`${styles.link} ${
          pages === "SANCTIONS" ? styles.selected : {}
        }`}
        to="/regulation/sanctions-edit"
      >
        {i18n.t("pages.regulation.sanctions.title")}
      </Link>

      <Link
        id="state-portals"
        className={`${styles.link} ${
          pages === "STATE-PORTALS" ? styles.selected : {}
        }`}
        to="/regulation/state-portals-edit"
      >
        {i18n.t("pages.regulation.state-portals.title")}
      </Link>
       <Link
        id="Wassenaar"
        className={`${styles.link} ${
          pages === "Wassenaar" ? styles.selected : {}
        }`}
        to="/regulation/wassenaar-edit"
      >
       Wassenaar
      </Link>
    </SubHeader>
  );
};

export default HeaderRegulationEditComponent;

import React, { FC, ReactNode } from "react";
import cx from "classnames";
import classes from "../Card/styles.module.scss";

type Props = {
  className?: string;
  children?: ReactNode;
};

const CardContent: FC<Props> = ({ className, children }) => (
  <div className={cx(className, classes["ds-card__content"])}>{children}</div>
);

export default CardContent;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Slide,
  Fade,
  makeStyles,
  Theme,
  Stack,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Modal from "@mui/material/Modal";
import i18n from "i18next";
import moment from "moment";
import RootStore from "../../../stores/rootStore";

interface Props {
  onDeleteAssociation: () => void;
  rootStore: RootStore;
  equipment: EquipmentFlow;
  license: MatchedEquipment;
  flow: any;
  type: string;
}

const AssociationCard: React.FC<Props> = ({
  onDeleteAssociation,
  rootStore,
  equipment,
  license,
  flow,
  type,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [theLicense, setLicense] = useState<any>([] as any);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    // Ajoutez votre appel API ici pour supprimer l'association
    await rootStore.licenseStore.deleteLink(
      flow.id, //flux
      equipment.id as number, //equipement du flux
      license.license_id, //licence
      license.id, //equipement de la licence
      type //Type
    );
    onDeleteAssociation();
    handleClose();
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const hasFieldError = (license: MatchedEquipment, field: string) => {
    const regulation = "FR_MUNITIONS_LIST";
    if (license) {
      if (!license.amount_delivered) {
        license.amount_delivered = "0";
      }
      if (!license.amount_initial) {
        license.amount_initial = "0";
      }
      if (!license.amount_ordered) {
        license.amount_ordered = "0";
      }
      const expirationDate = moment(license.expiration_date);
      const now = moment();
      // const diffInmonths = Math.abs(expirationDate.diff(now, "months"));

      switch (field) {
        case "date":
          if (expirationDate.isValid()) {
            const diffInmonths = Math.abs(expirationDate.diff(now, "months"));

            return (
              <Typography
                color={moment().isAfter(expirationDate) ? "red" : "green"}
              >
                {moment().isAfter(expirationDate)
                  ? i18n.t("pages.license.matching.expired")
                  : i18n.t("pages.license.matching.expiredMessage", {
                      code: diffInmonths,
                    })}
              </Typography>
            );
          } else {
            return (
              <Typography color="orange">Date d'expiration invalide</Typography>
            );
          }
        case "qty":
          return (
            <Typography
              color={` ${
                (license.regulation === "FR_MUNITIONS_LIST" &&
                  equipment.quantity >
                    license.quantity_initial - license.quantity_ordered) ||
                (license.regulation !== "FR_MUNITIONS_LIST" &&
                  equipment.quantity > license.quantity_actual)
                  ? "red"
                  : "black"
              }`}
            >
              {regulation === "FR_MUNITIONS_LIST" &&
              license.quantity_initial -
                license.quantity_ordered -
                license.quantity_delivered
                ? i18n.t("pages.license.imput.left_quantity2") +
                  " : " +
                  (
                    license.quantity_initial -
                    license.quantity_ordered -
                    license.quantity_delivered
                  ).toLocaleString()
                : i18n.t("pages.license.imput.left_quantity2") +
                    " : " +
                    license.quantity_actual
                  ? i18n.t("pages.license.imput.left_quantity2") +
                    " : " +
                    license.quantity_actual
                  : ""}
            </Typography>
          );
        case "price":
          return (
            <Typography
              color={` ${
                (license.unit_price &&
                  equipment.unit_price > license.unit_price) /*&&
                isErrorPresent*/ ||
                !license.unit_price
                  ? "red"
                  : "black"
              }`}
            >
              {license.unit_price
                ? i18n.t("pages.license.matching.unit_price") +
                  " : " +
                  license.unit_price
                : i18n.t("pages.license.matching.unit_price") + " : -"}
            </Typography>
          );
        case "amount":
          return (
            <Typography
              color={` ${
                equipment.amount >
                parseInt(license.amount_initial) -
                  parseInt(license.amount_ordered) -
                  parseInt(license.amount_delivered)
                  ? "red"
                  : "black"
              }`}
            >
              {regulation === "FR_MUNITIONS_LIST"
                ? i18n.t("pages.license.imput.left_amount") +
                  " : " +
                  (
                    parseInt(license.amount_initial) -
                    parseInt(license.amount_ordered) -
                    parseInt(license.amount_delivered)
                  ).toLocaleString()
                : i18n.t("pages.license.imput.left_amount") +
                  license.amount_actual}
            </Typography>
          );
        default:
          return null;
      }
    }
  };
  useEffect(() => {
    setLicense(license);
  }, [license]);

  return (
    <Stack
      sx={{
        height: "auto",
      }}
      alignItems={"center"}
      alignContent={"center"}
      direction={"row"}
    >
      <Card
        style={{
          flex: 1,
          marginRight: 5,
          border: hovered ? "2px solid red" : "2px solid #00a8ff",
        }}
        sx={{
          height: "15em",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5">
            {i18n.t(`components.searchEccn.commande`)} :{" "}
            <b>{equipment.model ? equipment.model : "-"}</b>
          </Typography>
          <Box>
            <br></br>
            <Typography>
              {i18n.t("pages.license.add.part-number")} :{" "}
              {equipment.part_number}
            </Typography>
            <Typography>
              {i18n.t("pages.license.add.quantity")} : {equipment.quantity}
            </Typography>
            <Typography>
              {i18n.t("pages.license.add.unit-price")} : {equipment.unit_price}
            </Typography>
            <Typography>
              {i18n.t("pages.license.add.amount")} : {equipment.amount}
            </Typography>
            <Typography>
              {i18n.t("pages.license.add.end-user")} : {flow.customer}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Tooltip title="Supprimer l'association">
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IconButton onClick={handleOpen}>
            {hovered ? (
              <CloseIcon fontSize="large" sx={{ color: "red" }} />
            ) : (
              <CompareArrowsIcon fontSize="large" sx={{ color: "#00a8ff" }} />
            )}
          </IconButton>
        </div>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            outline: "none",
          }}
          sx={{ p: "3em" }}
        >
          <Typography variant="h6">
            Voulez-vous supprimer l'association de cette licence ?
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
            mt="3em"
          >
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              size="large"
              // sx={{ p: 5, borderRadius: "5px" }}
            >
              Confirmer
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="large"
              // sx={{ p: 5, borderRadius: "5px" }}
            >
              Annuler
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Card
        style={{
          flex: 1,
          marginLeft: 5,
          border: hovered ? "2px solid red" : "2px solid #00a8ff",
        }}
        sx={{
          height: "15em",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5">
            {i18n.t("pages.license.imput.title-tab")} :{" "}
            <b>
              {theLicense?.license_number ? theLicense?.license_number : "-"}
            </b>
          </Typography>
          {hasFieldError(theLicense, "date")}
          <b>{i18n.t("pages.license.add.part-number") + " : "}</b>
          {theLicense?.pn ? theLicense?.pn : " - "}
          {hasFieldError(theLicense, "qty")}
          {hasFieldError(theLicense, "price")}
          {hasFieldError(theLicense, "amount")}
          <b>{i18n.t("pages.license.add.end-user") + " : "}</b>
          {theLicense?.company_name ? theLicense?.company_name : " - "}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default AssociationCard;

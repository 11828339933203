/* Styles import */
import styles from "../admin.module.css";

/* Global import */
import React, { Component, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import ProgressUpload from "../../../components/progressUpload";
import { getToLocaleDate } from "../../../utils/date";

interface Props {}
interface InjectedProps extends Props {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {}

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const UsRules: React.FC<Props> = ({ rootStore }) => {
  useEffect(() => {
    rootStore.controlReasonsStore.getUploadCountriesVersion();
    rootStore.controlReasonsStore.getUploadEccnsVersion();
    rootStore.controlReasonsStore.getUploadList744Version();
  }, []);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage="ADMIN" />

      <HeaderAdmin pages="US_RULES" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.control-reasons.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadCountries(file)
            }
            progress={rootStore.controlReasonsStore.countriesPourcent}
            uploadState={rootStore.controlReasonsStore.countriesState}
            label={i18n.t("pages.admin.control-reasons.country-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${getToLocaleDate(
              rootStore.controlReasonsStore.countriesVersion.date,
            )}
                ${i18n.t("pages.admin.upload.filename")}: ${
                  rootStore.controlReasonsStore.countriesVersion.fileName
                }`}
          />

          <ProgressUpload
            onChange={(file) => rootStore.controlReasonsStore.uploadEccns(file)}
            progress={rootStore.controlReasonsStore.eccnsPourcent}
            uploadState={rootStore.controlReasonsStore.eccnsState}
            label={i18n.t("pages.admin.control-reasons.eccn-reasons")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}: ${getToLocaleDate(
              rootStore.controlReasonsStore.eccnsVersion.date,
            )}
                  ${i18n.t("pages.admin.upload.filename")}: ${
                    rootStore.controlReasonsStore.eccnsVersion.fileName
                  }`}
          />
        </CardContent>
      </Card>

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.admin.list744.title")} />

        <CardContent>
          <ProgressUpload
            onChange={(file) =>
              rootStore.controlReasonsStore.uploadList744(file)
            }
            progress={rootStore.controlReasonsStore.list744Pourcent}
            uploadState={rootStore.controlReasonsStore.list744State}
            label={i18n.t("pages.admin.list744.label")}
            subLabel={` ${i18n.t("pages.admin.upload.date")}:
                  ${getToLocaleDate(
                    rootStore.controlReasonsStore.list744Version.date,
                  )}
                  ${i18n.t("pages.admin.upload.filename")}:
                   ${rootStore.controlReasonsStore.list744Version.fileName}`}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(UsRules));

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./carousel.module.css";
import {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface Props {
  sources: string[];
}

function Carousel(props: Props) {
  const [index, setIndex] = useState(1);

  function renderSliderItems(sources: string[]) {
    if (sources) {
      return sources.map((source, idx) => (
        <SwiperSlide key={idx} className={styles.toto}>
          <img src={source} alt={`Image ${idx}`} className="sliderImage" />
        </SwiperSlide>
      ));
    }
    return null;
  }

  const handleChangeIndex = (currentIndex: number) => {
    setIndex(currentIndex);
  };

  return (
    <>
      <style>{`
     .monSwiper {
          height: 380px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        .sliderImage {
          width: 100%; /* Ajustez la largeur de l'image pour remplir l'élément parent */
          height: auto; /* Laissez la hauteur être ajustée automatiquement en conservant le ratio d'aspect */
          object-fit: cover; /*
        }
    `}</style>
      <Swiper
        className="monSwiper"
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        pagination
        navigation
        onSlideChange={() => {}}
      >
        {renderSliderItems(props.sources)}
      </Swiper>
    </>
  );
}

export default Carousel;

import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import classes from "../Button/styles.module.scss";

const IconButton: FC<HTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  ...rest
}) => (
  <button
    type="button"
    className={cx(className, classes["ds-button--icon"])}
    {...rest}
  >
    {children}
  </button>
);

export default IconButton;

/* Styles import */
import styles from "./timeLine.module.css";
import i18n from "i18next";
/* Global import */
import React, { Component } from "react";
import {
  Step,
  StepHeader,
  Stepper,
} from "../../components/horizon-components-react/src/components";
import { inject, observer } from "mobx-react";
import RootStore from "../../stores/rootStore";

interface InjectedProps extends Props {
  rootStore: import("../../stores/rootStore").default;
}

interface Props {
  step: number;
  rootStore?: RootStore;
}

interface State {}

@inject("rootStore")
@observer
export default class TimeLine extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    const roles = null;
    const { step, rootStore } = this.props;
    if (rootStore) {
      const user = rootStore.userStore.user;
      let roles: string | undefined;

      if (user) {
        roles = user.role;
      }
    }
    return (
      <div className={styles.margin_top_2}>
        {roles != "L_A" && roles != "L_R_A" ? (
          <Stepper horizontal activeStep={step}>
            <Step>
              <StepHeader>{i18n.t("global.tl_Classement")}</StepHeader>
            </Step>
            <Step>
              <StepHeader> {i18n.t("global.tl_Exportabilité")}</StepHeader>
            </Step>
            <Step>
              <StepHeader>{i18n.t("global.tl_Screening")}</StepHeader>
            </Step>
            <Step>
              <StepHeader>{i18n.t("global.tl_Licences")}</StepHeader>
            </Step>
            <Step>
              <StepHeader>{i18n.t("global.tl_Expédition")}</StepHeader>
            </Step>
          </Stepper>
        ) : (
          false
        )}
      </div>
    );
  }
}

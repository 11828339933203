import React, { Component, HTMLAttributes, ChangeEvent } from "react";
import cx from "classnames";
import uniqueId from "lodash.uniqueid";
import classes from "./styles.module.scss";

type Props = {
  /** The name of the `<input />` */
  name?: string;
  /** The label to show next to the `<input />` */
  label?: string;
  /** If `true`, the component is checked */
  checked?: boolean;
  /** The default checked status for an uncontrolled component */
  defaultChecked?: boolean;
  /** The callback for when the input's state changes */
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  /** The size of the toggle */
  size?: "normal" | "medium" | "small";
  /** If `true`, the component will be better suited to dark backgrounds */
  dark?: boolean;
};

type ToggleProps = Props &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, "onChange">
  >;

type State = {
  checked?: boolean;
};

export default class Toggle extends Component<ToggleProps, State> {
  private isControlled: boolean;
  private id: string;

  static defaultProps = {
    size: "normal",
  };

  constructor(props: ToggleProps) {
    super(props);
    this.id = uniqueId("toggle-");
    this.isControlled = props.checked != null;
    this.state = this.isControlled
      ? {}
      : {
          checked:
            props.defaultChecked !== undefined ? props.defaultChecked : false,
        };
  }

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (!this.isControlled) {
      this.setState({ checked });
    }
    if (this.props.onChange) {
      this.props.onChange(event, checked);
    }
  };

  render() {
    const { name, label, size, dark } = this.props;
    const id = this.props.id || this.id;
    const checked = this.isControlled ? this.props.checked : this.state.checked;
    return (
      <div
        className={cx(classes["ds-toggles"], {
          [classes["ds-toggles--medium"]]: size === "medium",
          [classes["ds-toggles--small"]]: size === "small",
          [classes["ds-toggles--dark"]]: dark === true,
        })}
      >
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={this.handleChange}
        />
        {label && (
          <div className={classes["ds-label-wrapper"]}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
      </div>
    );
  }
}

import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

interface ILoadingProps {
  /** The content of the Loading */
  children?: React.ReactNode;
  /** Can display a smaller spinner */
  small?: boolean;
  /** Add a background to the spinner */
  fill?: "block" | "block-white";
  /** Replace circle by dots */
  dots?: boolean;
}

interface ILoadingState {}

export default class Loading extends React.Component<
  ILoadingProps,
  ILoadingState
> {
  static defaultProps = {
    small: false,
    dots: false,
  };

  constructor(props: ILoadingProps) {
    super(props);
  }

  render() {
    const { small, fill, dots, children } = this.props;

    if (dots) {
      return (
        <div className={cx(classes["ds-dots"])}>
          <div className={cx(classes["ds-dots__loading"])}>
            <div className={cx(classes["ds-dots__item"])}></div>
            <div className={cx(classes["ds-dots__item"])}></div>
            <div className={cx(classes["ds-dots__item"])}></div>
          </div>
          {this.renderLabel(children)}
        </div>
      );
    } else {
      return (
        <div
          className={cx(classes["ds-spinner"], {
            [classes["ds-spinner--small"]]: small,
            [classes["ds-spinner--block"]]: fill === "block",
            [classes["ds-spinner--block-white"]]: fill === "block-white",
            [classes["ds-spinner--block-alone"]]:
              (fill === "block" || fill === "block-white") &&
              children === undefined,
          })}
        >
          <div className={cx(classes["ds-spinner__loading"])}>
            <svg
              className={cx(classes["ds-spinner__svg"])}
              viewBox="-40 -40 80 80"
            >
              <circle cx="0" cy="0" r="37.5"></circle>
            </svg>
          </div>
          {this.renderLabel(children)}
        </div>
      );
    }
  }

  renderLabel(children: React.ReactNode): JSX.Element {
    if (children) {
      return (
        <span className={cx(classes["ds-spinner__label"])}>{children}</span>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

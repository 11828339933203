/* Custom import */
import Api from "../utils/api";

export default class ClassificationStore {
  rootStore: import("./rootStore").default;
  api: Api;

  constructor(rootStore: import("./rootStore").default) {
    this.api = new Api();
    this.rootStore = rootStore;
  }

  async getModels(productType: ProductType) {
    try {
      return await this.api.getModels(productType);
    } catch (e) {}
  }

  async getEccnsByModel(departureCountryId: number, model: string) {
    try {
      return await this.api.getEccnsByModel(departureCountryId, model);
    } catch (e) {}
  }

  async getTypes(productType?: ProductType) {
    try {
      return await this.api.getTypes(productType);
    } catch (e) {}
  }

  async getOptionModels(grouping: string, productType?: ProductType) {
    try {
      return await this.api.getOptionModels(grouping, productType);
    } catch (e) {}
  }

  async postClassificationDatabseFile(classification: File, division: string) {
    try {
      return await this.api.postClassification(classification, division);
    } catch (e) {}
  }
}

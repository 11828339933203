/* Styles import */
import styles from "./us.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  TextInput,
  Title,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../components/appBar";
import Footer from "../../../components/footer";
import HeaderScreeningComponent from "../../../components/headerScreening";
import TimeLine from "../../../components/timeLine";
import { flowUrl } from "../../../utils/generator";
import AutoComplete from "../../../components/autoComplete";
import Loader from "../../../components/loader";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  company?: string;
  country?: number;
  flowId?: string;
}

const AhScreening: React.FC<Props> = ({ rootStore }) => {
  const { id, type } = useParams() as {
    id?: string;
    type?: string;
  };
  const flowIdF = id;
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };
  const [country, setCountry] = useState(
    rootStore.exportabilityStore.countryOfDestination,
  );
  const [company, setCompany] = useState("");
  //const [flowId, setFlowId] = useState('');

  const getCompany = () => {
    const company = rootStore.screeningStore.company;

    rootStore.screeningStore.company = "";
    return company ? company : "";
  };

  const getCountry = () => {
    const countryId = rootStore.screeningStore.country;

    rootStore.screeningStore.country = -1;

    return countryId ? countryId : 0;
  };

  const submitIsDisabled = () => {
    return !company;
  };

  const handleSubmit = async () => {
    if (company) {
      rootStore.screeningStore.setCompany(company);
      // rootStore.screeningStore.setCountry(country)
      await rootStore.screeningStore.getBasicEntitiesah(company);
      navigateTowards("/screening/basic/ah/choose-basic-entity");
    }
  };

  useEffect(() => {
    rootStore.countriesStore.getCountries();
    const fetchData = async () => {
      if (flowIdF) {
        const flow = await rootStore.flowStore.getById(parseInt(flowIdF));

        if (flow) {
          setCompany(flow.customer);
          setCountry(flow.destinationCountryId);
          //setFlowId(flowIdF);
        }
      } else if (rootStore.screeningStore.company !== "") {
        setCompany(getCompany());
        setCountry(getCountry());
      }
    };

    fetchData(); // Appeler la fonction fetchData une fois, équivalent à componentDidMount

    // Nettoyage
    return () => {
      // Code de nettoyage ici si nécessaire
    };
  }, [id, rootStore.screeningStore.company]);

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <HeaderScreeningComponent
        type="BASIC"
        pages="AHMENU"
        rootStore={rootStore}
      />

      {rootStore.screeningStore.entitiesState === "PENDING" && <Loader />}

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2" className={styles.pStyles}>
          Military end user (MEU) check
        </Title>
        <Title tag="h5" className={styles.pStyles}>
          {i18n.t("pages.screening.entity.choose-entity.titleah")}
        </Title>
        <form className={styles.form}>
          <div className={styles.row}>
            <div className={styles.input}>
              <TextInput
                id="company"
                type="text"
                label={i18n.t("pages.screening.entity.company")}
                value={company || ""}
                required={true}
                errorText={i18n.t("errors.company-not-defined")}
                disabled={flowIdF !== undefined}
                onChange={(value) => setCompany(value.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
              />
            </div>
          </div>
        </form>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => goBack(),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            label: i18n.t("components.searchEccn.search-button"),
            primary: true,
            disabled: submitIsDisabled(),
          },
        ]}
      >
        {flowIdF && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(AhScreening));

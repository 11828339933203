import React from "react";
import { Box, Button } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

type ButtonProps = {
  id: string;
  onClick: () => void;
  label: string;
  primary?: boolean;
  disabled?: boolean;
  bg?: string;
  icon?: SvgIconComponent; // Type pour l'icône
  iconPosition?: "left" | "right" | string; // Position de l'icône
};

type FooterProps = {
  leftButtons?: ButtonProps[];
  rightButtons?: ButtonProps[];
  height?: "small" | "medium" | "large";
};

const heightStyles = {
  small: { height: 40, padding: "8px 16px" },
  medium: { height: 60, padding: "16px 24px" },
  large: { height: 80, padding: "24px 32px" },
};

const Footer: React.FC<FooterProps> = ({
  leftButtons = [],
  rightButtons = [],
  height = "medium",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        borderTop: "1px solid #e0e0e0",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 9500,
        ...heightStyles[height],
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {leftButtons.map((button) => (
          <Button
            key={button.id}
            onClick={button.onClick}
            variant={button.primary ? "contained" : "outlined"}
            disabled={button.disabled}
            sx={{
              margin: 1,
              backgroundColor: button.primary ? button.bg : "transparent",
              borderColor: button.bg,
              color: button.primary ? "white" : button.bg, // Adjust text color for outlined variant
            }}
            startIcon={
              button.icon && button.iconPosition !== "right" ? (
                <button.icon />
              ) : null
            }
            endIcon={
              button.icon && button.iconPosition === "right" ? (
                <button.icon />
              ) : null
            }
          >
            {button.label}
          </Button>
        ))}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {rightButtons.map((button) => (
          <Button
            key={button.id}
            onClick={button.onClick}
            variant={button.primary ? "contained" : "outlined"}
            disabled={button.disabled}
            sx={{
              margin: 1,
              backgroundColor: button.primary ? button.bg : "transparent",
              borderColor: button.bg,
              color: button.primary ? "white" : button.bg, // Adjust text color for outlined variant
            }}
            startIcon={
              button.icon && button.iconPosition !== "right" ? (
                <button.icon />
              ) : null
            }
            endIcon={
              button.icon && button.iconPosition === "right" ? (
                <button.icon />
              ) : null
            }
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;

import React, { FC, ReactNode } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  /**
   * If true, responsive max-widths won't be set ; the container
   * will be `width: 100%` across all viewport sizes.
   */
  children?: ReactNode;
  fluid?: boolean;
};

const GridContainer: FC<Props> = ({ fluid = false, children }) => (
  <div
    className={cx(classes["ds-container"], {
      [classes["ds-container-fluid"]]: fluid,
    })}
  >
    {children}
  </div>
);

export default GridContainer;

/* Styles import */
import styles from "../admin.module.css";
import SearchIcon from "@mui/icons-material/Add";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteSweep";
import {
  Title,
  Checkbox,
  IconButton,
  TextInput,
  Button,
  GridContainer,
  GridRow,
  GridCol,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";
import { Hidden, Tooltip } from "@mui/material";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import { getToLocaleDate } from "../../../utils/date";
import ProgressUpload from "../../../components/progressUpload";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  tenant: string;
  R_checklist: R_entite[];
}
interface UpdatedProps {
  [key: string]: any; // Définissez les types appropriés pour les propriétés mises à jour
}

const Entite: React.FC<Props> = ({ rootStore }) => {
  const [tenant, setTenant] = useState<string>("");
  const [R_checklist, setRCheckList] = useState<R_entite[]>([]);
  const [updated, setUpdated] = useState<number>(0);

  const addR_checklist = () => {
    let R_checklist_tmp = [...R_checklist];
    R_checklist_tmp.push({
      id_entite: "",
      id_tenant: tenant || "",
    } as never);

    setRCheckList(R_checklist_tmp);
    //saveR_checklist();
  };

  const addR_checklist_index = (index: number) => {
    let R_checklist_tmp = [...R_checklist];
    R_checklist_tmp.splice(index + 1, 0, {
      id_entite: "",
      id_tenant: tenant!,
    } as never);

    setRCheckList(R_checklist_tmp);

    //saveR_checklist();
  };

  const updateR_checklist = (index: number, updatedProps: UpdatedProps) => {
    // Créer une copie de la liste R_checklist
    const updatedChecklist = [...R_checklist];

    // Mettre à jour les propriétés de l'objet reponse correspondant à l'index
    updatedChecklist[index] = {
      ...updatedChecklist[index],
      ...updatedProps,
    };

    // Mettre à jour l'état avec la nouvelle liste mise à jour
    setRCheckList(updatedChecklist);
  };

  const deleteR_checklist = (index: number) => {
    // Obtenez le tableau actuel de la liste des questions
    let tmpR = [...R_checklist];

    // Supprimez la question à l'indice spécifié
    tmpR.splice(index, 1);

    // Mettez à jour l'état avec le nouveau tableau
    setRCheckList(tmpR);
    //saveR_checklist();
  };

  const saveR_checklist = () => {
    // Envoyer les données à l'API Laravel
    fetch(`${process.env.REACT_APP_SCREEN}/Post_entite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ R_checklist, tenant }), // Envoyer la liste Q_checklist au format JSON
    })
      .then((response) => response.json())
      .then((data) => {
        setUpdated(updated + 1);
      })
      .catch((error) => {
        // Gérer les erreurs lors de l'appel à l'API
        console.error(error);
      });
  };

  // eslint-disable-next-line

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const paramValue = params.get("parametre");
      const tenantValue = paramValue || "";

      setTenant(tenantValue);

      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/get_entite?tenant=${encodeURIComponent(tenantValue)}`
        );
        const data = await response.json();
        setRCheckList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [updated]);

  const renderRStep = () => {
    return (
      <div>
        <div className={styles.equipmentBox}>
          <GridRow>
            <GridCol md={5}>
              <div
                className={styles.equipmentHeader}
                style={{ marginLeft: "20px" }}
              >
                Name
              </div>
            </GridCol>

            <GridCol md={"auto"}></GridCol>

            <GridCol md={2}>
              <div className={styles.equipmentHeader}>{`Action`}</div>
            </GridCol>
          </GridRow>
        </div>

        <GridContainer>{renderRStep1(" ")}</GridContainer>
      </div>
    );
  };

  const renderRStep1 = (key: string) => {
    return (
      <div>
        {R_checklist.map((reponse, index) => {
          return (
            <div className={styles.equipmentBox} key={index}>
              <GridRow>
                <GridCol md={5}>
                  <TextInput
                    id="rule"
                    label=""
                    value={reponse.id_entite || ""}
                    type="text"
                    min={0}
                    step={1}
                    errorText={i18n.t("errors")}
                    onChange={(e) =>
                      updateR_checklist(index, {
                        ...reponse,
                        id_entite: e.target.value,
                      })
                    }
                  />
                </GridCol>

                <GridCol md={2}>
                  <IconButton
                    id="search-national"
                    className={styles.searchButton}
                    onClick={() => {
                      addR_checklist_index(index);
                    }}
                  >
                    <Tooltip title="Ajouter">
                      <SearchIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => deleteR_checklist(index)}>
                    <Tooltip title="Supprimer">
                      <DeleteIcon style={{ color: "#0085ad" }} />
                    </Tooltip>
                  </IconButton>
                </GridCol>
              </GridRow>
            </div>
          );
        })}

        <Button
          id="add-equipment"
          type="button"
          onClick={() => addR_checklist()}
          size="medium"
        >
          {i18n.t("pages.admin.users.add")}
        </Button>
        <span style={{ marginLeft: "35px" }}></span>

        <Button
          id="add-equipment"
          type="button"
          onClick={() => saveR_checklist()}
          size="medium"
        >
          Save
        </Button>
      </div>
    );
  };
  return (
    <div>
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />

      <HeaderAdmin pages="CLASSIFICATION_DATABASE" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={"Entités du tenant " + tenant} />

        <CardContent>
          <FormGroup>{renderRStep()}</FormGroup>
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Entite));

/* Styles import */
import styles from "../regulation.module.css";

/* Styles import */
import globalStyle from "../../../globalStyles.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import i18n from "i18next";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderRegulationEdit from "../../../components/headerRegulation/headerRegulation-edit";
import {
  Card,
  CardContent,
  Title,
} from "../../../components/horizon-components-react/src/components";
import ListCountryEdit from "../../../components/listCountry/listCountryEdit";
import { useNavigate } from "react-router-dom";
import Api from "../../../utils/api";
import { inject, observer } from "mobx-react";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  data: any;
}

const Sanctions: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SCREEN}/controle?categorie=sanctions`
        );
        const resultat = await response.json();
        setState({ data: resultat });
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  });

  const renderAllList = () => {
    const liste_country = [
      { index: 3, name: "European sanctions" },
      { index: 9, name: "France" },
      { index: 10, name: "Canada" },
      { index: 5, name: "UK" },
      { index: 6, name: "US sanctions" },
    ];
    return liste_country.map((pays, index: number) => (
      <ListCountryEdit
        key={pays.index}
        list={state.data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
        categorie="sanctions"
      />
    ));

    return liste_country.map((pays, index: number) => (
      <ListCountryEdit
        key={pays.index}
        list={state.data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
        categorie="sanctions"
      />
    ));
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"REGULATION"} />
      <HeaderRegulationEdit pages="SANCTIONS" />
      <div className={globalStyle.body}>
        <Card>
          <Title tag="h2">{i18n.t("pages.regulation.sanctions.title")}</Title>
          <CardContent>
            <ul className={styles.left_padding_1}>{renderAllList()}</ul>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(Sanctions));

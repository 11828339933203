/* Styles import */
import styles from "../regulation.module.css";

/* Styles import */
import globalStyle from "../../../globalStyles.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import i18n from "i18next";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderRegulationEdit from "../../../components/headerRegulation/headerRegulation-edit";
import {
  Card,
  CardContent,
  Title,
} from "../../../components/horizon-components-react/src/components";
import ListCountryEdit from "../../../components/listCountry/listCountryEdit";
import { useNavigate } from "react-router-dom";
import Api from "../../../utils/api";
import { inject, observer } from "mobx-react";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  data: any;
}
interface Item {
  id: number;
  url: string;
}
const Wassenaar: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const api = new Api();
  const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SCREEN}/getWassenaar`
        );
        const resultat = await response.json();
        setState({ data: resultat });
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData()}, ['']);

  const renderAllList = () => {
    const liste_country = [
      { index: 3, name: "Sanctions européennes" },
      { index: 5, name: "UK" },
      { index: 6, name: "US sanctions" },
    ];
    return liste_country.map((pays, index: number) => (
      <ListCountryEdit
        key={pays.index}
        list={state.data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
        categorie="sanctions"
      />
    ));

    return liste_country.map((pays, index: number) => (
      <ListCountryEdit
        key={pays.index}
        list={state.data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
        categorie="sanctions"
      />
    ));
  };
  function modifier(id: string, ligne: string) {
    const url = prompt("Nouvelle URl", ligne);
    if (url) {
      fetch(`${process.env.REACT_APP_SCREEN}/updateWassenaar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          url: url,
        }), // Envoyer la liste Q_checklist au format JSON
      })
        .then((response) => {
          return response.text();
        })
        .then((resultat) => {
          alert("Mise a jour bien effectuée");
        window.location.reload();
        });
    }
  }
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"REGULATION"} />
      <HeaderRegulationEdit pages="Wassenaar" />
      <div className={globalStyle.body}>
        <Card>
          <Title tag="h2">Wassenaar Arrangement </Title>
          <br></br>
          <CardContent>
            <ul className={styles.left_padding_1}>
              {state.data.map((item: Item) => (
                <span>
                  <li key={item.id}>
                    {item.id == 1
                      ? "Wassenaar Arrangement website"
                      : "List of Advisory Questions for the Industry"}
                    <br></br>
                    

                    <input
                      name={item.id?.toString()}
                      type="text"
                      value={item.url}
                      onClick={() => modifier(item.id?.toString(), item.url)}
                      style={{ width: "100%" }}
                    />
                  </li>
                  <br></br>
                </span>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(Wassenaar));

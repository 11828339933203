/* Styles import */
import styles from "../regulation.module.css";

/* Styles import */
import globalStyle from "../../../globalStyles.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import i18n from "i18next";
// eslint-disable-next-line

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderRegulation from "../../../components/headerRegulation";
import {
  Card,
  CardContent,
  Title,
} from "../../../components/horizon-components-react/src/components";
import ListCountry from "../../../components/listCountry";
import { inject, observer } from "mobx-react";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}

const ControlLists: React.FC<Props> = ({ rootStore }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SCREEN}/controle?categorie=controle`
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, []);

  const renderAllList = () => {
    const liste_country = [
      { index: 1, name: "Australia" },
      { index: 2, name: "Belgium" },
      { index: 3, name: "Canada" },
      { index: 4, name: "France" },
      { index: 5, name: "Germany" },
      { index: 6, name: "India" },
      { index: 7, name: "Italy" },
      { index: 8, name: "Europe" },
      { index: 9, name: "Netherlands" },
      { index: 10, name: "South Africa" },
      { index: 11, name: "UK" },
      { index: 12, name: "USA" },
    ];
    return liste_country.map((pays, index: number) => (
      <ListCountry
        key={pays.index}
        list={data.filter((list: any) => list.pays === pays.name)}
        country={pays.name}
      />
    ));
  };

  const listAllCountries = i18n.t("pages.regulation.control-lists.list", {
    returnObjects: true,
  });
  //const lien=['http://www.yahoo.Fr','http://www.1yahoo.Fr','http://www.2yahoo.Fr','http://www.3yahoo.Fr','http://www.y4ahoo.Fr','http://www.y5ahoo.Fr']

  // console.log(valuesArray[0].elements)
  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"REGULATION"} />

      <HeaderRegulation pages="CONTROL_LISTS" />

      <div className={globalStyle.body}>
        <Card>
          <Title tag="h2">
            {i18n.t("pages.regulation.control-lists.title")}
          </Title>
          <CardContent>
            <ul className={styles.left_padding_1}>{renderAllList()}</ul>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(ControlLists));

/* Styles import */
import styles from "./basicEntityChoose.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  Title,
  GridRow,
  GridCol,
  Button,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams } from "react-router-dom";

/* Custom import */
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import EntityComponent from "../../../../components/entity";

import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import { toJS } from "mobx";

interface Props {
  rootStore: import("../../../../stores/rootStore").default;
}

interface State {

  basicEntity?: BasicEntity;
  basicEntity_eu?: BasicEntity;
  basicEntity_fr?: BasicEntity;
  basicEntity_canada?: BasicEntity;
  basicEntity_uk?: BasicEntity;
  flowId?: string;
  fuzzyResult: boolean;
  skipPage: boolean;
}

const BasicEntityChoose: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };
const location = useLocation();
  let company = "";
//console.log(location.state.company)
  if (location.state) {
    company = location.state.company;
    
  } ;
  const [skipPage, setSkipPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fuzzyResult, setFuzzyResult] = useState(false);
  const [basicEntity, setBasicEntity] = useState<BasicEntity>();
  const [basicEntity_fr, setBasicEntity_fr] = useState<BasicEntity>();
  const [basicEntity_eu, setBasicEntity_eu] = useState<BasicEntity>();
  const [basicEntity_canada, setBasicEntity_canada] = useState<BasicEntity>();
  const [basicEntity_uk, setBasicEntity_uk] = useState<BasicEntity>();

  const { id } = useParams();
  const flowId = id;
  const currentProps = rootStore.screeningStore;
   const currentcountry=rootStore.screeningStore.country!
  useEffect(() => {
    if (flowId) {
      // Utilisez la fonction de mise à jour de l'état du composant fonctionnel ici
      // (vous devrez déclarer une variable d'état à l'aide de useState)
    }

    if (currentProps.liste_us && currentProps.basicEntities.length == 1)
      setBasicEntity(currentProps.basicEntities[0]);
    if (currentProps.liste_eu && currentProps.basicEntities_eu.length == 1)
      setBasicEntity_eu(currentProps.basicEntities_eu[0]);
    if (currentProps.liste_fr && currentProps.basicEntities_fr.length == 1)
      setBasicEntity_fr(currentProps.basicEntities_fr[0]);
    if (
      currentProps.liste_canadien &&
      currentProps.basicEntities_canada.length == 1
    )
      setBasicEntity_canada(currentProps.basicEntitiesFuzzy_canada[0]);
    if (currentProps.liste_uk && currentProps.basicEntities_uk.length == 1)
      setBasicEntity_uk(currentProps.basicEntities_uk[0]);

    let force = true;

    if (currentProps.liste_us && currentProps.basicEntities.length > 1)
      force = false;

    if (currentProps.liste_eu && currentProps.basicEntities_eu.length > 1)
      force = false;

    if (currentProps.liste_fr && currentProps.basicEntities_fr.length > 1)
      force = false;

    if (
      currentProps.liste_canadien &&
      currentProps.basicEntities_canada.length > 1
    )
      force = false;

    if (currentProps.liste_uk && currentProps.basicEntities_uk.length > 1)
      force = false;

    // alert(force);

    if (force) {
      handleSubmit();
    }

    const checkEmptyEntities = (...entityArrays: BasicEntity[][]) => {
      if (entityArrays.every((entities) => entities.length === 0)) {
        rootStore.screeningStore.setSkipPage(true);
        handleSubmit();
      }
    };

    checkEmptyEntities(
      currentProps.basicEntities,
      currentProps.basicEntitiesFuzzy,
      currentProps.basicEntities_fr,
      currentProps.basicEntitiesFuzzy_fr,
      currentProps.basicEntities_canada,
      currentProps.basicEntitiesFuzzy_canada,
      currentProps.basicEntities_uk,
      currentProps.basicEntitiesFuzzy_uk,
      currentProps.basicEntities_eu,
      currentProps.basicEntitiesFuzzy_eu
    );
  }, [flowId, rootStore.screeningStore]);

  const handleSubmit = async () => {
      setLoading(true);

    let tmpbasicEntity = basicEntity;
    let tmpbasicEntity_fr = basicEntity_fr;
    let tmpbasicEntity_eu = basicEntity_eu;
    let tmpbasicEntity_canada = basicEntity_canada;
    let tmpbasicEntity_uk = basicEntity_uk;

    if (currentProps.liste_us && currentProps.basicEntities.length == 1) {
      tmpbasicEntity = currentProps.basicEntities[0];
    }
    if (
      rootStore.screeningStore.liste_eu &&
      rootStore.screeningStore.basicEntities_eu.length == 1
    )
      tmpbasicEntity_eu = rootStore.screeningStore.basicEntities_eu[0];
    if (
      rootStore.screeningStore.liste_fr &&
      rootStore.screeningStore.basicEntities_fr.length == 1
    )
      tmpbasicEntity_fr = rootStore.screeningStore.basicEntities_fr[0];
    if (
      rootStore.screeningStore.liste_canadien &&
      rootStore.screeningStore.basicEntities_canada.length == 1
    )
      tmpbasicEntity_canada = rootStore.screeningStore.basicEntities_canada[0];
    if (
      rootStore.screeningStore.liste_uk &&
      rootStore.screeningStore.basicEntities_uk.length == 1
    )
      tmpbasicEntity_uk = rootStore.screeningStore.basicEntities_uk[0];

  

    if (rootStore.screeningStore.liste_us) {
      if (tmpbasicEntity) {
        await rootStore.screeningStore.screeningBasic(
          true,
          parseInt(flowId || "0"),
          tmpbasicEntity.source,
          tmpbasicEntity.name
        );
      } else {
        await rootStore.screeningStore.screeningBasic(
          false,
          parseInt(flowId || "0"),
          "",
          ""
        );
      }
    }
    setLoading(false);

    const company_fr = toJS(tmpbasicEntity_fr);
    const company_eu = toJS(tmpbasicEntity_eu);
    const company_us = toJS(tmpbasicEntity);
    const company_canada = toJS(tmpbasicEntity_canada);
    const company_uk = toJS(tmpbasicEntity_uk);

    //alert(company_us?.name)
    navigate(flowUrl("/screening/basic/All/result", flowId), {
      state: {
         company: company,
        company_us: company_us?.name,
        company_eu: company_eu?.name,
        company_fr: company_fr?.name,
        company_canada: company_canada?.name,
        company_uk: company_uk?.name,
        skipPage: skipPage, // supposant que skipPage est une variable d'état ou une prop
      },
    });

    setLoading(false);
  };

  const noMatch = () => {
    return !(
      !basicEntity_fr &&
      !basicEntity_eu &&
      !basicEntity_canada &&
      !basicEntity_uk &&
      !basicEntity
    );
  };

  const isSubmit = () => {
    return (
      !basicEntity_fr &&
      !basicEntity_eu &&
      !basicEntity_canada &&
      !basicEntity_uk &&
      !basicEntity
    );
  };

  const getCompany = () => {
    //const company = rootStore.screeningStore.company;

    return company ? company : "";
  };

  const getCountry = () => {
    const countryId = currentProps.country;

    if (countryId) {
      const country = rootStore.countriesStore.getCountryById(countryId);

      return country ? country.name : "";
    }
  };

  const length = fuzzyResult
    ? rootStore.screeningStore.basicEntitiesFuzzy.length
    : rootStore.screeningStore.basicEntities.length;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

  

      <div className={styles.body}>
        <GridRow justify="space-between">
          <GridCol>
            <Title tag="h2">
              {i18n.t("pages.screening.entity.choose-entity.title")}
            </Title>
          </GridCol>
          <GridCol>
            <div className={styles.aligner}>
              {getCountry() !== undefined &&
                (rootStore.screeningStore.basicEntities.length ||
                  rootStore.screeningStore.basicEntitiesFuzzy.length > 0) && (
                  <p className={styles.message}>
                    {`${length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${company}"
                    ${i18n.t("pages.screening.entity.choose-entity.in")}
                    "${getCountry()}"
                    `}
                  </p>
                )}

              {getCountry() === undefined &&
                (rootStore.screeningStore.basicEntities.length ||
                  rootStore.screeningStore.basicEntitiesFuzzy.length > 0) && (
                  <p className={styles.message}>
                    {`${length}
                    ${i18n.t(
                      "pages.screening.entity.choose-entity.results-for"
                    )}
                    "${getCompany()}"
            
                    `}
                  </p>
                )}
            </div>
          </GridCol>
        </GridRow>

        <div className={styles.entities}>
          {(rootStore.screeningStore.basicEntities.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy.length !== 0 ||
            rootStore.screeningStore.basicEntities_fr.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy_fr.length !== 0 ||
            rootStore.screeningStore.basicEntities_eu.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy_eu.length !== 0 ||
            rootStore.screeningStore.basicEntities_uk.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy_uk.length !== 0 ||
            rootStore.screeningStore.basicEntities_canada.length !== 0 ||
            rootStore.screeningStore.basicEntitiesFuzzy_canada.length !==
              0) && <p className={styles.message}>{i18n.t("global.fuzzy")}</p>}

          {rootStore.screeningStore.basicEntities.length != 1 &&
            rootStore.screeningStore.liste_us &&
            (rootStore.screeningStore.basicEntities.length !== 0 ||
              rootStore.screeningStore.basicEntitiesFuzzy.length !== 0) && (
              <Title color="primary" dark tag="h5">
                {i18n.t("pages.screening.entity.liste_all")}
              </Title>
            )}

          {rootStore.screeningStore.basicEntities.length != 1 &&
            rootStore.screeningStore.liste_us &&
            !fuzzyResult &&
            rootStore.screeningStore.basicEntities.map(
              (basicEntitymap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntitymap}
                    onClick={() => setBasicEntity(basicEntitymap)}
                    selected={basicEntity && basicEntity === basicEntitymap}
                  />
                );
              }
            )}

          {rootStore.screeningStore.liste_us &&
            fuzzyResult &&
            rootStore.screeningStore.basicEntitiesFuzzy.map(
              (basicEntityMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntityMap}
                    onClick={() => setBasicEntity(basicEntityMap)}
                    selected={basicEntity && basicEntity === basicEntityMap}
                  />
                );
              }
            )}

          <br />
          {rootStore.screeningStore.basicEntities_eu.length != 1 &&
            rootStore.screeningStore.liste_eu &&
            (rootStore.screeningStore.basicEntities_eu.length !== 0 ||
              rootStore.screeningStore.basicEntitiesFuzzy_eu.length !== 0) && (
              <Title color="primary" dark tag="h5">
                {i18n.t("pages.screening.entity.liste_all1")}
              </Title>
            )}

          {rootStore.screeningStore.basicEntities_eu.length != 1 &&
            rootStore.screeningStore.liste_eu &&
            rootStore.screeningStore.basicEntities_eu.map(
              (basicEntity_euMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_euMap}
                    onClick={() => setBasicEntity_eu(basicEntity_euMap)}
                    selected={
                      basicEntity_eu && basicEntity_eu === basicEntity_euMap
                    }
                  />
                );
              }
            )}

          {rootStore.screeningStore.liste_eu &&
            rootStore.screeningStore.basicEntitiesFuzzy_eu.map(
              (basicEntity_euMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_euMap}
                    onClick={() => setBasicEntity_eu(basicEntity_euMap)}
                    selected={
                      basicEntity_eu && basicEntity_eu === basicEntity_euMap
                    }
                  />
                );
              }
            )}
          <br />

          {rootStore.screeningStore.basicEntities_fr.length != 1 &&
            rootStore.screeningStore.liste_fr &&
            (rootStore.screeningStore.basicEntities_fr.length !== 0 ||
              rootStore.screeningStore.basicEntitiesFuzzy_fr.length !== 0) && (
              <Title color="primary" dark tag="h5">
                {i18n.t("pages.screening.entity.liste_all2")}
              </Title>
            )}
          {rootStore.screeningStore.basicEntities_fr.length != 1 &&
            rootStore.screeningStore.liste_fr &&
            rootStore.screeningStore.basicEntities_fr.map(
              (basicEntity_frMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_frMap}
                    onClick={() => setBasicEntity_fr(basicEntity_frMap)}
                    selected={
                      basicEntity_fr && basicEntity_fr === basicEntity_frMap
                    }
                  />
                );
              }
            )}

          {rootStore.screeningStore.liste_fr &&
            rootStore.screeningStore.basicEntitiesFuzzy_fr.map(
              (basicEntity_frMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_frMap}
                    onClick={() => setBasicEntity_fr(basicEntity_frMap)}
                    selected={
                      basicEntity_fr && basicEntity_fr === basicEntity_frMap
                    }
                  />
                );
              }
            )}
          <br />

          {rootStore.screeningStore.basicEntities_canada.length != 1 &&
            rootStore.screeningStore.liste_canadien &&
            (rootStore.screeningStore.basicEntities_canada.length !== 0 ||
              rootStore.screeningStore.basicEntitiesFuzzy_canada.length !==
                0) && (
              <Title color="primary" dark tag="h5">
                {i18n.t("pages.screening.entity.liste_all3")}
              </Title>
            )}
          {rootStore.screeningStore.basicEntities_canada.length != 1 &&
            rootStore.screeningStore.liste_canadien &&
            rootStore.screeningStore.basicEntities_canada.map(
              (basicEntity_canadaMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_canadaMap}
                    onClick={() => setBasicEntity_canada(basicEntity_canadaMap)}
                    selected={
                      basicEntity_canada &&
                      basicEntity_canada === basicEntity_canadaMap
                    }
                  />
                );
              }
            )}

          {rootStore.screeningStore.basicEntities_canada.length != 1 &&
            rootStore.screeningStore.liste_canadien &&
            rootStore.screeningStore.basicEntitiesFuzzy_canada.map(
              (basicEntity_canadaMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_canadaMap}
                    onClick={() => setBasicEntity_canada(basicEntity_canadaMap)}
                    selected={
                      basicEntity_canada &&
                      basicEntity_canada === basicEntity_canadaMap
                    }
                  />
                );
              }
            )}
          <br />

          {rootStore.screeningStore.basicEntities_uk.length != 1 &&
            rootStore.screeningStore.liste_uk &&
            (rootStore.screeningStore.basicEntities_uk.length !== 0 ||
              rootStore.screeningStore.basicEntitiesFuzzy_uk.length !== 0) && (
              <Title color="primary" dark tag="h5">
                {i18n.t("pages.screening.entity.liste_all4")}
              </Title>
            )}
          {rootStore.screeningStore.basicEntities_uk.length != 1 &&
            rootStore.screeningStore.liste_uk &&
            rootStore.screeningStore.basicEntities_uk.map(
              (basicEntity_ukMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_ukMap}
                    onClick={() => setBasicEntity_uk(basicEntity_ukMap)}
                    selected={
                      basicEntity_uk && basicEntity_uk === basicEntity_ukMap
                    }
                  />
                );
              }
            )}

          {rootStore.screeningStore.liste_uk &&
            rootStore.screeningStore.basicEntitiesFuzzy_uk.map(
              (basicEntity_ukMap, index) => {
                return (
                  <EntityComponent
                    rootStore={rootStore}
                    key={index}
                    basicEntity={basicEntity_ukMap}
                    onClick={() => setBasicEntity_uk(basicEntity_uk)}
                    selected={
                      basicEntity_uk && basicEntity_uk === basicEntity_ukMap
                    }
                  />
                );
              }
            )}

          {rootStore.screeningStore.basicEntitiesFuzzy.length !== 0 &&
            !fuzzyResult && (
              <div>
                <Button onClick={() => setFuzzyResult(true)}>
                  {i18n.t("global.view-more-interested")}
                </Button>
              </div>
            )}

          {rootStore.screeningStore.basicEntities.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy.length === 0 &&
            rootStore.screeningStore.basicEntities_fr.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy_fr.length === 0 &&
            rootStore.screeningStore.basicEntities_canada.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy_canada.length === 0 &&
            rootStore.screeningStore.basicEntities_uk.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy_uk.length === 0 &&
            rootStore.screeningStore.basicEntities_eu.length === 0 &&
            rootStore.screeningStore.basicEntitiesFuzzy_eu.length === 0 && (
              <p className={styles.message}>
                {i18n.t("pages.screening.entity.choose-entity.no-match")}{" "}
              </p>
            )}
        </div>
      </div>

      <Footer
        buttons={[
          {
            id: "previous",
            onClick: () => navigateTowards("/screening/basic/all"),
            label: i18n.t("global.previous"),
          },
          {
            id: "submit",
            onClick: () => handleSubmit(),
            disabled: isSubmit(),
            label: "Screening",
            primary: true,
          },
          {
            id: "submit1",
            onClick: () => handleSubmit(),
            disabled: noMatch(),
            label: i18n.t("global.noMatch"),
            primary: true,
          },
        ]}
      >


     

        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}


      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(BasicEntityChoose));

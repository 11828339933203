import React, { FC, ReactNode, HTMLAttributes } from "react";
import cx from "classnames";

type TableBodyProps = HTMLAttributes<HTMLTableSectionElement> & {
  children?: ReactNode;
};

const TableBody: FC<TableBodyProps> = ({ children, ...rest }) => (
  <tbody {...rest}>{children}</tbody>
);

export default TableBody;

import React, { FC, ReactNode, HTMLAttributes } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type FormGroupProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
};

const FormGroup: FC<FormGroupProps> = ({ children, ...rest }) => (
  <div className={cx(classes["ds-form-group"])} {...rest}>
    {children}
  </div>
);

export default FormGroup;

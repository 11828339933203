/* Styles import */
import styles from "./footer.module.css";
import cx from "classnames";

/* Global import */
import React from "react";
import { Button } from "../../components/horizon-components-react/src/components";

interface Props {
  justify?: string;
  buttons: ButtonProps[];
  children?: any;
}
interface ButtonProps {
  id: string;
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  disabled_tmp?: boolean;
  className?: string;
  primary?: boolean;
}

const footerComponent = ({ justify, buttons, children }: Props) => {
  return (
    <div
      className={styles.footer}
      style={{ justifyContent: justify || "space-between" }}
    >
      {buttons.map((button, index) => {
        if (children && index === 0) {
          return (
            <React.Fragment key={index + "frag"}>
              <Button
                id={button.id}
                onClick={button.onClick}
                size="medium"
                disabled={button.disabled}
                primary={button.primary}
              >
                {button.label}
              </Button>
              <div className={styles.width_100} key={index + "div"}>
                {children}
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <Button
              key={index}
              id={button.id}
              onClick={button.onClick}
              size="medium"
              disabled={button.disabled}
              primary={button.primary}
            >
              {button.label}
            </Button>
          );
        }
      })}
    </div>
  );
};

export default footerComponent;

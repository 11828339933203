import React, { FC, ReactNode, HTMLAttributes } from "react";
import cx from "classnames";

type TableCellProps = HTMLAttributes<HTMLTableCellElement> & {
  children?: ReactNode;
};

const TableCell: FC<TableCellProps> = ({ children, ...rest }) => (
  <td {...rest}>{children}</td>
);

export default TableCell;

import * as React from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

import CloseIcon from "@mui/icons-material/Close";

import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import AlertIcon from "@mui/icons-material/Error";
import ErrorIcon from "@mui/icons-material/Cancel";

interface INotificationProps {
  /** Additional classes. */
  className?: string;
  /** Determines the status of the notification */
  status?: "info" | "warning" | "error" | "success";
  /** Communicate an important or an advisory information to the user*/
  role?: "status" | "alert";
  /** Center the message */
  center?: boolean;
  /** Title of the banner */
  title?: string;
  /** Delai in milli seconde to hide notification */
  autoHideDuration?: number;
  /** The content of the notification */
  children?: React.ReactNode;
  /** The callback for when the notification closed */
  onClose?: () => void;
  /** Show / Hide the closable button */
  closable?: boolean;
}

interface INotificationState {
  visible: boolean;
}

const style = {
  close: {
    color: "white",
    fontSize: 14,
  },
  icon: {
    position: "relative" as "relative",
    bottom: -3,
    left: -8,
    fontSize: "1.125rem",
  },
};

export default class Banner extends React.Component<
  INotificationProps,
  INotificationState
> {
  timerAutoHide?: NodeJS.Timeout;
  static defaultProps = {
    status: "info",
    role: "status",
    center: false,
    closable: true,
  };

  constructor(props: INotificationProps) {
    super(props);
    this.state = { visible: true };
  }

  componentDidMount() {
    this.setAutoHideTimer();
  }

  componentWillUnmount() {
    if (this.timerAutoHide) clearTimeout(this.timerAutoHide);
  }

  render() {
    const { className, status, role, center, title, closable, children } =
      this.props;
    return (
      <div
        className={
          this.state.visible
            ? cx(className, classes["ds-banner"], {
                [classes["ds-banner--center"]]: center,
                [classes["ds-banner--info"]]: status === "info",
                [classes["ds-banner--success"]]: status === "success",
                [classes["ds-banner--warning"]]: status === "warning",
                [classes["ds-banner--error"]]: status === "error",
              })
            : cx(classes["ds-banner--hidden"])
        }
        role={role}
      >
        <h5 className={cx(classes["ds-banner__title"])}>
          {this.renderIcon()}
          {title}
        </h5>
        <p className={cx(classes["ds-banner__text"])}>{children}</p>
        {closable && (
          <button
            className={cx(classes["ds-button--close"])}
            aria-label="Close"
            type="button"
            onClick={this.handleClick}
          >
            <CloseIcon style={style.close} />
          </button>
        )}
      </div>
    );
  }

  private renderIcon(): JSX.Element {
    switch (this.props.status) {
      case "success":
        return <SuccessIcon aria-hidden="true" style={style.icon} />;

      case "warning":
        return <AlertIcon aria-hidden="true" style={style.icon} />;

      case "error":
        return <ErrorIcon aria-hidden="true" style={style.icon} />;

      case "info":
      default:
        return <InfoIcon aria-hidden="true" style={style.icon} />;
    }
  }

  handleClick = (): void => {
    this.setState({ visible: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private setAutoHideTimer() {
    if (this.timerAutoHide) clearTimeout(this.timerAutoHide);

    if (this.props.autoHideDuration && this.props.autoHideDuration > 0) {
      this.timerAutoHide = setTimeout(() => {
        this.handleClick();
      }, this.props.autoHideDuration);
    }
  }
}

import React, { FC, ReactNode, HTMLAttributes } from "react";
import cx from "classnames";

type TableRowProps = HTMLAttributes<HTMLTableRowElement> & {
  children?: ReactNode;
};

const TableRow: FC<TableRowProps> = ({ children, ...rest }) => (
  <tr {...rest}>{children}</tr>
);

export default TableRow;

import React, { Component } from "react";
import FormGroup from "../FormGroup";
import cx from "classnames";
import classes from "./styles.module.scss";

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps {
  /** A select can appear disabled. */
  disabled?: boolean;

  /** A unique identifier. */
  id: string;

  /** A label */
  label?: string;

  /** Options array. Each option has a 'value' and a 'label' property. */
  options: SelectOption[];

  /**
   * Called when user selects an option.
   *
   * @param {ChangeEvent<HTMLSelectElement>} event - React's original ChangeEvent.
   */
  handleChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
}

export default class Select extends Component<SelectProps> {
  static defaultProps = {};

  constructor(props: SelectProps) {
    super(props);
  }

  render() {
    const { disabled, handleChange, id, label, options } = this.props;

    return (
      <FormGroup>
        <label htmlFor={id} className={cx(classes["ds-label"])}>
          {label}
        </label>
        <select
          id={id}
          aria-label="Label"
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map((option: SelectOption, key: number) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormGroup>
    );
  }
}

/* Styles import */
import styles from "./entity.module.css";

/* Global import */
import React, { Component } from "react";
import { Title } from "../../components/horizon-components-react/src/components";
import { inject, observer } from "mobx-react";

interface Props {
  entity?: Entity;
  basicEntity?: BasicEntity;
  rootStore: import("../../stores/rootStore").default;
  onClick: () => void;
  selected?: boolean;
}
interface State {}

const EntityComponent: React.FC<Props> = ({
  rootStore,
  entity,
  basicEntity,
  onClick,
  selected,
}) => {
  if (basicEntity) {
    return (
      <div
        className={`${styles.entity} ${selected ? styles.selected : ""}`}
        onClick={() => onClick()}
      >
        <Title tag="h4">{basicEntity.name}</Title>

        {basicEntity.addresses && basicEntity.addresses[0] && (
          <p>
            {basicEntity.addresses[0] && basicEntity.addresses[0].address}
            <br />
            {basicEntity.addresses[0].postal_code}{" "}
            {basicEntity.addresses[0].city}
            <br />
            {basicEntity.addresses[0].country}
          </p>
        )}
      </div>
    );
  }
  if (entity) {
    return (
      <div
        className={`${styles.entity} ${selected ? styles.selected : ""}`}
        onClick={() => onClick()}
      >
        <Title tag="h4">{entity.name}</Title>

        <p>
          {entity.address}
          <br />
          {entity.postCode} {entity.city}
          <br />
          {entity.countryAlpha2}
        </p>
      </div>
    );
  }
  return <p></p>;
};
export default inject("rootStore")(observer(EntityComponent));

/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

/* Custom import */
import SubHeader from "../subHeader";
import RootStore from "../../stores/rootStore";

interface Props {
  pages: Pages;
  rootStore: RootStore;
  id?: string;
}
type Pages = "REQUEST" | "ADD" | "MATCHING" | "SEARCH" | "VIEW";

const headerLicenseComponent = ({ pages, rootStore, id }: Props) => {
  return (
    <SubHeader>
      <Link
        id="search"
        className={`${styles.link} ${
          pages === "SEARCH" ? styles.selected : {}
        }`}
        to="/license/search"
      >
        {i18n.t("pages.license.menu.search")}
      </Link>

      <Link
        id="request"
        className={`${styles.link} ${
          pages === "REQUEST" ? styles.selected : {}
        }`}
        to="/license/request"
      >
        {i18n.t("pages.license.menu.request")}
      </Link>
      {rootStore.userStore.user?.role !== "L_R_A" ? (
        <Link
          id="add"
          className={`${styles.link} ${pages === "ADD" ? styles.selected : {}}`}
          to="/license/add"
        >
          {i18n.t("pages.license.menu.add")}
        </Link>
      ) : (
        <Link
          id="add"
          hidden={!id}
          className={`${styles.link} ${
            pages === "VIEW" ? styles.selected : {}
          }`}
          to="/license/add"
        >
          {i18n.t("pages.license.menu.view")}
        </Link>
      )}
    </SubHeader>
  );
};

export default headerLicenseComponent;

import React, { Component, ChangeEvent, ReactNode } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type State = {};
type Props = {
  className?: string;
  /** The value of the currently selected `Tab`. */
  value?: any;
  /** If `true`, the component will be better suited to dark backgrounds. */
  dark?: boolean;
  /** If `true`, the component will have an alertnative look. */
  secondary?: boolean;
  /** Callback fired when the value changes. */
  onChange?: (event: ChangeEvent<{}>, value: any) => void;
  children?: ReactNode;
};

export default class Tabs extends Component<Props, State> {
  private valueToIndex: Map<any, number> = new Map();

  render() {
    const { className, value, onChange, secondary, dark } = this.props;
    this.valueToIndex = new Map();
    let childIndex = 0;
    const children = React.Children.map(this.props.children, (child) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      if (child.type === React.Fragment) {
        console.warn(
          "The Tabs component doesn't accept a Fragment as a child. Consider providing an array instead."
        );
      }

      const childProps = child.props as any;
      const childValue =
        childProps.value === undefined ? childIndex : childProps.value;
      this.valueToIndex.set(childValue, childIndex);
      const selected = childValue === value;

      childIndex += 1;
      return React.cloneElement<any>(child, {
        selected,
        onChange,
        value: childValue,
      });
    });
    return (
      <div
        className={cx(
          classes["ds-tabs"],
          {
            [classes["ds-tabs--secondary"]]: secondary,
            [classes["ds-tabs--dark"]]: dark,
          },
          className
        )}
      >
        <ul role="tablist" className={classes["ds-tabs__list"]}>
          {children}
        </ul>
      </div>
    );
  }
}

import React, { createContext, useContext, ReactNode } from "react";
import RootStore from "./rootStore";

export const MobXContext = createContext<RootStore | null>(null);

interface MobXProviderProps {
  children: ReactNode;
}

export const MobXProvider: React.FC<MobXProviderProps> = ({ children }) => {
  const rootStore = new RootStore();

  return (
    <MobXContext.Provider value={rootStore}>
      {children}
    </MobXContext.Provider>
  );
};

import styles from "../license.module.css";

import React, { useEffect, useState, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Tooltip,
} from "@mui/material";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import i18n from "i18next";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";

/* Custom import */
import ErrorAlert from "../../../components/alert/ErrorAlert";
import AppBar from "../../../components/appBar";
import HeaderLicense from "../../../components/headerLicense";
import Api from "../../../utils/api";
import { MonetaryTypo } from "../../../customComponents/monetaryTypo";
import MonetaryInput from "../../../customComponents/monetaryInput/monetaryInput";
import TimeLine from "../../../components/timeLine";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import { flowUrl } from "../../../utils/generator";
import Footer from "../../../components/footer";

interface State {
  linkedEquip: MatchedEquipment[];
  vertical: string;
  horizontal: string;
}

interface Props extends InjectedProps {}

interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}

const ImputeLicence: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();

  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "center",
    linkedEquip: [
      {
        flow: {} as MatchingFlowDto,
        equipments: [],
        linked: [],
        qtyFromFlow: 0,
        amountFromFlow: 0,
        pnFromFlow: "",
        modelFromFlow: "",
        expiration_date: "",
        company_name: "",
        license_number: "",
        regulation: "",
        license_id: 0,
        active_line: 0,
        amount: "",
        amount_actual: "",
        amount_delivered: "",
        amount_initial: "",
        amount_ordered: "",
        eccn_code: "",
        id: 0,
        keyword: "",
        model: "",
        pn: "",
        public_list_name: "",
        quantity: 0,
        quantity_actual: 0,
        quantity_delivered: 0,
        quantity_initial: 0,
        quantity_ordered: 0,
        unit_price: 0,
        us_origin: 0,
        currency: "",
      },
    ],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ message: string; code?: string } | null>(
    null
  ); // Stocke l'erreur
  const [disabledButtons, setDisabledButtons] = useState<
    Record<string, boolean>
  >({});

  const [open, setSnackOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [statusSnack, setStatusSnack] = useState(0);

  const handleClose = () => {
    setSnackOpen(false);
  };

  const { id } = useParams() as { id?: string };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true); // Commence le chargement
        const linkedEquipData: MatchedEquipment[] =
          await api.getMatchedLicenseByFlowId(parseInt(id as string));

        if (linkedEquipData.length > 0) {
          setState({
            ...state,
            linkedEquip: linkedEquipData,
          });
          console.log(linkedEquipData);
        }
      } catch (error: any) {
        console.error("Error fetching data: ", error);

        // Capture le message d'erreur et le code de l'API si disponible
        const errorMessage =
          error.response?.data?.message || "Une erreur est survenue.";
        const errorCode = error.response?.status || "Non disponible";

        // Stocke l'erreur dans l'état
        setError({ message: errorMessage, code: errorCode });
      } finally {
        setLoading(false); // Fin du chargement
      }
    };

    fetch();
  }, [id]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const handleChange = (
    e: number,
    field: string,
    lnkEqmnt: MatchedEquipment
  ) => {
    const linkedEquipIndex = state.linkedEquip.findIndex(
      (linkedEquip) => linkedEquip.id === lnkEqmnt.id
    );
    var lnkEqmnt: MatchedEquipment = state.linkedEquip[linkedEquipIndex];

    setState((prevState) => ({
      ...prevState,
      linkedEquip: prevState.linkedEquip.map((equip, i) => {
        if (i === linkedEquipIndex) {
          // Mettre à jour en fonction du champ
          if (field === "quantityImput") {
            return { ...equip, qtyFromFlow: e };
          } else if (field === "amountImput") {
            return { ...equip, amountFromFlow: e };
          }
        }
        return equip; // Ne pas modifier les autres équipements
      }),
    }));
  };

  const handleButtonClick = (equipmentId: number) => {
    handleSubmit(equipmentId);
    setDisabledButtons((prevState) => ({ ...prevState, [equipmentId]: true }));
  };

  /**
   * Function to submit the imputation of a license to an equipment
   * @param id The id of the equipment
   */
  const handleSubmit = async (id: number) => {
    const linkedEquipIndex = state.linkedEquip.findIndex(
      (linkedEquip) => linkedEquip.id === id
    );
    var lnkEqmnt: MatchedEquipment = state.linkedEquip[linkedEquipIndex];

    const payload = {
      licenseId: lnkEqmnt.license_id ?? 0,
      licenseEquipementId: id,
      eccn_code: lnkEqmnt?.eccn_code ?? "",
      quantityToImpute: lnkEqmnt?.qtyFromFlow ?? 0,
      amountToImpute: lnkEqmnt?.amountFromFlow ?? 0,
    };

    try {
      const imputation: LicenseEquipment = await api.imputeLicense(payload);
      const newLinkedEquip: MatchedEquipment[] =
        await api.getMatchedLicenseByFlowId(lnkEqmnt.flow.id);

      setMessageNotification(i18n.t("notifications.form.success"));
      setStatusSnack(0);
      setSnackOpen(true);
      setState({ ...state, linkedEquip: newLinkedEquip });
    } catch (error) {
      setMessageNotification(i18n.t("notifications.form.failure"));
      setStatusSnack(1);
      setSnackOpen(true);
    }

    setLoading(false);
  };

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />
      <HeaderLicense pages="MATCHING" rootStore={rootStore} />
      <div className={styles.body}>
        <div>
          {loading && <p>Chargement...</p>}
          {error && <ErrorAlert message={error.message} code={error.code} />}
        </div>

        <Typography
          variant="h2"
          sx={{ fontSize: 34, fontWeight: "medium", margin: "15px" }}
        >
          {i18n.t("pages.imputation.title")}
        </Typography>

        {state.linkedEquip.map((equipment: MatchedEquipment, index: number) => (
          <Grid
            container
            spacing={7}
            key={index}
            alignItems="center"
            sx={{ marginBottom: "20px", justifyContent: "space-around" }}
          >
            {/* Licence avant imputation */}
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Typography variant="h6">
                  {i18n.t("pages.imputation.titleBefore")}
                </Typography>
                <Typography>
                  {i18n.t("pages.imputation.licenseNumber")}{" "}
                  {equipment.license_number}
                </Typography>

                {equipment.eccn_code.includes("ML") ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.order")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.delivered")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.quantity")}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{equipment.quantity_ordered}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            {equipment.quantity_delivered}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.amount")}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            {parseInt(
                              equipment.amount_ordered
                            ).toLocaleString()}{" "}
                            {equipment.currency}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            {parseInt(
                              equipment.amount_delivered
                            ).toLocaleString()}{" "}
                            {equipment.currency}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <>
                    <Typography>
                      {i18n.t("pages.imputation.quantity")}
                      {equipment.quantity_actual}
                    </Typography>
                    <MonetaryTypo
                      text={i18n.t("pages.imputation.amount")}
                      numberValue={parseInt(equipment.amount_actual)}
                      currency={equipment.currency}
                    />
                  </>
                )}
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              md={0.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowForwardIcon
                fontSize="large"
                sx={{
                  transform: { xs: "rotate(90deg)", md: "none" },
                  color: "#4C5054",
                }}
              />
            </Grid>

            {/* Ligne de commande */}
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Typography variant="h6">
                  {i18n.t("pages.imputation.commandLine")} {equipment.eccn_code}{" "}
                  - {equipment.id}
                </Typography>
                <Typography>
                  {i18n.t("pages.imputation.partNumber")} {equipment.pnFromFlow}
                </Typography>
                <Tooltip
                  title={
                    <span style={{ fontSize: "18px" }}>
                      {equipment.modelFromFlow}
                    </span>
                  }
                  followCursor
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "400px",
                    }}
                  >
                    {i18n.t("pages.imputation.designation")}{" "}
                    {equipment.modelFromFlow}
                  </Typography>
                </Tooltip>
                <TextField
                  label={i18n.t("pages.imputation.qtyToImput")}
                  variant="standard"
                  type="number"
                  value={equipment.qtyFromFlow}
                  onChange={(e) =>
                    handleChange(
                      parseInt(e.target.value) || 0,
                      "quantityImput",
                      equipment
                    )
                  }
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                />
                <MonetaryInput
                  id="amount-to-impute"
                  label={i18n.t("pages.imputation.amountToImput")}
                  currency={equipment.currency as Currency}
                  value={equipment.amountFromFlow}
                  onChange={(e) =>
                    handleChange(Number(e), "amountImput", equipment)
                  }
                />
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              md={0.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowForwardIcon
                fontSize="large"
                sx={{
                  transform: { xs: "rotate(90deg)", md: "none" },
                  color: "#4C5054",
                }}
              />
            </Grid>

            {/* Licence après imputation */}
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Typography variant="h6">
                  {i18n.t("pages.imputation.titleAfter")}
                </Typography>
                <Typography>
                  {i18n.t("pages.imputation.licenseNumber")}{" "}
                  {equipment.license_number}
                </Typography>

                {equipment.eccn_code.includes("ML") ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.order")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.delivered")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.quantity")}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{
                                display: "inline-flex",
                                color: "red",
                                verticalAlign: "middle",
                              }}
                            />
                            {equipment.quantity_ordered - equipment.qtyFromFlow}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <KeyboardArrowUpIcon
                              fontSize="small"
                              sx={{
                                display: "inline-flex",
                                color: "green",
                                verticalAlign: "middle",
                              }}
                            />
                            {equipment.quantity_delivered +
                              equipment.qtyFromFlow}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("pages.imputation.amount")}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{
                                display: "inline-flex",
                                color: "red",
                                verticalAlign: "middle",
                              }}
                            />
                            {(
                              parseInt(equipment.amount_ordered) -
                              equipment.amountFromFlow
                            ).toLocaleString()}{" "}
                            {equipment.currency}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <KeyboardArrowUpIcon
                              fontSize="small"
                              sx={{
                                display: "inline-flex",
                                color: "green",
                                verticalAlign: "middle",
                              }}
                            />
                            {(
                              parseInt(equipment.amount_delivered) +
                              equipment.amountFromFlow
                            ).toLocaleString()}{" "}
                            {equipment.currency}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <>
                    <Typography>
                      {i18n.t("pages.imputation.quantity")}
                      {equipment.quantity_actual - equipment.qtyFromFlow}
                    </Typography>
                    <MonetaryTypo
                      text={i18n.t("pages.imputation.amount")}
                      numberValue={
                        parseInt(equipment.amount_actual) -
                        equipment.amountFromFlow
                      }
                      currency={equipment.currency}
                    />
                  </>
                )}
              </Paper>
            </Grid>

            {/* Bouton d'imputation */}
            <Grid item xs={12} sm={6} md={1.2} textAlign="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: disabledButtons[equipment.id]
                    ? "#B0BEC5"
                    : "#0085AD", // Couleur grise si désactivé
                  padding: 1,
                  "& .MuiButton-endIcon": {
                    "@media (min-width: 900px) and (max-width: 1500px)": {
                      display: "none",
                      margin: 0,
                    },
                  },
                }}
                endIcon={<AssignmentTurnedInIcon />}
                onClick={() =>
                  !disabledButtons[equipment.id] &&
                  handleButtonClick(equipment.id)
                } // Empêche le clic si désactivé
                fullWidth
                disabled={disabledButtons[equipment.id]} // Désactive le bouton si nécessaire
              >
                {i18n.t("pages.imputation.buttonImput")}
              </Button>
            </Grid>
          </Grid>
        ))}
      </div>
      {state.linkedEquip.length >= 0 && (
        <Footer
          justify="flex-end"
          buttons={[
            {
              id: "previous",
              onClick: () => goBack(),
              label: i18n.t("global.previous"),
            },
            {
              id: "next",
              onClick: () => {
                navigate(flowUrl("/shipping", id));
              },
              label: i18n.t("global.sub-expo-l_a"),
              primary: true,
            },
          ]}
        >
          <TimeLine step={3} rootStore={rootStore} />
          <Snackbar
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: state.vertical as any,
              horizontal: state.horizontal as any,
            }}
            open={open}
            onClose={handleClose}
            message=""
            key={state.vertical + state.horizontal}
          >
            <Alert
              onClose={handleClose}
              severity={statusSnack === 1 ? "error" : "success"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {messageNotification}
            </Alert>
          </Snackbar>
        </Footer>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(ImputeLicence));

import React, { FC, CSSProperties, ReactNode } from "react";
import cx from "classnames";
import classes from "./styles.module.scss";

type Props = {
  className?: string;
  style?: CSSProperties;
  /** The tag to be used */
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /** The color of the title */
  color?: "primary" | "error" | "success" | "warning" | "info";
  /** If `true`, the title will be adapted to dark backgrounds */
  dark?: boolean;
  children?: ReactNode;
};

const Title: FC<Props> = ({
  tag = "h1",
  dark = false,
  color,
  className,
  style,
  children,
}) => {
  const Component = tag as any;
  return (
    <Component
      style={style}
      className={cx(
        classes[`${tag}-like`],
        {
          [classes["ds-heading--dark"]]: dark,
          [classes["ds-heading--primary"]]: color === "primary",
          [classes["ds-heading--error"]]: color === "error",
          [classes["ds-heading--success"]]: color === "success",
          [classes["ds-heading--warning"]]: color === "warning",
          [classes["ds-heading--info"]]: color === "info",
        },
        className
      )}
    >
      {children}
    </Component>
  );
};

export default Title;
